import React from 'react'
import classNames from 'classnames'

function Separator({ className }) {
  const classes = classNames({
    'separator': true,
    [className]: className
  })

  return <div className={classes} />
}

export default Separator
