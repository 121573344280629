import React from 'react'
import Avatar from '@fnd/components/Avatar'
import Badge from '@fnd/components/Badge'
import { useIntl } from 'react-intl'
import classNames from 'classnames'

export const ConversationArchiveToggle = ({
  showArchive,
  setShowArchive,
  total,
}) => {
  const intl = useIntl()

  return (
    <div
      className={classNames({
        'chat-archive': true,
        'chat-list-item': true,
        'active': showArchive,
      })}
      onClick={() => setShowArchive(!showArchive)}
    >
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-3">
          <Avatar icon={showArchive ? 'arrow-left' : 'archive'} />
          <span className="inline-flex items-center gap-2 font-medium">
            {intl.formatMessage({ id: 'messages.archived_chats' })}
          </span>
        </div>
        <Badge
          variant={showArchive ? 'primary' : 'light'}
          label={total}
        />
      </div>
    </div>
  )
}

export default ConversationArchiveToggle
