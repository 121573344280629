import { make } from './endpoints'

const getMonitoring = () => make.get('/playlist-check/monitoring')

const setMonitoring = (playlistId) =>
  make.post(`/playlist-check/${playlistId}`)

const removeMonitoring = (playlistId) =>
  make.delete(`/playlist-check/${playlistId}`)

const getPlaylistAnalysis = (playlistId) =>
  make.get(`/playlist-check/${playlistId}`)

const getPlaylistAnalysisCharts = (playlistId, startDate, endDate) => {
  return make.get(`/playlist-check/${playlistId}/charts?startDate=${startDate}&endDate=${endDate}`)
}

export default {
  getMonitoring,
  setMonitoring,
  removeMonitoring,
  getPlaylistAnalysis,
  getPlaylistAnalysisCharts
}
