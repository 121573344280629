import { spotifyEndpoints } from '@fnd/core/spotify'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { create } from 'zustand'

export const usePushedStore = create(
  (set) => ({
    pushed: [],
    error: false,
    isLoading: false,
    success: false,
    getPushedTracks: async () => {
      set({ isLoading: true })
      const pushed = await spotimatchEndpoints.getPushedTracks().toPromise()

      const tracks = await Promise.all(pushed.map(async (pushedTrack) => {
        const track = await spotifyEndpoints.getTrack(pushedTrack?.fy_track_id).toPromise()
        return {
          ...pushedTrack,
          ...track
        }
      }))

      set({ pushed: tracks })
      set({ isLoading: false })
    },
  }),
)
