import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '@fnd/components/Button'
import Info from '@fnd/components/Info'
import { SectionHeader } from '@fnd/components/Section'
import countries from './countries.json'

export default function BillingInfoView({ billingInfo, switchMode }) {
  const isItaly = billingInfo.country === 'IT'
  let countryName = ''

  if (billingInfo.country) {
    const countryObj = countries.find(country => country.value === billingInfo.country)
    if (countryObj) {
      countryName = countryObj.label
    }
  }

  return (
    <>
      <SectionHeader
        sticky
        title={<FormattedMessage id="profile.billing_info.edit" />}
        icon="file-invoice"
        actions={(
          <>
            <Button
              className="overview-link"
              variant="primary"
              icon="edit"
              onClick={switchMode}
            >
              <FormattedMessage id="profile.billing_info.edit" />
            </Button>
          </>
        )}
      />
      <Info
        label={<FormattedMessage id="profile.billing_info.firstName" />}
        value={billingInfo.firstName}
      />
      <Info
        label={<FormattedMessage id="profile.billing_info.lastName" />}
        value={billingInfo.lastName}
      />
      <Info
        label={<FormattedMessage id="profile.billing_info.country" />}
        value={countryName}
      />
      <Info
        label={<FormattedMessage id="profile.billing_info.address" />}
        value={billingInfo.address}
      />
      <Info
        label={<FormattedMessage id="profile.billing_info.city" />}
        value={billingInfo.city}
      />
      <Info
        label={<FormattedMessage id="profile.billing_info.zipCode" />}
        value={billingInfo.zipCode}
      />
      <Info
        label={<FormattedMessage id="profile.billing_info.provinceState" />}
        value={billingInfo.provinceState}
      />
      {isItaly && (
        <Info label="Codice Fiscale" value={billingInfo.codiceFiscale} />
      )}
      <Info label="Email" value={billingInfo.email} />
      {billingInfo.isVatCompany && (
        <>
          <Info
            label={<FormattedMessage id="profile.billing_info.companyName" />}
            value={billingInfo.companyName}
          />
          <Info
            label={<FormattedMessage id="profile.billing_info.vatNumber" />}
            value={billingInfo.vatNumber}
          />
        </>
      )}

      <Info
        label={<FormattedMessage id="field.phone.label" />}
        value={billingInfo.phone}
      />

      {billingInfo.isVatCompany && isItaly && (
        <>
          <Info label="Codice Univoco" value={billingInfo.codiceUnivoco} />
          <Info label="PEC" value={billingInfo.pec} />
        </>
      )}
    </>
  )
}
