import { spotifyEndpoints } from '@fnd/core/spotify'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { create } from 'zustand'

export const useCuratorStore = create(
  (set, getState) => ({
    error: false,
    isLoading: false,
    curators: undefined,
    currentCurator: undefined,
    currentCuratorId: undefined,
    success: false,
    getCurator: (userId) => {
      return getState().curators?.[userId] || null
    },
    loadCurator: async (userId) => {
      try {
        set({ isLoading: true })

        const curator = await spotifyEndpoints.getUserProfile(userId).toPromise()
        const curatorStats = await spotimatchEndpoints.getCuratorStats(userId).toPromise()
        const curatorData = {
          ...curator,
          ...curatorStats,
        }

        set({
          currentCurator: curatorData,
          currentCuratorId: userId,
          curators: {
            ...getState().curators,
            [userId]: curatorData,
          },
        })

        set({ isLoading: false })
      } catch (error) {
        console.log(error)
        set({ isLoading: false })
      }
    },
    resetFeedback: () => set({ success: false, error: false }),
  }),
)

export default useCuratorStore
