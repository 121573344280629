import React from 'react'
import { FormattedMessage } from 'react-intl'
import Progress from '@fnd/components/Progress'

function MatchDetails({ values }) {
  return (
    <div className="playlist-match">
      <Progress
        title={<FormattedMessage id="match.card.features.danceability" />}
        icon="guitar"
        progress={values?.danceability}
        color="red"
        tooltipText={
          <FormattedMessage id="match.card.features.danceability.tooltip" />
        }
      />

      <Progress
        title={<FormattedMessage id="match.card.features.acousticness" />}
        icon="turntable"
        progress={values?.acousticness}
        color="orange"
        tooltipText={
          <FormattedMessage id="match.card.features.acousticness.tooltip" />
        }
      />

      <Progress
        title={<FormattedMessage id="match.card.features.energy" />}
        icon="bolt"
        progress={values?.energy}
        color="yellow"
        tooltipText={
          <FormattedMessage id="match.card.features.energy.tooltip" />
        }
      />

      <Progress
        title={<FormattedMessage id="match.card.features.instrumentalness" />}
        icon="violin"
        progress={values?.instrumentalness}
        color="green"
        tooltipText={
          <FormattedMessage id="match.card.features.instrumentalness.tooltip" />
        }
      />

      <Progress
        title={<FormattedMessage id="match.card.features.speechiness" />}
        icon="microphone-stand"
        progress={values?.speechiness}
        color="lightblue"
        tooltipText={
          <FormattedMessage id="match.card.features.speechiness.tooltip" />
        }
      />

      <Progress
        title={<FormattedMessage id="match.card.features.mood" />}
        icon="smile-wink"
        progress={values?.valence}
        color="blue"
        tooltipText={<FormattedMessage id="match.card.features.mood.tooltip" />}
      />

      <Progress
        title={<FormattedMessage id="match.card.features.tempo" />}
        icon="hourglass-half"
        progress={values?.tempo}
        color="purple"
        tooltipText={
          <FormattedMessage id="match.card.features.tempo.tooltip" />
        }
      />
    </div>
  )
}

export default MatchDetails
