import { make } from './endpoints'


export const getPaymentIntent = (intentId) =>
  make.get(`/transactions/payment/intent/${intentId}`)

export const createPaymentIntent = (amount, customerId, metadata) =>
  make.post(`/transactions/payment/intent`, {
    body: { amount, customerId, metadata },
  })

export const createCustomer = () =>
  make.post(`/transactions/payment/customer`)

export const getUserSubscription = () => make.get(`/transactions/payment/subscription`)

export const createSubscription = ({ amount, customerId, plan, metadata }) =>
  make.post(`/transactions/payment/subscription`, {
    body: { amount, customerId, plan, metadata },
  })

export const updateSubscription = ({ amount, creditsScoreUsed, plan }) =>
  make.put(`/transactions/payment/subscription`, {
    body: { amount, creditsScoreUsed, plan },
  })

export const enableSubscriptionAutoRenewal = () => make.put(`/transactions/payment/subscription/renewal/enable`)

export const disableSubscriptionAutoRenewal = () => make.put(`/transactions/payment/subscription/renewal/disable`)

export const getUserTransactions = () =>
  make.get(`/transactions`)


export default {
  getPaymentIntent,
  createPaymentIntent,
  createCustomer,
  getUserSubscription,
  createSubscription,
  updateSubscription,
  enableSubscriptionAutoRenewal,
  disableSubscriptionAutoRenewal,
  getUserTransactions,
}
