import React from 'react'
import { FormattedMessage } from 'react-intl'
import Badge from '@fnd/components/Badge'
import { CURRENCY_SYMBOL } from '@fnd/constants'

const PlanPriceAnalysis = ({
  price,
  isArtistAnalysisFree,
  availableAnalysis
}) => {
  if (!price) return null

  const labelKey = `analysis.card.${isArtistAnalysisFree ? 'free_remaining' : 'remaining'}`

  if (availableAnalysis <= 0) {
    return (
      <h4 className="m-0">
        <FormattedMessage id="analysis.card.only_for" />{' '}{CURRENCY_SYMBOL}
        {price}
      </h4>
    )
  }

  return (
    <Badge
      large
      variant="primary-light"
      className="capitalize"
      label={<FormattedMessage id={labelKey} />}
      value={availableAnalysis}
    />
  )
}

export default PlanPriceAnalysis
