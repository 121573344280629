import React from 'react'
import classNames from 'classnames'
import Avatar from '@fnd/components/Avatar'
import Badge from '@fnd/components/Badge'
import { USER_TYPES } from '@fnd/constants'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'

export const ConversationItem = React.forwardRef(({
  conversation,
  onClick,
  active,
  className
}, ref) => {
  const intl = useIntl()
  const { locale } = intl

  const handleConversationClick = () => {
    if (onClick) {
      onClick(conversation)
    }
  }

  const classes = classNames({
    'chat-list-item': true,
    'active': active,
    [className]: className,
  })

  return (
    <div
      ref={ref}
      className={classes}
      onClick={handleConversationClick}
    >
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-3">
          {conversation?.avatar ? (
            <Avatar
              src={conversation.avatar}
              star={conversation.type === USER_TYPES.STAR}
            />
          ) : (
            <Avatar
              star={conversation.type === USER_TYPES.STAR}
            />
          )}

          <div className="flex flex-col items-start">
            <span className="font-medium">
              {conversation?.friendlyName}
            </span>
            {conversation?.dateUpdated && (
              <small className="opacity-70">
                {dayjs(conversation?.dateUpdated).locale(locale).fromNow()}
              </small>
            )}
          </div>
        </div>

        {conversation?.unreadCount > 0 && (
          <Badge
            small
            variant="primary"
            label={conversation.unreadCount}
          />
        )}
      </div>
    </div>
  )
})

export default ConversationItem
