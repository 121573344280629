import React from "react"
import Button from "@fnd/components/Button"
import { useQueueStore, useQueueFeedbackStore } from "@fnd/store"
import { useIntl } from "react-intl"

export const RejectSubmission = ({
  track,
  submitter
}) => {
  const intl = useIntl()

  const {
    setTrack,
    setSubmitter,
    setShowFeedback
  } = useQueueFeedbackStore()

  const { loadingTrackId } = useQueueStore()

  const handleTrackFeedback = (track, submitter) => {
    setShowFeedback(true)
    setTrack(track)
    setSubmitter(submitter)
  }

  return (
    <Button
      small
      variant="red-light"
      icon="thumbs-down"
      loading={loadingTrackId === track.id}
      disabled={loadingTrackId === track.id}
      tooltip={intl.formatMessage({
        id: 'playlist_queue.action.feedback',
      })}
      onClick={() => handleTrackFeedback(track, submitter)}
    />
  )
}

export default RejectSubmission
