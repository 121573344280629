import React, { useEffect, useState } from 'react'
import Auth from '@fnd/components/Auth'
import Avatar from '@fnd/components/Avatar'
import BlockUser from '@fnd/components/BlockUser'
import Badge from '@fnd/components/Badge'
import Empty from '@fnd/components/Empty'
import { Icons } from '@fnd/components/Icons'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Share from '@fnd/components/Share/Share'
import Spinner from '@fnd/components/Spinner'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import { FormattedMessage, useIntl } from 'react-intl'
import { ConversationStart } from '@fnd/components/Chat'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { Placeholder } from '@fnd/components/Placeholder'
import { ROUTES } from '@fnd/screens/constants'
import { nanoid } from 'nanoid'
import classNames from 'classnames'
import {
  userFeaturedSelector,
  userProfileSelector,
  isCuratorFeatured,
  useUserContext,
} from '@fnd/modules/User'
import CuratorPromote from './CuratorPromote'
import dayjs from 'dayjs'
import { USER_TYPES } from '@fnd/constants'

export function CuratorDetail() {
  const navigate = useNavigate()
  const { curatorId } = useParams()
  if (!curatorId) navigate(ROUTES.CURATORS)

  const [curator, setCurator] = useState(null)
  const [playlists, setPlaylists] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [daysToEnd, setDaysToEnd] = useState(0)
  const [isStar, setIsStar] = useState(false)

  const intl = useIntl()

  const { profile, featured, isFeatured } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
    isFeatured: isCuratorFeatured(user),
    featured: userFeaturedSelector(user),
  }))

  useEffect(() => {
    if (featured) {
      const daysToEnd = dayjs(featured.ends_at).diff(dayjs(), 'days')
      setDaysToEnd(daysToEnd)
    }
  }, [featured])

  useEffect(() => {
    if (curator?.type === USER_TYPES.STAR) {
      setIsStar(true)
    }
  }, [curator])

  const fetchCurator = async () => {
    try {
      const curator = await spotimatchEndpoints
        .getUserProfile(curatorId)
        .toPromise()
      setCurator(curator)

      const playlists = await spotimatchEndpoints
        .getCuratorPlaylists(curatorId)
        .toPromise()
      setPlaylists(playlists)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    fetchCurator()
  }, [curatorId])

  const longBio = curator?.contacts?.bio?.length > 180

  const classes = classNames({
    'curator-detail': true,
    'star': isStar,
  })

  const bioCn = classNames({
    'curator-bio text-balance': true,
    'text-center sm:max-w-2xl mt-6 mx-auto': !longBio,
    'text-left max-w-screen-lg mt-6 mx-auto': longBio,
    'text-lg md:text-xl font-light opacity-90': !longBio,
    'text-base font-light opacity-90': longBio,
  })

  return (
    <Wrapper>
      <Auth login emptyState>
        <div className="mb-12">
          {curator ? (
            <div className={classes}>
              <div className="flex flex-col items-center justify-center mb-4">
                {curator?.spotify?.images ? (
                  <Avatar
                    className="mb-3"
                    star={isStar}
                    hideStarBadge
                    src={curator?.spotify?.images}
                    alt={curator?.spotify?.display_name}
                  />
                ) : (
                  <Placeholder
                    variant="avatar"
                    icon="user-music"
                    star={isStar}
                    hideStarBadge
                    className={classNames({
                      'w-[150px] h-[150px]': true,
                      'mx-auto mb-3': true,
                    })}
                  />
                )}

                <h1 className="inline-flex items-center gap-2 mb-3">
                  {curator?.spotify?.display_name}
                  {isStar && <span className="verified">
                    <Icons icon="verified" />
                  </span>}
                </h1>

                <div className="flex gap-2">
                  {isStar && (
                    <Badge
                      icon="star"
                      label="Star"
                      variant="gold-light"
                    />
                  )}

                  <Badge
                    icon="users"
                    label={<FormattedMessage id="match.table.followers" />}
                    value={curator?.spotify?.followers?.total || `0`}
                    variant="light"
                  />

                  {profile.spotify_id === curatorId && isFeatured && (
                    <Badge
                      label={<FormattedMessage id="match.card.featured" />}
                      value={
                        <FormattedMessage
                          id="curator.featured.badge"
                          values={{ daysLeft: daysToEnd }}
                        />
                      }
                      variant="light"
                    />
                  )}
                </div>

                {curator?.contacts?.roles && (
                  <div className="flex gap-2 mt-2">
                    {curator?.contacts?.roles.map(role => (
                      <Badge
                        key={role}
                        label={intl.formatMessage({ id: `role.${role}` })}
                        variant="light"
                      />
                    ))}
                  </div>
                )}

                {curator?.tags && (
                  <div className="flex gap-2 mt-2">
                    {curator?.tags.map(tag => (
                      <Badge
                        key={tag}
                        label={tag}
                        variant="primary-light"
                      />
                    ))}
                  </div>
                )}

                <div className="mt-5 curator-actions">
                  <Share
                    url={window.location}
                    triggerVariant={isStar ? 'outline-gold' : 'outline-primary'}
                    title={intl.formatMessage({ id: 'messages.share' })}
                  />

                  {curatorId !== profile?.spotify_id && <BlockUser
                    checkOnlineStatus
                    spotifyId={curatorId}
                    showTitle={false}
                  />}

                  {profile.spotify_id === curatorId && !isFeatured ? (
                    <CuratorPromote triggerVariant={isStar ? 'gold' : 'primary'} />
                  ) : null}

                  {curatorId !== profile?.spotify_id && (
                    <ConversationStart user={curator} />
                  )}
                </div>
              </div>

              <div className={bioCn}>
                <p>
                  {curator?.contacts?.bio ? (
                    <>{curator?.contacts?.bio}</>
                  ) : (
                    <FormattedMessage id="profile.no_bio" />
                  )}
                </p>
              </div>
            </div>
          ) : null}

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-8">
            {playlists &&
              playlists.length > 0 &&
              playlists.map((playlist) => {
                if (playlist && playlist.public) {
                  return (
                    <Link
                      to={`${ROUTES.CURATORS}/${curatorId}/${playlist?.id}`}
                      key={nanoid()}
                      className="curator-playlist"
                    >
                      <div className="w-full playlist-cover">
                        {playlist?.images && playlist?.images[0]?.url ? (
                          <img src={playlist?.images[0]?.url} alt={playlist?.name} />
                        ) : (
                          <Placeholder icon="music" className="w-full" />
                        )}
                      </div>
                      <div className="flex flex-col items-start gap-2">
                        <h3>{playlist?.name}</h3>
                        <Badge
                          icon="users"
                          label={<FormattedMessage id="match.table.followers" />}
                          value={playlist?.followers?.total || `0`}
                          variant="light"
                        />
                      </div>
                    </Link>
                  )
                }
              })}
          </div>

          {!isLoading && playlists && playlists.length === 0 && (
            <Empty
              message={intl.formatMessage({
                id: 'empty_state.no_playlists_found',
              })}
            />
          )}

          {isLoading && <Spinner />}
        </div>
      </Auth>
    </Wrapper>
  )
}


export default CuratorDetail
