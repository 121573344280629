import { make } from './endpoints'

export const sendRequest = (typology, formData) =>
  make.post(`requests`, { body: { typology, formData } }).toPromise()

export const getUserRequests = () => make.get(`requests`).toPromise()

export const getRequestExists = (typology) => make.get(`requests/exists`, { query: { typology } }).toPromise()


export default {
  sendRequest,
  getUserRequests,
  getRequestExists,
}
