import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '@fnd/screens/constants'
import { FormattedMessage } from 'react-intl'

function CuratorPageButton({ isTrackInPlaylist }) {
  return (
    <div>
      <p>
        {isTrackInPlaylist ? (
          <span className="font-medium">
            <FormattedMessage id="match.playlist_popup.submit.track_already_in" />
          </span>
        ) : (
          <span>
            <FormattedMessage id="match.playlist_popup.submit.track_added" />
          </span>
        )}
      </p>

      <Link
        to={`${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.MY_ACTIVITY}`}
        className="button button-primary mt-3"
      >
        <FormattedMessage id="match.playlist_popup.submit.see_button" />
      </Link>
    </div>
  )
}

export default CuratorPageButton
