import React from 'react'
import { ResponsivePie } from '@nivo/pie'
import { createColorScale } from './helpers'
import dayjs from 'dayjs'


export const PieChart = ({
  chartData,
  primaryColor = '#5e4af9',
  arcLabelsTextColor = 'transparent',
}) => {
  const chartColor = primaryColor
  const colorScale = createColorScale(chartColor, 10)

  return (
    <div className="chart-pie">
      <ResponsivePie
        data={chartData}
        margin={{ top: 80, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.7}
        padAngle={1}
        cornerRadius={4}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        arcLinkLabelsSkipAngle={5}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsTextColor={arcLabelsTextColor}
        arcLabelsSkipAngle={2}
        colors={colorScale}
        theme={{
          labels: {
            text: {
              fontSize: 16,
              fontWeight: 600,
            },
          },
        }}
        tooltip={({ datum: { data } }) => {
          if (!data) return

          return (
            <div className="chart-tooltip">
              <div className="rc-tooltip-inner px-3 py-2">
                <span>
                  <b>{data.label}</b>: {data.value}
                </span>
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}

export default PieChart
