import React, { useState, useEffect } from 'react'
import Spinner from '@fnd/components/Spinner'
import { QUERIES, SUBMISSIONS_PER_PAGE } from '@fnd/constants'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { useInView } from 'react-intersection-observer'
import { useInfiniteQuery } from '@tanstack/react-query'
import SubmissionsTableItem from './SubmissionsTableItem'
import Empty from '@fnd/components/Empty'
import { useIntl } from 'react-intl'

function SubmissionsTable({ trackId }) {
  const [total, setTotal] = useState(0)
  const intl = useIntl()

  const queryKey = `${QUERIES.TRACK_PUSHED.LIST}-${trackId}`

  const {
    data,
    isFetchingNextPage,
    isLoading,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery({
    queryKey: [queryKey],
    queryFn: async (params) => {
      const res = await spotimatchEndpoints.getPushedPlaylists({
        trackId,
        limit: SUBMISSIONS_PER_PAGE,
        ...params
      })
      setTotal(res.total)
      return res
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage?.next_page) return
      return lastPage.next_page
    },
    enabled: !!trackId
  })

  const { ref, inView } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage, hasNextPage])

  const submissions = data?.pages?.map((page) => page.data).flat() || []

  return (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>
                {intl.formatMessage({ id: 'profile.my_activity.pushed_to_playlists_table.playlist' })}
              </th>
              <th className="text-center">
                {intl.formatMessage({ id: 'profile.my_activity.pushed_to_playlists_table.status' })}
              </th>
              <th>
                {intl.formatMessage({ id: 'profile.my_activity.pushed_to_playlists_table.owner' })}
              </th>
              <th>
                {intl.formatMessage({ id: 'profile.my_activity.pushed_to_playlists_table.pushed_by' })}
              </th>
              <th>
                {intl.formatMessage({ id: 'messages.compatibility' })}
              </th>
              <th>
                {intl.formatMessage({ id: 'messages.sent_created_at' })}
              </th>
            </tr>
          </thead>
          <tbody>
            {submissions.map((sub, index) => (
              <SubmissionsTableItem
                key={`${sub.playlist.id}-${index}`}
                playlist={sub?.playlist}
                submitter={sub?.submitter}
              />
            ))}
          </tbody>
        </table>

        {(isLoading || isFetchingNextPage) && <Spinner />}

        {!isLoading && submissions.length === 0 && (
          <div className="p-4">
            <Empty className="m-0" />
          </div>
        )}

      </div>

      <div ref={ref} />
    </>
  )
}

export default SubmissionsTable
