import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'classnames'
import Tooltip from 'rc-tooltip'
import Alert from '@fnd/components/Alert'
import Badge from '@fnd/components/Badge'
import Button from '@fnd/components/Button'
import Spinner from '@fnd/components/Spinner'
import Date from '@fnd/components/Date'
import { useQueries } from '@tanstack/react-query'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { usePlaylistStore } from '@fnd/store'
import { useConsent } from '@fnd/context/ConsentProvider'
import { isMobile } from 'react-device-detect'
import { QUERIES } from '@fnd/constants'

export const PlaylistGenres = ({ className, closeModalFn }) => {
  const intl = useIntl()

  const {
    currentPlaylistId,
    genresUpdateRequest
  } = usePlaylistStore()

  const { handleIntercomToggle } = useConsent()

  const [playlistGenres, isRecentlyUpdated] = useQueries({
    queries: [
      {
        queryKey: [QUERIES.PLAYLIST.GENRES, currentPlaylistId],
        queryFn: () => spotimatchEndpoints.getPlaylistGenres(currentPlaylistId).toPromise(),
      },
      {
        queryKey: [QUERIES.PLAYLIST.GENRES_CHECK_UPDATE, currentPlaylistId],
        queryFn: () => spotimatchEndpoints.checkGenresRecentlyUpdated(currentPlaylistId).toPromise(),
      },
    ]
  })

  const handleTagsUpdate = async () => {
    try {
      await genresUpdateRequest(currentPlaylistId)
      closeModalFn()
    } catch (e) {
      console.log(e)
    }
  }
  const classes = classNames({
    'field-input-container': true,
    'field-icon-before': true,
    [className]: className,
  })

  const ButtonWrapper = ({ children }) => {
    if (!isRecentlyUpdated?.data?.status) {
      return children
    }

    return (
      <Tooltip
        placement="top"
        trigger={['hover']}
        overlay={<span><FormattedMessage id="messages.is_recent_updated" /></span>}
      >
        {children}
      </Tooltip>
    )
  }

  if (playlistGenres?.isLoading) {
    return <Spinner />
  }

  return (
    <div className={classes}>
      <div className="w-full mt-3">
        {playlistGenres?.data && playlistGenres?.data?.genres?.length > 0 && (
          <div className="tags">
            {playlistGenres?.data?.genres.map((genre) => {
              return (
                <span key={genre} className="tag p-0 capitalize">
                  <span className="px-4">{genre}</span>
                </span>
              )
            })}
          </div>
        )}
      </div>

      {playlistGenres?.data && playlistGenres?.data?.latestUpdate && (
        <Badge
          className="text-base mt-4 mb-5"
          icon="clock"
          vertical={isMobile ? true : false}
          variant="blue-light"
          label={intl.formatMessage({ id: 'messages.latest_update_genres' })}
          value={
            <Date
              dateString={playlistGenres?.data?.latestUpdate}
              includeTime={true}
            />
          }
        />
      )}

      {playlistGenres?.data?.genres?.length === 0 && isRecentlyUpdated?.data?.status && (
        <Alert
          className="mb-4"
          variant="primary-light"
          label={intl.formatMessage({ id: 'messages.no_genres_found' })}
          cta={
            <Button
              small
              icon="question-circle"
              className="toggle-intercom"
              onClick={handleIntercomToggle}
              label={intl.formatMessage({ id: 'messages.support' })}
            />
          }
        />
      )}

      <ButtonWrapper>
        <Button
          className="button-wide mt-3"
          onClick={handleTagsUpdate}
          disabled={isRecentlyUpdated?.data?.status}
        >
          <FormattedMessage id="messages.update_genres" />
        </Button>
      </ButtonWrapper>
    </div>
  )
}

export default PlaylistGenres
