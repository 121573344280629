import React from 'react'
import { useParams } from 'react-router-dom'
import ProfileWrapper from '@fnd/modules/Profile/ProfileWrapper'
import SubmissionsTable from './SubmissionsTable'
import { usePushedTrackStore } from '@fnd/store'


export default function TracksPushedDetail() {
  const { trackId } = useParams()
  const { currentTrack } = usePushedTrackStore()

  return (
    <ProfileWrapper
      back
      title={currentTrack?.name}
      image={currentTrack?.album?.images?.[0]?.url}
      bodyClasses="p-0"
    >
      <SubmissionsTable trackId={trackId} />
    </ProfileWrapper>
  )
}
