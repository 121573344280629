import { CURRENCY, PRODUCTION } from "@fnd/constants"
import { nanoid } from "nanoid"
import { spotimatchEndpoints } from "@fnd/core/spotimatch"
import ReactGA from "react-ga4"
import { isAxiosError } from "axios"

export const EVENTS = {
  INITIATE_CHECKOUT: 'InitiateCheckout',
  NEW_USER: 'NewUser',
  PURCHASE: 'Purchase',
}

export const GTAG_ADVANCED_CONVERSION = {
  PURCHASE: 'AW-753455424/_ohtCKz2npcYEMCio-cC',
  SIGN_UP: 'AW-753455424/nf4eCOrAn5cYEMCio-cC',
}

const waitForPixel = (timeout = 1000, maxRetries = 10) => {
  return new Promise((resolve, reject) => {
    let attempts = 0;

    (function checkPixel() {
      if (typeof window.fbq === 'function') {
        resolve()
      } else if (attempts >= maxRetries) {
        reject(new Error(`fbq not loaded after ${maxRetries} attempts`))
      } else {
        attempts++
        setTimeout(checkPixel, timeout)
      }
    })()
  })
}

const trackNewUser = async (eventId, payload) => {
  const { profile } = payload

  if (window.fbq) {
    window.fbq('track', 'CompleteRegistration', {
      eventID: eventId,
    })
  }

  ReactGA.event('sign_up')

  ReactGA.set('user_data', {
    userId: profile?.spotify_id,
    email: profile?.contacts?.email,
    phone_number: profile?.contacts?.phone,
  })

  if (window.gtag) {
    window.gtag('event', 'sign_up', {
      send_to: GTAG_ADVANCED_CONVERSION.SIGN_UP,
    })
  }

  if (window.ttq) {
    window.ttq.track('CompleteRegistration', {
      contents: [
        {
          content_name: profile?.contacts?.email,
        }
      ]
    })
  }
}

const trackPurchase = async (eventId, payload) => {
  const { status, price, plan, profile } = payload

  ReactGA.event({
    category: 'Payments',
    action: 'User has paid for an item',
  })

  await waitForPixel()

  if (window.fbq) {
    window.fbq('track', 'Purchase', {
      value: price,
      currency: CURRENCY,
    }, { eventID: eventId })
  }

  if (window.dataLayer && profile) {
    window.dataLayer.push({
      userId: profile?.spotify_id,
      email: profile?.contacts?.email,
      phone_number: profile?.contacts?.phone,
    })
  }

  if (window.dataLayer) {
    window.dataLayer.push({
      conversionValue: parseFloat(price),
      value: parseFloat(price),
    })
  }

  if (window.gtag) {
    window.gtag('event', 'purchase', {
      value: parseFloat(price),
      currency: CURRENCY,
      items: [
        {
          id: plan,
          name: plan,
          price,
        },
      ],
    })

    window.gtag('event', 'conversion', {
      send_to: GTAG_ADVANCED_CONVERSION.PURCHASE,
      value: parseFloat(price),
      currency: CURRENCY,
    })
  }

  if (window.ttq) {
    window.ttq.track('CompletePayment', {
      value: price,
      currency: CURRENCY,
      status,
      plan,
      content_id: eventId,
      event_id: eventId,
    })
  }
}

const initiateCheckout = async (eventId, payload) => {
  const { slug: planSlug, title, price } = payload

  if (window.fbq) {
    window.fbq('track', 'InitiateCheckout', {
      value: price,
      currency: CURRENCY,
      content_name: title,
    }, { eventID: eventId })
  }

  if (window.gtag) {
    window.gtag('event', 'begin_checkout', {
      value: price,
      currency: CURRENCY,
      items: [
        {
          id: planSlug,
          name: title,
          price,
        },
      ],
    })
  }

  if (window.ttq) {
    window.ttq.track('InitiateCheckout', {
      value: price,
      currency: CURRENCY,
      contents: [
        {
          content_name: title,
          content_id: planSlug,
          price: price,
        },
      ],
    })
  }
}

export const trackEvent = async (slug, payload, id) => {
  if (!PRODUCTION) return

  const eventId = id || nanoid()
  try {
    await spotimatchEndpoints.sendEvent(eventId, slug, payload)
    switch (slug) {
      case EVENTS.INITIATE_CHECKOUT:
        await initiateCheckout(eventId, payload)
        break
      case EVENTS.NEW_USER:
        await trackNewUser(eventId, payload)
        break
      case EVENTS.PURCHASE:
        await trackPurchase(eventId, payload)
        break
      default:
        return
    }
  } catch (e) {
    if (!isAxiosError(e)) {
      throw e
    }
  }
}

export default trackEvent
