import React, { useState, useEffect } from 'react'
import AsyncSelect from 'react-select/async'
import { FormattedMessage } from 'react-intl'
import debounce from 'lodash/debounce'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import ValueContainer from './GenresSelectValueContainer'
import classNames from 'classnames'

const loadOptions = (query, callback) => {
  spotimatchEndpoints
    .getQueries(query)
    .toPromise()
    .then((results) =>
      callback(
        results.map((genre) => ({ label: genre.query, value: genre.query }))
      )
    )
    .catch(console.error)
}

const debouncedLoadOptions = debounce(loadOptions, 1000)

const Genres = React.forwardRef(({
  id,
  defaultValue,
  genres,
  onGenreSelect,
  hideTitle,
  title,
  isDisabled,
  label,
  errors,
  hideLabel = false,
  maxLimit,
  className
}, ref) => {
  const [value, setValue] = useState([])

  const handleGenreChange = (options, { action }) => {
    const newOptions = options || []
    setValue(newOptions)

    const actionsList = ['select-option', 'remove-value', 'clear']
    if (actionsList.includes(action)) {
      onGenreSelect(newOptions.map((option) => option.value))
    }
  }

  const checkIfOptionDisabled = (option) => {
    const isMaxLimitReached = genres && genres.length >= maxLimit
    const isOptionAlreadySelected = genres && genres.includes(option.value)
    return isMaxLimitReached && !isOptionAlreadySelected
  }

  useEffect(() => {
    if (defaultValue) {
      setValue(
        defaultValue.map((genre) => ({ value: genre, label: genre }))
      )
    }
  }, [defaultValue])

  useEffect(() => {
    if (genres) {
      setValue(genres.map((genre) => ({ value: genre, label: genre })))
    }
  }, [genres])

  const classes = classNames({
    'field-input-container': true,
    'field-error': errors,
    'cursor-not-allowed opacity-50': isDisabled,
    [className]: className,
  })

  return (
    <div id={id} className={classes}>
      {!hideTitle && title && <h5>
        {title}
      </h5>}

      {!hideLabel && <label htmlFor="genres-select">
        {label ? label : <FormattedMessage id="match.second_step.label" />}
      </label>}

      <AsyncSelect
        ref={ref}
        components={{
          ValueContainer,
        }}
        isDisabled={isDisabled}
        isMulti
        isClearable
        inputId="genres-select"
        onChange={handleGenreChange}
        loadOptions={debouncedLoadOptions}
        defaultOptions
        placeholder={
          <FormattedMessage id="profile.my_playlists.settings_popup.settings.playlist_genres_select_placeholder" />
        }
        isOptionDisabled={checkIfOptionDisabled}
        className="select"
        classNamePrefix="select"
        value={value}
      />
    </div>
  )
})

export default Genres

Genres.defaultProps = {
  hideTitle: false,
}
