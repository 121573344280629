import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Countdown from '@fnd/components/Countdown/Countdown'
import { CURRENCY_SYMBOL } from '@fnd/constants'

const PlanPriceDefault = ({
  price,
  salePrice,
  countdown,
  duration,
  displayMonthly,
}) => {
  const intl = useIntl()
  const planSave = salePrice ? (price - salePrice).toFixed(0) : 0
  const discountPercentage = salePrice ? (planSave / price) * 100 : 0
  const monthlyPrice = (price / 12).toFixed(2)
  const monthlySalePrice = (salePrice / 12).toFixed(2)
  const monthLabel = intl.formatMessage({ id: 'messages.month' })

  if (!displayMonthly) {
    return (
      <div className="w-full">
        {!!salePrice && (
          <span className="plan-sale">
            {CURRENCY_SYMBOL}
            {price}/{duration}
          </span>
        )}

        {salePrice > 0 && (
          <h3>
            {CURRENCY_SYMBOL}
            {salePrice || price}
            <span className="ml-1">/{duration}</span>
          </h3>
        )}

        {salePrice > 0 && price > 0 && (
          <span className="tag tag-green text-lg mt-2">
            <FormattedMessage id="messages.you_save" />{' '}
            {planSave}{' '}
            {CURRENCY_SYMBOL}
          </span>
        )}

        {countdown && <Countdown className="mt-4" initialCount={countdown} />}

        {salePrice === 0 && (
          <>
            <span className="plan-sale">
              {CURRENCY_SYMBOL}
              {price}{' / '}
              {duration}
            </span>
            <h3 className="plan-free">Free</h3>
          </>
        )}
      </div>
    )
  }

  return (
    <div className="w-full">
      {!!salePrice && (
        <span className="plan-sale">
          {CURRENCY_SYMBOL}
          {monthlyPrice}{' / '}
          {monthLabel}
        </span>
      )}

      {salePrice > 0 && (
        <h3 className="flex items-center">
          {CURRENCY_SYMBOL}
          {monthlySalePrice || monthlyPrice}{' / '}
          {monthLabel}
        </h3>
      )}

      <span className="block plan-billing mt-2">
        <FormattedMessage id="messages.billed_yearly" />
      </span>

      {salePrice > 0 && price > 0 && (
        <span className="tag tag-green text-lg mt-2">
          <FormattedMessage id="messages.you_save" />{' '}
          {discountPercentage.toFixed(0)}{' '}{'%'}
        </span>
      )}

      {countdown && <Countdown className="mt-4" initialCount={countdown} />}

      {salePrice === 0 && (
        <>
          <span className="plan-sale">
            {CURRENCY_SYMBOL}
            {price}{' / '}{monthLabel}
          </span>
          <h3 className="plan-free">Free</h3>
        </>
      )}
    </div>
  )
}

export default PlanPriceDefault
