import React from 'react'
import Button from '@fnd/components/Button'
import { FormattedMessage } from 'react-intl'

function PushButton({ className, disabled, onClick }) {
  return (
    <div className={className ?? null}>
      <p><FormattedMessage id="push_to_playlist.text" /></p>
      <Button
        disabled={disabled}
        variant="gradient"
        className="mt-3"
        label={<FormattedMessage id="push_to_playlist.cta" />}
        onClick={onClick}
      />
    </div>
  )
}

export default PushButton
