import { make } from './endpoints'

export const canUseDiscountCode = (code, query) => make.get(`/discount/can-use/${code}?${query}`).toPromise()

export const getDiscountByCode = (code) => make.get(`/discount/${code}`).toPromise()

export const redeemDiscountCode = (code, plan, price) =>
  make.post(`/discount/${code}`, { body: { plan, price }}).toPromise()

export default {
  canUseDiscountCode,
  getDiscountByCode,
  redeemDiscountCode
}
