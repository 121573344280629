import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useUserContext } from '@fnd/modules/User'
import { ROUTES } from '@fnd/screens/constants'
import { userProfileSelector } from '../selectors'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import AccountSubscription from './AccountSubscription'
import AccountSettings from './AccountSettings'
import Badge from '@fnd/components/Badge'
import Button from '@fnd/components/Button'
import ConfirmationPopup from '@fnd/components/ConfirmationPopup'
import { toastFeedback } from '@fnd/core/libs/toast'
import { spotifyStorage } from '@fnd/core/spotify'
import { useNavigate } from 'react-router-dom'
import { nanoid } from 'nanoid'

const reformatDate = (date) => {
  const formatDate = new Date(date)
  const day = formatDate.getDate()
  const month = formatDate.getMonth() + 1
  const year = formatDate.getFullYear()
  const addZero = (num) => (num < 10 ? '0' : '') + num

  return `${addZero(day)}/${addZero(month)}/${year}`
}

function Account() {
  const navigate = useNavigate()
  const [confirmationPopupState, setConfirmationPopupState] = useState(false)
  const { profile, updateUserNotifications } = useUserContext(
    ({ user, updateUserProfile }) => ({
      profile: userProfileSelector(user),
      updateUserNotifications: (type, next) => {
        const key = type === 'web' ? 'web_notifications' : 'notifications'
        return updateUserProfile({ [key]: { $set: next } })
      },
    })
  )

  const vipTracks = profile.vip_plans
  const isActive = (expDate) =>
    new Date().getTime() <= new Date(expDate).getTime()
  const intl = useIntl()

  const handleUserExport = async () => {
    try {
      await spotimatchEndpoints.exportUserData()
      toastFeedback(
        'success',
        intl.formatMessage({ id: 'feedback.success.user_data_export' })
      )
    } catch (error) {
      console.log(error)
      toastFeedback(
        'error',
        intl.formatMessage({ id: 'feedback.error.default' })
      )
    }
  }

  const handleUserDelete = async () => {
    try {
      await spotimatchEndpoints.deleteUser()
      toastFeedback(
        'success',
        intl.formatMessage({ id: 'feedback.success.user_delete' })
      )
      setConfirmationPopupState(false)
      spotimatchEndpoints
        .logout()
        .toPromise()
        .then(() => {
          setTimeout(() => {
            spotifyStorage.clear()
            navigate(ROUTES.HOME)
            window.location.reload(false)
          }, 1000)
        })
    } catch (error) {
      console.log(error)
      toastFeedback(
        'error',
        intl.formatMessage({ id: 'feedback.error.default' })
      )
    }
  }

  return (
    <div className="mt-4">
      <AccountSubscription />

      <div className="account-section">
        <h5>
          <FormattedMessage id="profile.account.export_data" />
        </h5>
        <div className="flex items-center gap-2">
          <Button
            icon="file-export"
            onClick={handleUserExport}
            variant="primary-light"
          >
            <FormattedMessage id="profile.account.export_data_cta" />
          </Button>
          <Button
            icon="trash"
            variant="red-light"
            onClick={() => setConfirmationPopupState(!confirmationPopupState)}
          >
            <FormattedMessage id="profile.account.delete" />
          </Button>

          <ConfirmationPopup
            bodyClasses="p-5"
            isOpen={confirmationPopupState}
            onConfirm={() => handleUserDelete()}
            onReject={() => setConfirmationPopupState(false)}
            title={intl.formatMessage({ id: 'profile.account.delete' })}
            text={intl.formatMessage({
              id: 'profile.account.delete_confirmation',
            })}
          />
        </div>
      </div>

      <AccountSettings
        settings={profile.settings}
        notifications={profile.notifications}
        web_notifications={profile.web_notifications}
        updateUserNotifications={updateUserNotifications}
      />

      {vipTracks && vipTracks.length > 0 && (
        <div className="plan-vip mt-8">
          <h5 className="account-vip">VIP</h5>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="profile.account_plan.vip_table.track_ids" />
                  </th>
                  <th>
                    <FormattedMessage id="profile.account_plan.vip_table.status" />
                  </th>
                  <th>
                    <FormattedMessage id="profile.account_plan.purchased_one" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {vipTracks.map((track) => {
                  const planStatus = isActive(track.expired_at) ? (
                    <Badge
                      variant="primary-light"
                      icon="check"
                      label={<FormattedMessage id="messages.active" />}
                    />
                  ) : (
                    <Badge
                      variant="light"
                      icon="times"
                      label={<FormattedMessage id="messages.closed" />}
                    />
                  )

                  return (
                    <tr key={nanoid()}>
                      <td className="track-id">{track.track_id}</td>
                      <td>{planStatus}</td>
                      <td className="track-purchased">
                        {reformatDate(track.created_at)}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default Account
