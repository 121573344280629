import { spotifyStorage } from '@fnd/core/spotify'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import update from 'immutability-helper'
import { EVENTS, trackEvent } from '@fnd/core/libs/event-tracker'
import { compose, setDisplayName, withHandlers } from 'recompose'
import { EMPTY } from 'rxjs'
import { catchError, finalize } from 'rxjs/operators'
import { isUserAllowedSelector } from '../selectors'
import {
  initialUserContextState,
  withUserContextConsumer,
} from './withUserContext'

const handlers = {
  authorize({ user, setUserState }) {
    const patch = (next) => setUserState((current) => update(current, next))

    return function _authorize(isLogin = false, metadata = null) {
      if (isUserAllowedSelector(user)) {
        return
      }

      patch({ isLoading: { $set: true } })

      const handleError$ = catchError((error) => {
        patch({ error: { $set: error } })
        return EMPTY
      })

      try {
        if (localStorage.getItem('metadata-storage')) {
          metadata = JSON.parse(localStorage.getItem('metadata-storage'))?.state?.metadata
        }
      } catch (error) {
        console.log('Failed metadata parse: ' + error)
      }

      spotimatchEndpoints
        .registerMe(isLogin, metadata)
        .pipe(
          handleError$,
          finalize(() => patch({ isLoading: { $set: false } }))
        )
        .subscribe(async ({ profile, spotifyProfile, newUser, version }) => {
          if (newUser) {
            try {
              await trackEvent(EVENTS.NEW_USER, { profile })
            } catch (error) {
              console.log(error)
            }
          }

          patch({
            error: { $set: void 0 },
            profile: { $set: { ...profile, ...spotifyProfile } },
            isAuthorized: { $set: true },
            version: { $set: version },
          })
        })
    }
  },

  unauthorize({ setUserState }) {
    return function _unauthorize() {
      spotimatchEndpoints
        .logout()
        .toPromise()
        .then(() => {
          setUserState(initialUserContextState)
          spotifyStorage.clear()

          localStorage.removeItem('app-storage')
          localStorage.removeItem('banner-dismissed')
          localStorage.removeItem('metadata-storage')
          localStorage.removeItem('payment-storage')
          localStorage.removeItem('playlist-storage')
          localStorage.removeItem('pushtoplaylist-storage')
          localStorage.removeItem('recent-match-storage')
          localStorage.removeItem('vip-campaign-storage')
        })
    }
  },
}

const withUserAuthentication = compose(
  setDisplayName('withUserAuthentication'),
  withHandlers(handlers)
)

export default compose(withUserContextConsumer, withUserAuthentication)
