import React, { useState } from 'react'
import Badge from '@fnd/components/Badge'
import Emoji from '@fnd/components/Emoji'
import Icon from '@fnd/components/Icon'
import PlanPrice from './PlanPrice'
import { useUserContext } from '@fnd/modules/User'
import { FormattedMessage } from 'react-intl'
import { PlanActionAnalysis, PlanActionDefault, PlanActionVip } from './actions'
import classNames from 'classnames'


const PlanCard = (props) => {
  const {
    title,
    slug,
    icon,
    color,
    features = [],
    caption,
    price,
    salePrice,
    paymentPrice,
    layout,
    disabled,
    displayMonthly,
    disabledDowngrade,
    isActive,
    mostPopular,
    countdown,
    duration,
    socialProof,
    cta,
    defaultOpen = false,
  } = props

  const [expandFeatures, setExpandFeatures] = useState(false)
  const displayedFeatures = expandFeatures ? features : features.slice(0, 3)

  const { user } = useUserContext()

  const classes = classNames({
    plan: true,
    [`plan-${color}`]: color && !isActive,
    'plan-primary ': isActive,
    'plan-active ': isActive,
    'plan-most-popular': mostPopular,
  })

  return (
    <div className={classes}>
      {socialProof && (
        <Badge
          emoji={<Emoji symbol="🏆" className="text-base mr-2" label="Trophy" />}
          className="mb-3 whitespace-normal"
          variant={`${color}-light`}
          label={socialProof}
          color={color}
        />
      )}

      <div className="plan-header">
        <h2 className={`text-${color}`}>
          {title}
        </h2>
        {typeof icon === 'string' ? <Icon name={icon} /> : icon}
      </div>

      {caption && <span className="plan-caption">{caption}</span>}

      <PlanPrice
        countdown={countdown}
        price={price}
        salePrice={salePrice}
        layout={layout}
        displayMonthly={displayMonthly}
        duration={duration}
      />

      {features?.length > 0 && (
        <ul className="plan-features">
          {displayedFeatures.map((feature) => {
            return (
              <li key={feature.text}>
                <Icon name="check" />
                {feature?.highlight ? <b>{feature?.text}</b> : feature?.text}
              </li>
            )
          })}

          <a
            className={`font-bold text-${color}`}
            onClick={() => setExpandFeatures(!expandFeatures)}
          >
            {!expandFeatures ? (
              <>
                <Icon name="chevron-down" className="mr-2" />
                <FormattedMessage id="messages.read_more" />
              </>
            ) : (
              <>
                <Icon name="chevron-up" className="mr-2" />
                <FormattedMessage id="messages.read_less" />
              </>
            )}
          </a>
        </ul>
      )}

      <div className="plan-action">
        {cta && cta}

        {layout === 'analysis' && <PlanActionAnalysis
          title={title}
          slug={slug}
          icon={icon}
          color={color}
          disabled={disabled}
          price={price}
          user={user}
        />}

        {layout === 'default' && <PlanActionDefault
          title={title}
          slug={slug}
          icon={icon}
          color={color}
          isActive={isActive}
          displayMonthly={displayMonthly}
          disabled={disabled}
          disabledDowngrade={disabledDowngrade}
          defaultOpen={defaultOpen}
          user={user}
          paymentPrice={paymentPrice}
          price={price}
          salePrice={salePrice}
        />}

        {layout === 'vip' && <PlanActionVip
          color={color}
          disabled={disabled}
        />}
      </div>
    </div>
  )
}

export default PlanCard
