import React, { useState, useEffect, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'classnames'
import Button from '@fnd/components/Button'
import Empty from '@fnd/components/Empty'
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuGroup,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "@fnd/components/Dropdown/DropdownMenu"
import NotificationBadge from './NotificationBadge'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { ROUTES } from '@fnd/screens/constants'
import { NotificationDropdownItem } from '@fnd/components/Notification'
import { NOTIFICATION_FETCH_TIMEOUT } from '@fnd/constants'
import {
  isNotificationEnabled,
  useUserContext,
  withUserAuthentication,
} from '@fnd/modules/User'
import NotificationSound from "./../../../public/audio/notification_sound.mp3"

export const NotificationDropdown = ({ className }) => {
  const intl = useIntl()
  const [notifications, setNotifications] = useState([])
  const [notificationsNumber, setNotificationsNumber] = useState(0)
  const [menuItems, setMenuItems] = useState([])

  const { notificationEnabled } = useUserContext(({ user }) => {
    return {
      notificationEnabled: isNotificationEnabled(user),
    }
  })

  const notificationEmpty = useMemo(() => [
    {
      id: 'empty',
      label: (
        <Empty
          small
          ghost
          className="w-full m-0"
          icon="bell-slash"
          message={intl.formatMessage({
            id: 'notifications.unread.empty_state',
          })}
        />
      ),
    },
  ], [])

  const fetchData = async (isInterval = false) => {
    try {
      const { notifications: notificationData, notificationsNumber } = await spotimatchEndpoints.getNotificationDigest()
      if (notificationEnabled && isInterval && notificationData.length > notifications.length) {
        (new Audio(NotificationSound)).play()
      }
      setNotifications(notificationData)
      setNotificationsNumber(notificationsNumber)

      const notificationMenuItems = notificationData.map((notification) => {
        return {
          id: notification._id,
          label: <NotificationDropdownItem notification={notification} />,
        }
      })

      setMenuItems([...menuItems, ...notificationMenuItems])

      if (notificationData.length === 0) {
        setMenuItems([...notificationEmpty])
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => fetchData(true), NOTIFICATION_FETCH_TIMEOUT)
    return () => clearInterval(intervalId)
  }, [notifications])

  const classes = classNames({
    'notification-dropdown': true,
    [className]: className,
  })

  return (
    <DropdownMenu className={classes}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          icon="bell"
          className="gap-0"
        >
          <NotificationBadge count={notificationsNumber} />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end" className="w-[300px]">
        <DropdownMenuLabel>
          {intl.formatMessage({ id: 'notification.dropdown.title' })}
        </DropdownMenuLabel>

        <DropdownMenuSeparator />

        <DropdownMenuGroup className="notification-dropdown-menu max-h-[300px] overflow-y-auto">
          {menuItems.map((item) => (
            <DropdownMenuItem key={item.id}>
              {item.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>

        <DropdownMenuSeparator />
        <div className="p-2">
          <Button
            small
            className="w-full"
            to={ROUTES.NOTIFICATIONS}
            icon="eye"
          >
            <FormattedMessage id="messages.show_all" />
          </Button>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default withUserAuthentication(NotificationDropdown)
