import { create } from 'zustand'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { spotifyEndpoints } from '@fnd/core/spotify'


export const useQueueStore = create(
  (set, getState) => ({
    error: false,
    isLoading: false,
    scoreOffset: 0,
    success: false,
    loadingTrackId: undefined,
    setIsLoading: (isLoading) => set({ isLoading }),
    setLoadingTrackId: (loadingTrackId) => set({ loadingTrackId }),
    addTrackToPlaylist: async (playlistId, trackId, submitterId) => {
      try {
        const { scoreOffset } = getState()
        const { scoreOffsetValue } = await spotimatchEndpoints.addTrackToPlaylist(
          playlistId,
          trackId,
          submitterId
        ).toPromise()

        set({
          scoreOffset: scoreOffset + scoreOffsetValue,
          success: 'feedback.success.track_add_to_playlist',
        })
      } catch (error) {
        set({ error: 'feedback.error.default' })
        console.log(error)
      }
    },
    archiveTrackFromQueue: async (playlistId, trackId) => {
      try {
        await spotimatchEndpoints.archiveTrackFromQueue(playlistId, trackId).toPromise()
      } catch (error) {
        set({ error: 'feedback.error.default' })
        console.log(error)
      }
    },
    removeTrackFromPlaylist: async (playlistId, trackId, submitterId) => {
      try {
        const { scoreOffset } = getState()
        const { scoreOffsetValue } = await spotimatchEndpoints.removeTrackFromPlaylist(
          playlistId,
          trackId,
          submitterId
        ).toPromise()

        set({
          scoreOffset: scoreOffset - scoreOffsetValue,
          success: 'feedback.success.track_remove_from_playlist',
        })
      } catch (error) {
        set({ error: 'feedback.error.default' })
        console.log(error)
      }
    },
    removeTrackFromQueue: async (playlistId, trackId, feedback = false) => {
      try {
        const state = getState()
        await spotimatchEndpoints.removeTrackFromQueue(playlistId, trackId).toPromise()
        set({ scoreOffset: state.scoreOffset + 1, })

        if (feedback) {
          set({ success: 'feedback.success.track_remove_from_queue' })
        }
      } catch (error) {
        set({ error: 'feedback.error.default' })
        console.log(error)
      }
    },
    followArtists: async (artistIds) => {
      try {
        await spotifyEndpoints.followArtists(artistIds)
      } catch (error) {
        console.log(error)
      }
    },
    unfollowArtists: async (artistIds) => {
      try {
        await spotifyEndpoints.unfollowArtists(artistIds)
      } catch (error) {
        console.log(error)
      }
    },
    resetFeedback: () => set({ success: false, error: false }),
    resetLoadingTrackId: () => set({ loadingTrackId: undefined }),
    resetScoreOffset: () => set({ scoreOffset: 0 }),
  }),
)

export default useQueueStore
