import React from 'react'
import Badge, { CuratorScoreBadge } from '@fnd/components/Badge'
import Icon from '@fnd/components/Icon'
import MatchDetails from './MatchDetails'
import { humanReadableDuration } from '@fnd/core/libs/helpers'
import { buildSpotifyPlaylistUrl } from '@fnd/core/libs/platforms'
import { FormattedMessage, useIntl } from 'react-intl'
import { ConversationStart } from '@fnd/components/Chat'
import { formatNumber } from '@fnd/core/libs/currency'
import classNames from 'classnames'


function PlaylistOverview({
  playlist,
  curator,
  genres,
  score,
  values,
  wrapperClassName,
  children,
  className
}) {
  const {
    id,
    name,
    image,
    followers,
  } = playlist

  const intl = useIntl()
  const { locale } = intl

  const getPlaylistImage = () => {
    if (image) {
      return image
    }

    if (playlist.images && playlist.images.length) {
      return playlist.images[0].url
    }
  }

  const getPlaylistFollowers = () => {
    if (followers?.total !== null && followers?.total !== undefined) {
      return `${formatNumber(followers.total)}`
    }

    if (typeof followers === 'number') {
      return `${formatNumber(followers)}`
    }

    return `0`
  }

  const classes = classNames({
    'playlist-overview': true,
    'grid-cols-1': !values,
    [className]: className
  })

  const wrapperClasses = classNames({
    'playlist-info-wrapper': true,
    [wrapperClassName]: wrapperClassName
  })

  return (
    <div key={id} className={classes}>
      <div className={wrapperClasses}>
        {getPlaylistImage() && <div
          className="playlist-cover"
          style={{ backgroundImage: `url("${getPlaylistImage()}")` }}
        />}

        <div className="playlist-info">
          <div className="playlist-meta">
            {score ? (
              <Badge
                variant="gradient"
                icon="star"
                label="Match"
                value={`${score}%`}
              />
            ) : null}

            {curator && (
              <Badge
                variant="light"
                icon="user-circle"
                label={curator.display_name}
                value={
                  <ConversationStart
                    inline
                    userId={curator.id}
                  />
                }
              />
            )}

            {followers ? (
              <Badge
                variant="light"
                icon="users"
                label={<FormattedMessage id="match.table.followers" />}
                value={getPlaylistFollowers()}
              />
            ) : null}
          </div>

          <h2>{name}</h2>

          {curator && (
            <div className="flex flex-wrap gap-2">
              <CuratorScoreBadge
                label={<FormattedMessage id="curator.score.responseRate" />}
                value={curator?.responseRate > 0 ? `${(curator?.responseRate * 100).toFixed(0)}%` : null}
                icon="comment"
                className="capitalize"
                variant="light"
                fallbackValue={<FormattedMessage id="curator.score.noData" />}
              />

              <CuratorScoreBadge
                label={<FormattedMessage id="curator.score.timeMean" />}
                value={curator?.timeMean > 0 ? humanReadableDuration(curator?.timeMean, locale) : null}
                icon="clock"
                className="capitalize"
                variant="light"
                fallbackValue={<FormattedMessage id="curator.score.noData" />}
              />
            </div>
          )}

          {genres && genres.length > 0 && (
            <div className="flex flex-wrap gap-1">
              {genres.map(genre => (
                <Badge
                  key={genre}
                  className="capitalize"
                  variant="primary-light"
                  label={genre}
                />
              ))}
            </div>
          )}

          {playlist && playlist.description && (
            <p className="mt-2">
              {playlist.description}
            </p>
          )}

          {children}

          {id && (
            <a
              href={buildSpotifyPlaylistUrl(id)}
              className="button button-primary-light mt-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon className="mr-1" type="brands" name="spotify" />
              <FormattedMessage id="messages.open_on_spotify" />
            </a>
          )}
        </div>
      </div>

      {values ? <MatchDetails values={values} /> : null}
    </div>
  )
}

export default PlaylistOverview
