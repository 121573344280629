import React from 'react'
import Avatar from '@fnd/components/Avatar'
import Button from '@fnd/components/Button'
import Card from '@fnd/components/Card'
import { Placeholder } from '@fnd/components/Placeholder'
import { ROUTES } from '@fnd/screens/constants'
import classNames from 'classnames'

function ArtistCard({ artist, className }) {
  const artistUrl = `https://open.spotify.com/artist/${artist.id}`

  const classes = classNames({
    'border-outline bg-secondary py-3 px-5': true,
    [className]: className,
  })

  return (
    <Card
      className={classes}
      contentClasses="flex items-center gap-4"
    >
      <div className="flex items-center gap-3">
        {artist.images.length ? (
          <a href={artistUrl} target="_blank" rel="noreferrer">
            <Avatar
              src={artist.images[0].url}
              alt={artist.name}
              className="avatar rounded-full"
            />
          </a>
        ) : (
          <Placeholder
            avatar
            className="w-14 h-14 p-0"
            icon="user-music"
          />
        )}

        {artist.name && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="font-medium"
            href={artistUrl}
          >
            {artist.name}
          </a>
        )}
      </div>

      <Button
        variant="ghost"
        icon="cog"
        to={`${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.MY_ARTISTS}`}
      />
    </Card>
  )
}

export default ArtistCard
