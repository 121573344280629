import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Spinner from '@fnd/components/Spinner'
import {
  useUserContext,
  isUserAuthorizedSelector,
  userProfileSelector,
  isUserLoadingSelector,
} from '@fnd/modules/User'
import Button from '@fnd/components/Button'
import CuratorPromote from '@fnd/modules/Curator/CuratorPromote'
import PlaylistsTable from '@fnd/modules/Curator/Playlist/PlaylistsTable'
import PlaylistCreatePopup from '@fnd/modules/Curator/Playlist/PlaylistCreatePopup'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { getDateFormat } from '@fnd/core/libs/helpers'
import dayjs from 'dayjs'
import { toastFeedback } from '@fnd/core/libs/toast'

function CuratorPlaylists() {
  const intl = useIntl()
  const { locale } = intl

  const [playlistTableKey, setPlaylistTableKey] = useState('playlist_table_key')
  const [isPlaylistCreatePopupShown, setIsPlaylistCreatePopupShown] = useState(false)
  const [updateLoading, setUpdateLoading] = useState(false)
  const [updatedAt, setUpdatedAt] = useState(dayjs().format(getDateFormat(locale, true)))

  const {
    profile = {},
    isLoading,
  } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
    isLoading: isUserLoadingSelector(user),
    isAuthorized: isUserAuthorizedSelector(user),
  }))

  const showPlaylistCreatePopup = () => setIsPlaylistCreatePopupShown(true)

  const hidePlaylistCreatePopup = (isPlaylistCreated) => {
    setIsPlaylistCreatePopupShown(false)

    if (isPlaylistCreated === true) {
      setPlaylistTableKey((currentState) => currentState + 1)
    }
  }

  const fetchPlaylistData = async () => {
    try {
      setUpdateLoading(true)
      await spotimatchEndpoints.fetchPlaylistData().toPromise()
      setUpdatedAt(dayjs().format(getDateFormat(locale, true)))
      toastFeedback('success', intl.formatMessage({ id: 'feedback.success.playlist_fetch' }))
    } catch (error) {
      console.error(error)
      toastFeedback('error', intl.formatMessage({ id: 'feedback.error.generic' }))
    } finally {
      setTimeout(() => {
        setUpdateLoading(false)
      }, 500)
    }
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className="curator">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-3 mb-4">
        <div className="flex items-center gap-2">
          <Button
            small
            icon="sync"
            variant="light"
            loading={updateLoading}
            onClick={fetchPlaylistData}
          >
            {intl.formatMessage({ id: 'messages.update' })}
          </Button>

          <span className="text-sm opacity-70">
            {intl.formatMessage({ id: 'messages.last_update' })}{': '}
            {updatedAt}
          </span>
        </div>

        <div className="flex flex-col md:flex-row gap-2">
          <CuratorPromote triggerVariant="primary-light" />

          <Button
            variant="primary"
            icon="plus"
            label={<FormattedMessage id="playlist.create_new" />}
            onClick={showPlaylistCreatePopup}
          />
        </div>
      </div>

      <PlaylistsTable
        key={playlistTableKey}
        userId={profile.id}
        isUserVerified={profile.verified}
      />

      {isPlaylistCreatePopupShown && (
        <PlaylistCreatePopup
          onClose={hidePlaylistCreatePopup}
          userId={profile.id}
        />
      )}
    </div>
  )
}

export default CuratorPlaylists
