import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { ROUTES } from '@fnd/screens/constants'
import Avatar from '@fnd/components/Avatar'
import Badge from '@fnd/components/Badge'
import Icon from '@fnd/components/Icon'
import { FormattedMessage } from 'react-intl'
import { Placeholder } from '@fnd/components/Placeholder'

const CuratorBanner = ({
  back,
  curator,
  children,
  titleTag,
  className,
  featured,
  ...props
}) => {
  const classes = classNames({
    card: true,
    'curator-banner': true,
    'curator-banner-featured': featured,
    [className]: className,
  })

  const Heading = titleTag

  return (
    <Link to={`${ROUTES.CURATORS}/${curator.id}`} className={classes} {...props}>
      <div className="flex items-center gap-3">
        {back && (
          <Icon name="arrow-left" className="text-xl" />
        )}

        {curator?.images[1] ? (
          <Avatar src={curator.images[1].url} />
        ) : (
          <Placeholder
            variant="avatar"
            icon="user-music"
            className="w-[150px] h-[150px] mx-auto mb-4"
          />
        )}
      </div>

      <div>
        <Heading>
          {curator?.display_name ? curator.display_name : curator?.id}
        </Heading>
        <Badge
          icon="users"
          label={<FormattedMessage id='match.table.followers' />}
          value={curator?.followers?.total || `0`}
          variant="light"
        />
        {children}
      </div>
    </Link>
  )
}

export default CuratorBanner

CuratorBanner.defaultProps = {
  titleTag: 'h3',
}
