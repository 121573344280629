import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import 'dayjs/locale/es'
import 'dayjs/locale/it'

dayjs.extend(duration)

export const isServer = typeof window === 'undefined'
export const isBrowser = typeof window !== "undefined" && window.document

export const isFunction = (x) => {
  return Object.prototype.toString.call(x) == '[object Function]'
}

export const hexToRGB = (hex) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  const hexColor = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor)
  return result
    ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16),
    ]
    : null
}

export const hexToHSL = (H, offset = 0) => {
  let r = 0
  let g = 0
  let b = 0
  if (H.length === 4) {
    r = `0x${H[1]}${H[1]}`
    g = `0x${H[2]}${H[2]}`
    b = `0x${H[3]}${H[3]}`
  } else if (H.length === 7) {
    r = `0x${H[1]}${H[2]}`
    g = `0x${H[3]}${H[4]}`
    b = `0x${H[5]}${H[6]}`
  }
  // Then to HSL
  r /= 255
  g /= 255
  b /= 255
  const cmin = Math.min(r, g, b)
  const cmax = Math.max(r, g, b)
  const delta = cmax - cmin
  let h = 0
  let s = 0
  let l = 0

  if (delta === 0) {
    h = 0
  } else if (cmax === r) {
    h = ((g - b) / delta) % 6
  } else if (cmax === g) {
    h = (b - r) / delta + 2
  } else {
    h = (r - g) / delta + 4
  }

  h = Math.round(h * 60)

  if (h < 0) {
    h += 360
  }

  l = (cmax + cmin) / 2
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
  s = +(s * 100).toFixed(1)
  l = +(l * 100).toFixed(1)

  if (offset !== 0) {
    l += offset
  }

  return `${h},${s}%,${l}%`
}

export const splitArray = (array, parts) => {
  const result = []
  const arrayLength = array.length
  const chunkSize = Math.ceil(arrayLength / parts)

  for (let i = 0; i < arrayLength; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize))
  }

  return result
}

export const getRandomInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const getRandomUniqueNumbers = (min, max, count) => {
  if (max - min + 1 < count) {
    throw new Error('Cannot generate unique numbers with given constraints.')
  }

  const numbers = []
  while (numbers.length < count) {
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min
    if (!numbers.includes(randomNum)) {
      numbers.push(randomNum)
    }
  }

  return Array.from(numbers)
}

export const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
      ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

export const chunk = (arr, len) => {
  const chunks = []
  let i = 0
  const n = arr.length

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)))
  }
  return chunks
}

export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' }
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  const item = lookup
    .slice()
    .reverse()
    .find((n) => {
      return num >= n.value
    })
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0'
}

export const truncate = (str, num = 150) => {
  if (str.length > num) {
    return str.slice(0, num) + '...'
  } else {
    return str
  }
}

export const chunkArray = (array, chunkSize) => {
  const result = []
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize))
  }
  return result
}

export const readMore = (str, max = 30) => {
  const array = str.trim().split(' ')
  const ellipsis = array.length > max ? '...' : ''

  return array.slice(0, max).join(' ') + ellipsis
}

export const humanReadableDuration = (hours, locale) => {
  if (!hours) return
  return dayjs.duration(hours, 'hours').locale(locale).humanize()
}

export const loadScript = (
  src,
  id,
  async = true,
  head = true
) => {
  if (!isBrowser) return
  const script = document.createElement('script')
  script.src = src
  script.id = id
  script.async = async

  if (head) {
    document.head.appendChild(script)
  } else {
    document.body.appendChild(script)
  }
}

export const loadGoogleMapScript = (
  googleMapsScriptBaseUrl,
  googleMapsScriptUrl
) => {
  if (!isBrowser) return Promise.resolve()

  if (typeof google !== "undefined") {
    if (window.google.maps && window.google.maps.api) return Promise.resolve()
  }

  const scriptElements = document.querySelectorAll(
    `script[src*="${googleMapsScriptBaseUrl}"]`
  )

  if (scriptElements && scriptElements.length) {
    return new Promise((resolve) => {
      if (typeof google !== "undefined") return resolve()
      scriptElements[0].addEventListener("load", () => resolve())
    })
  }

  let scriptUrl = new URL(googleMapsScriptUrl)
  scriptUrl.searchParams.set(
    "callback",
    "__REACT_GOOGLE_AUTOCOMPLETE_CALLBACK__"
  )
  const el = document.createElement("script")
  el.src = scriptUrl.toString()

  return new Promise((resolve) => {
    window.__REACT_GOOGLE_AUTOCOMPLETE_CALLBACK__ = resolve
    document.body.appendChild(el)
  })
}

export const stripHtml = (html) => {
  return html.replace(/<[^>]*>?/gm, '')
}

export const getSpotifyMinBudget = (artistPopularity) => {
  if (artistPopularity < 3) {
    return 500
  } else if (artistPopularity < 6) {
    return 350
  } else {
    return 250
  }
}

export const addSpacesToCamelCase = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1 $2')
    .trim()
}

export const getDateFormat = (locale, includeTime = false, format) => {
  if (format) {
    return format
  }

  if (locale === 'en') {
    if (includeTime) return 'MM/DD/YYYY HH:mm'
    return 'MM/DD/YYYY'
  }

  if (locale === 'es') {
    if (includeTime) return 'DD/MM/YYYY HH:mm'
    return 'DD/MM/YYYY'
  }

  if (locale === 'it') {
    if (includeTime) return 'DD/MM/YYYY HH:mm'
    return 'DD/MM/YYYY'
  }

  return 'YYYY-MM-DD'
}
