import { useEffect, useState } from 'react'

import { EMPTY } from 'rxjs'
import { catchError, finalize } from 'rxjs/operators'

import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { followPlaylist } from '@fnd/core/spotify/endpoints'

import useCheckQueueLimits from './useCheckQueueLimits'

export default function usePushToPlaylist({ trackId, playlistId }) {
  const queueLimitsState = useCheckQueueLimits({
    trackId,
    playlistId,
  })
  const [pushToPlaylistState, setState] = useState(queueLimitsState)

  useEffect(() => {
    setState((state) => ({
      ...state,
      isLoading: queueLimitsState.isLoading,
      isTrackInQueue: queueLimitsState.isTrackInQueue,
    }))
  }, [queueLimitsState])

  const pushToPlaylistQueueHandler = (playlistScore, track) => {
    setState((state) => ({ ...state, isLoading: true }))

    followPlaylist(playlistId)

    spotimatchEndpoints
      .putTrackIntoQueue({ track, trackId, playlistId, playlistScore })
      .pipe(
        catchError((error) => {
          const errorMessage = error?.response?.data?.message ?? error.message
          setState((state) => ({ ...state, error: errorMessage, success: false }))

          return EMPTY
        }),

        finalize(() => setState((state) => ({ ...state, isLoading: false })))
      )
      .subscribe((response) =>
        setState((state) => ({
          ...state,
          error: void 0,
          success: true,
          isTrackInQueue: response.is_track_in_queue,
        }))
      )
  }

  return [pushToPlaylistState, pushToPlaylistQueueHandler]
}
