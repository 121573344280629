import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const usePushToPlaylistStore = create(
  persist(
    (set) => ({
      pushedCount: 0,
      pushedLimit: 50,
      pushedPlaylists: [],
      increment: () => set((state) => ({ pushedCount: state.pushedCount + 1 })),
      incrementLimit: () => set((state) => ({ pushedLimit: state.pushedLimit * 2 })),
      setPushedPlaylists: (playlists) => set({ pushedPlaylists: playlists }),
    }),
    {
      name: 'pushtoplaylist-storage'
    }
  )
)
