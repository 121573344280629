import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  CREDIT_DISCOUNT_PERCENTAGE,
  PLANS,
  PLAN_CONFIG,
  STRIPE_PLAN_SUBSCRIPTIONS,
} from '@fnd/constants'
import { ROUTES } from '@fnd/screens/constants'
import { userProfileSelector } from '../selectors'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import Button from '@fnd/components/Button'
import Badge from '@fnd/components/Badge'
import Info from '@fnd/components/Info'
import Progress from '@fnd/components/Progress'
import ConfirmationPopup from '@fnd/components/ConfirmationPopup'
import { toastFeedback } from '@fnd/core/libs/toast'
import classNames from 'classnames'
import PaymentCalculator from '@fnd/components/Payment/PaymentCalculator'
import { formatCurrency } from '@fnd/core/libs/currency'
import { useUserContext, userPlanSelector } from '@fnd/modules/User'
import Payment from '@fnd/components/Payment'
import { PaymentItem } from '@fnd/components/Plans'

const reformatDate = (date) => {
  const formatDate = new Date(date)
  const day = formatDate.getDate()
  const month = formatDate.getMonth() + 1
  const year = formatDate.getFullYear()
  const addZero = (num) => (num < 10 ? '0' : '') + num

  return `${addZero(day)}/${addZero(month)}/${year}`
}

function AccountSubscription() {
  const intl = useIntl()
  const { locale } = intl
  const [userSubscription, setUserSubscription] = useState(false)
  const [hasSubscription, setHasSubscription] = useState(false)
  const [applyCreditsPopup, setApplyCreditsPopup] = useState(false)
  const [autoRenewal, setAutoRenewal] = useState(false)
  const [renewalPrice, setRenewalPrice] = useState(0)
  const [planPrice, setPlanPrice] = useState(0)
  const [autoRenewalPopup, setAutoRenewalPopup] = useState(false)
  const [discountedPrice, setDiscountedPrice] = useState()
  const [creditsScoreUsed, setCreditsScoreUsed] = useState(0)
  const [applyCredits, setApplyCredits] = useState(false)

  const { plan, profile, updateUser } = useUserContext(
    ({ user, updateUserProfile }) => ({
      plan: userPlanSelector(user),
      profile: userProfileSelector(user),
      updateUser: (next) => updateUserProfile(next),
    })
  )

  const getMonthlyTrackPushLabel = (plan) => {
    if (plan?.name !== PLANS.FREE) return '∞'
    return `${plan.monthlyTrackPushCount ?? 0}/${plan.monthlyTrackPushLimit}`
  }

  const calculateMonthlyTrackPushLimit = (plan) => {
    if (plan?.name !== PLANS.FREE) return 100
    return (plan?.monthlyTrackPushCount / plan?.monthlyTrackPushLimit) * 100
  }

  const fetchUserSubscription = async () => {
    try {
      const userSubscription = await spotimatchEndpoints
        .getUserSubscription()
        .toPromise()

      setUserSubscription(userSubscription)
      setHasSubscription(
        userSubscription &&
          Object.keys(userSubscription).length > 0 &&
          plan.name !== PLANS.FREE
      )
      setAutoRenewal(!userSubscription?.subscription?.cancel_at_period_end)
      setRenewalPrice(userSubscription?.invoice?.amount_due / 100)
      setPlanPrice(userSubscription?.subscription?.plan?.amount / 100)

      if (
        userSubscription?.subscription?.plan?.amount >
        userSubscription?.invoice?.amount_due
      ) {
        setDiscountedPrice(userSubscription?.invoice?.amount_due / 100)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchUserSubscription()
  }, [profile])

  const disableAutoRenewal = async () => {
    try {
      await spotimatchEndpoints.disableSubscriptionAutoRenewal().toPromise()
      toastFeedback(
        'success',
        intl.formatMessage({ id: 'feedback.success.auto_renewal.disable' })
      )
      setAutoRenewalPopup(false)
      setAutoRenewal(false)
    } catch (error) {
      console.log(error)
      toastFeedback(
        'error',
        intl.formatMessage({ id: 'feedback.error.default' })
      )
    }
  }

  const enableAutoRenewal = async () => {
    try {
      await spotimatchEndpoints.enableSubscriptionAutoRenewal().toPromise()
      toastFeedback(
        'success',
        intl.formatMessage({ id: 'feedback.success.auto_renewal.enable' })
      )
      setAutoRenewalPopup(false)
      setAutoRenewal(true)
    } catch (error) {
      console.log(error)
      toastFeedback(
        'error',
        intl.formatMessage({ id: 'feedback.error.default' })
      )
    }
  }

  const handleSubscriptionRenewalUpdate = async () => {
    if (autoRenewal) {
      await disableAutoRenewal()
    } else {
      await enableAutoRenewal()
    }
  }

  const handleSubscriptionCredits = async () => {
    setApplyCreditsPopup(false)
    const userPlan = STRIPE_PLAN_SUBSCRIPTIONS[plan.name]

    try {
      await spotimatchEndpoints
        .updateSubscription({
          plan: userPlan,
          creditsScoreUsed,
          amount: discountedPrice * 100,
        })
        .toPromise()
      toastFeedback(
        'success',
        intl.formatMessage({ id: 'feedback.success.subscription_credits' })
      )
      setApplyCredits(true)

      const { profile: updatedProfile } = await spotimatchEndpoints
        .getMe()
        .toPromise()

      updateUser({
        plan: { $set: updatedProfile.plan },
        vip_plans: { $set: updatedProfile.vip_plans },
        spotify_artist_id: { $set: updatedProfile.spotify_artist_id },
        referral: { $set: updatedProfile.referral },
        contacts: { $set: updatedProfile.contacts },
        spotify_username: { $set: updatedProfile.spotify_username },
        featured: { $set: updatedProfile.featured },
        verified: { $set: updatedProfile.verified },
        notifications: { $set: updatedProfile.notifications },
        billingInfo: { $set: updatedProfile.billingInfo },
      })
    } catch (error) {
      console.log(error)
      const errorMessage =
        `feedback.error.${error?.response?.data?.message}` ||
        'feedback.error.default'
      toastFeedback('error', intl.formatMessage({ id: errorMessage }))
    }
  }

  const handleUseCredits = async (price, creditsToUse) => {
    if (price < planPrice) setDiscountedPrice(price)
    setCreditsScoreUsed(creditsToUse)
  }

  const planClasses = classNames({
    'account-plan': true,
    [`plan-${plan.name}`]: true,
  })

  return (
    <div className="mb-6">
      <div className={planClasses}>
        <h3 className="mb-3 capitalize">{plan.name}</h3>

        {hasSubscription && (
          <div className="flex flex-wrap gap-8">
            <Info
              vertical
              label={<FormattedMessage id="account_plan.created_at" />}
              value={reformatDate(plan.created_at)}
            />
            <Info
              vertical
              label={<FormattedMessage id="account_plan.expired_at" />}
              value={reformatDate(plan.expired_at)}
            />
            {autoRenewal && (
              <Info
                vertical
                label={<FormattedMessage id="account_plan.renewal_fee" />}
              >
                {discountedPrice > 0 ? (
                  <>
                    {planPrice > discountedPrice &&
                      planPrice !== renewalPrice && (
                      <span className="mr-2 font-medium line-through opacity-50">
                        {formatCurrency(planPrice, locale)}
                      </span>
                    )}
                    <span className="font-bold text-green">
                      {formatCurrency(renewalPrice, locale)}
                    </span>
                  </>
                ) : (
                  <span className="font-bold text-green">
                    {formatCurrency(planPrice, locale)}
                  </span>
                )}
              </Info>
            )}
            {plan.status === 'past_due' ? (
              <Info
                vertical
                label={<FormattedMessage id="account_plan.status" />}
              >
                <Badge
                  small
                  variant="orange-light"
                  label={<FormattedMessage id="account_plan.status.past_due" />}
                />
              </Info>
            ) : (
              <Info
                vertical
                label={<FormattedMessage id="account_plan.auto_renewal" />}
              >
                {autoRenewal ? (
                  <Badge
                    small
                    variant="green-light"
                    label={
                      <FormattedMessage id="account_plan.auto_renewal.enabled" />
                    }
                    icon="check"
                  />
                ) : (
                  <Badge
                    small
                    variant="light"
                    label={
                      <FormattedMessage id="account_plan.auto_renewal.disabled" />
                    }
                    icon="times"
                  />
                )}
              </Info>
            )}
          </div>
        )}

        <div className="mt-4">
          <Progress
            title={<FormattedMessage id="account_plan.monthly_push_limit" />}
            icon="arrow-to-top"
            progress={calculateMonthlyTrackPushLimit(plan)}
            color="primary"
            customText={getMonthlyTrackPushLabel(plan)}
          />
        </div>

        <div className="flex flex-wrap gap-3 mt-4">
          {!applyCredits && hasSubscription && plan.status !== 'past_due' && profile?.referral?.score > 0 && (
            <Button
              icon="euro-sign"
              className="w-full md:w-auto"
              variant="primary-light"
              onClick={() => setApplyCreditsPopup(true)}
              label={<FormattedMessage id="account_plan.apply_credits" />}
            />
          )}

          {hasSubscription && plan.status !== 'past_due' && (
            <Button
              icon="clock"
              className="w-full md:w-auto"
              variant={autoRenewal ? 'inverse-light' : 'primary-light'}
              onClick={() => setAutoRenewalPopup(true)}
              label={
                autoRenewal ? (
                  <FormattedMessage id="account_plan.disable_auto_renewal" />
                ) : (
                  <FormattedMessage id="account_plan.enable_auto_renewal" />
                )
              }
            />
          )}

          {hasSubscription && plan.status === 'past_due' && (
            <Payment
              mode="subscription"
              plan={plan.name}
              title={PLAN_CONFIG[plan.name].title}
              price={renewalPrice}
              disableAffiliate={true}
              disableCoupon={true}
              disableCredits={discountedPrice > 0 ? true : false}
              maxCredits={discountedPrice > 0 ? 0 : undefined}
              autoApply={false}
              monthly={true}
              label={<FormattedMessage id="account_plan.renew" />}
            >
              <PaymentItem
                title={PLAN_CONFIG[plan.name].title}
                subtitle={<FormattedMessage id="navigation.account_plan" />}
                icon={PLAN_CONFIG[plan.name].icon}
              />
            </Payment>
          )}

          <ConfirmationPopup
            bodyClasses="p-5"
            isOpen={applyCreditsPopup}
            confirmText={<FormattedMessage id="messages.apply" />}
            title={<FormattedMessage id="account_plan.apply_credits.title" />}
            onConfirm={() => handleSubscriptionCredits()}
            onReject={() => setApplyCreditsPopup(false)}
            shouldCloseOnOverlayClick={false}
          >
            <p className="mb-5">
              {intl.formatMessage({
                id: 'account_plan.apply_credits.description',
              })}
            </p>

            <PaymentCalculator
              autoApply={true}
              disabled={true}
              disableCredits={false}
              disableCoupon={true}
              creditsDiscount={CREDIT_DISCOUNT_PERCENTAGE}
              price={planPrice}
              onChange={(price, creditsToUse) =>
                handleUseCredits(price, creditsToUse)
              }
            />
          </ConfirmationPopup>

          {plan.status !== 'past_due' && (
            <ConfirmationPopup
              bodyClasses="p-5"
              isOpen={autoRenewalPopup}
              onReject={() => setAutoRenewalPopup(false)}
              onConfirm={() => handleSubscriptionRenewalUpdate()}
              title={
                autoRenewal
                  ? intl.formatMessage({
                    id: 'account_plan.disable_auto_renewal',
                  })
                  : intl.formatMessage({
                    id: 'account_plan.enable_auto_renewal',
                  })
              }
              text={
                autoRenewal
                  ? intl.formatMessage({
                    id: 'account_plan.disable_auto_renewal_confirmation',
                  })
                  : intl.formatMessage({
                    id: 'account_plan.enable_auto_renewal_confirmation',
                  })
              }
            />
          )}

          {plan?.name !== PLANS.AGENCY && (
            <Button
              className="w-full md:w-auto"
              to={ROUTES.PRICING}
              variant="primary"
            >
              <FormattedMessage id="profile.account_plan.upgrade" />
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default AccountSubscription
