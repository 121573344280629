import { make } from './endpoints'
import { PER_PAGE } from '@fnd/constants'


export const getPushedTracks = (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)
  const { campaignId, vip } = params

  const query = {
    limit,
    offset,
  }

  if (campaignId) {
    query.campaignId = campaignId
  }

  if (vip) {
    query.vip = vip
  }

  return make.get(`/tracks-queue/pushed/`, { query }).toPromise()
}

export const getPushedPlaylists = (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)
  const { trackId } = params

  return make.get(`/tracks-queue/pushed/${trackId}`, {
    query: { limit, offset }
  }).toPromise()
}

export default {
  getPushedTracks,
  getPushedPlaylists
}
