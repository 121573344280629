import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '@fnd/components/Button'
import Info from '@fnd/components/Info'
import { APP_URL, AFFILIATE_MAX_USAGE } from '@fnd/constants'
import { ROUTES } from '@fnd/screens/constants'
import Tooltip from 'rc-tooltip'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import Alert from '@fnd/components/Alert'
import Badge from '@fnd/components/Badge'
import CopyToClipboard from '@fnd/components/CopyToClipboard'
import Icon from '@fnd/components/Icon'
import Spinner from '@fnd/components/Spinner'
import { SectionHeader } from '@fnd/components/Section'
import Share from '@fnd/components/Share/Share'
import CuratorPromote from '@fnd/modules/Curator/CuratorPromote'
import MagazineRequest from '@fnd/modules/Magazine/MagazineRequest'
import TikTokRequest from '@fnd/modules/TikTok/TikTokRequest'
import { Link } from 'react-router-dom'
import ProfileCompletion from './ProfileCompletion'

export default function ProfileOverviewView({ userProfile }) {
  const intl = useIntl()
  const { contacts, verified } = userProfile
  const [resendState, setResendState] = useState({
    value: void 0,
    isLoading: false,
    error: false,
  })

  const {
    isLoading: isLoadingResend,
    value: resendValue,
    error: resendError,
  } = resendState

  const handleLoading = (isLoading) =>
    setResendState((prevState) => ({ ...prevState, isLoading }))
  const handleError = (hasError) =>
    setResendState((prevState) => ({ ...prevState, error: hasError }))
  const handleValue = (value) =>
    setResendState((prevState) => ({ ...prevState, value }))

  const handleResend = () => {
    if (isLoadingResend) {
      return
    }
    handleLoading(true)

    spotimatchEndpoints
      .sendVerifyEmail()
      .toPromise()
      .then(({ expires_at: expiresAt }) => {
        handleValue(`Done!`)
      })
      .catch((err) => {
        handleError(true)

        if (err.response.status === 401) {
          const userVerifiedMessage = intl.formatMessage({
            id: 'confirm_email.user_verified',
          })
          handleValue(userVerifiedMessage)
        } else if (err.response.status === 400) {
          const emailNotVerifiedMessage = intl.formatMessage({
            id: 'confirm_email.already_sent',
          })
          handleValue(emailNotVerifiedMessage)
        }
      })
      .then(() => handleLoading(false))
  }

  const getVerificationState = () => {
    if (!contacts.email) {
      return null
    }

    if (verified) {
      return (
        <Tooltip
          mouseEnterDelay={0.5}
          placement="right"
          trigger={['hover']}
          overlay={
            <span>
              <FormattedMessage id="confirm_email.verified" />
            </span>
          }
        >
          <Icon name="check" className="icon-verified ml-2" />
        </Tooltip>
      )
    }

    if (resendValue) {
      return (
        <Alert variant={resendError ? 'warning' : 'success'}>
          {resendValue}
        </Alert>
      )
    }

    return (
      <Button
        variant="primary"
        onClick={handleResend}
        className="overview-resend-button"
      >
        {isLoadingResend ? (
          <Spinner inline />
        ) : (
          <FormattedMessage id="confirm_email.resend" />
        )}
      </Button>
    )
  }

  const curatorUrl = `${window.location.origin}${ROUTES.CURATORS}/${userProfile.spotify_id}`

  const ProfileRoles = ({ roles }) => {
    if (!roles?.length) return '-'

    return (
      <div className="flex flex-wrap gap-2">
        {roles.map((role) => (
          <Badge
            key={role}
            variant="primary-light"
            label={intl.formatMessage({ id: `role.${role}` })}
          />
        ))}
      </div>
    )
  }

  const ProfileGenres = ({ genres }) => {
    if (!genres?.length) return '-'

    return (
      <div className="flex flex-wrap gap-2">
        {genres.map((genre) => (
          <Badge key={genre} variant="secondary-light" label={genre} />
        ))}
      </div>
    )
  }

  return (
    <>
      <SectionHeader
        sticky
        title={
          userProfile.display_name ?? (
            <FormattedMessage id="navigation.overview" />
          )
        }
        icon="user-circle"
        actions={
          <Button icon="edit" to={`../${ROUTES.MY_PROFILE.OVERVIEW_EDIT}`}>
            <FormattedMessage id="profile.overview.edit" />
          </Button>
        }
      />

      <div className="p-5">
        <ProfileCompletion />

        <Info label={<FormattedMessage id="profile.show_public_profile" />}>
          <div className="flex flex-col items-start lg:flex-row lg:items-center justify-between w-full">
            <Link to={curatorUrl} className="block mb-2 lg:inline lg:mb-0">
              {userProfile.display_name ?? userProfile.spotify_id}
              <Icon name="external-link-alt" className="ml-2" />
            </Link>
            <div className="curator-actions lg:ml-2">
              <Share
                small
                title={<FormattedMessage id="messages.share" />}
                url={curatorUrl}
              />
              <CuratorPromote small />
            </div>
          </div>
        </Info>

        <Info label="Spotify ID" value={userProfile.spotify_id} />
        <Info label="Email" className="info-email">
          <span className="flex items-center gap-2">
            {contacts.email}
            {getVerificationState()}
          </span>
        </Info>
        <Info label="Twitter" value={contacts.twitter} />
        <Info label="Instagram" value={contacts.instagram} />
        <Info label="Facebook" value={contacts.facebook} />
        <Info label="LinkedIn" value={contacts.linkedin} />

        <Info
          label="TikTok"
          tooltipText={intl.formatMessage({
            id: 'profile.overview.tiktok_tooltip',
          })}
        >
          <div className="flex items-center gap-3">
            {contacts.tiktok}
            {contacts.tiktok && <TikTokRequest small variant="primary-light" />}
          </div>
        </Info>

        <Info label="YouTube" value={contacts.youtube} />

        <Info
          label="Website"
          tooltipText={intl.formatMessage({
            id: 'profile.overview.website_tooltip',
          })}
        >
          <div className="flex items-center gap-3">
            {contacts.website}
            {contacts.website && (
              <MagazineRequest small variant="primary-light" magazineURL={contacts.website} />
            )}
          </div>
        </Info>

        <Info
          label="Bio"
          valueClasses="text-sm"
          value={contacts?.bio || intl.formatMessage({ id: 'profile.no_bio' })}
        />

        <Info
          label={<FormattedMessage id="messages.roles" />}
          value={<ProfileRoles roles={contacts?.roles} />}
        />

        <Info
          label={<FormattedMessage id="profile.genres" />}
          value={<ProfileGenres genres={userProfile.tags} />}
          tooltipText={intl.formatMessage({
            id: 'profile.overview.genres_tooltip',
          })}
        />

        {userProfile?.plan?.affiliateCount < AFFILIATE_MAX_USAGE && (
          <Info
            label={
              <FormattedMessage id="profile.overview.inviter_referral_link" />
            }
            tooltipText={intl.formatMessage({
              id: 'profile.overview.inviter_referral_link_tooltip',
            })}
          >
            <div className="flex items-center">
              <Badge
                variant="primary-light"
                label={userProfile.spotify_id}
                value={`${userProfile?.plan?.affiliateCount} / ${AFFILIATE_MAX_USAGE}`}
                className="items-center"
              />
              <CopyToClipboard
                className="ml-2"
                label={<FormattedMessage id="messages.copy" />}
                text={`${APP_URL}?code=${userProfile.spotify_id}`}
              />
            </div>
          </Info>
        )}
      </div>
    </>
  )
}
