import React from "react"
import Button from "@fnd/components/Button"
import { QUERIES } from "@fnd/constants"
import { useQueueStore } from "@fnd/store"
import { useQueryClient } from "@tanstack/react-query"
import { useIntl } from "react-intl"

export const ArchiveSubmission = ({
  playlistId,
  track
}) => {
  const intl = useIntl()

  const { archiveTrackFromQueue } = useQueueStore()
  const queryClient = useQueryClient()
  const queryKey = `${QUERIES.PLAYLIST.QUEUE}.${playlistId}`

  const handleArchiveTrack = () => {
    archiveTrackFromQueue(playlistId, track.id)

    queryClient.setQueryData([queryKey], oldData => {
      if (!oldData) return oldData

      const newPages = oldData.pages.map(page => {
        const newQueue = page.data.filter(submission => submission.track.id !== track.id)
        return {
          ...page,
          data: newQueue
        }
      })

      return {
        ...oldData,
        pages: newPages
      }
    })
  }

  return (
    <Button
      small
      variant="ghost"
      icon="times"
      tooltip={intl.formatMessage({
        id: 'playlist_queue.action.archive',
      })}
      onClick={handleArchiveTrack}
    />
  )
}

export default ArchiveSubmission
