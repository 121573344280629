import React from 'react'
import { FormattedMessage } from 'react-intl'
import Empty from '@fnd/components/Empty'
import Spinner from '@fnd/components/Spinner'
import PlaylistQueueRow from './PlaylistQueueRow'

function PlaylistQueueTable({
  playlistId,
  queue,
  isLoading,
  isRefetching,
}) {
  return (
    <div className="table-responsive">
      <table className="table table-fixed md:table-auto">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="profile.my_playlists.settings_popup.submissions.queue_table.track" />
            </th>
            <th className="hidden md:table-cell">
              <FormattedMessage id="profile.my_playlists.settings_popup.submissions.queue_table.submitted_by" />
            </th>
            <th className="hidden md:table-cell text-center">
              <FormattedMessage id="messages.compatibility" />
            </th>
            <th className="hidden md:table-cell text-center">
              <FormattedMessage id="profile.my_playlists.settings_popup.submissions.queue_table.handled_by" />
            </th>
            <th className="hidden md:table-cell text-center">
              <FormattedMessage id="profile.my_playlists.settings_popup.submissions.queue_table.status" />
            </th>
            {queue && !queue.some(({ archived }) => archived) && (
              <th className="text-right">
                <FormattedMessage id="messages.actions" />
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {queue &&
            queue.length > 0 &&
            !isRefetching &&
            queue.map(submission => (
              <PlaylistQueueRow
                key={submission.track.id}
                playlistId={playlistId}
                {...submission}
              />
            ))}
        </tbody>
      </table>

      {isRefetching && (
        <Spinner />
      )}

      {queue && queue.length === 0 && !isLoading && (
        <Empty className="my-0" />
      )}
    </div>
  )
}

export default PlaylistQueueTable
