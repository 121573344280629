import React from 'react'
import TrackPlayer from '@fnd/components/Track'
import classNames from 'classnames'

export const TrackItem = ({
  name,
  link,
  artist,
  image,
  previewUrl,
  disablePlayer,
  className
}) => {
  const trackClasses = classNames({
    'track-item': true,
    [className]: className,
  })

  return (
    <div className={trackClasses}>
      <TrackPlayer
        cover={image}
        audioSrc={previewUrl}
        disablePlayer={disablePlayer}
      />

      <div className="track-info">
        <h4>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {name}
          </a>
        </h4>
        <p>
          <a href={artist.external_urls.spotify} target="_blank" rel="noopener noreferrer">
            {artist.name}
          </a>
        </p>
      </div>
    </div>
  )
}

export const trackImg = (track) => {
  if (track?.album?.images && Array.isArray(track.album.images)) {
    const image = track.album.images.find(img => img && img.height === 64)
    return image || track.album.images[0] || null
  }

  return null
}

export default TrackItem
