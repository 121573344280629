import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useReceiveArtists } from '@fnd/modules/Artist'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import ArtistsTable from '@fnd/modules/Artist/ArtistsTable'
import ArtistForm from '@fnd/modules/Artist/ArtistForm'
import { userProfileSelector, useUserContext } from '@fnd/modules/User'
import Alert from '@fnd/components/Alert'
import Button from '@fnd/components/Button'
import Spinner from '@fnd/components/Spinner'
import { PLANS } from '@fnd/constants'
import { ROUTES } from '@fnd/screens/constants'
import ProfileWrapper from '@fnd/modules/Profile/ProfileWrapper'
import { toastFeedback } from '@fnd/core/libs/toast'

export default function MyArtists() {
  const { profile, updateUserArtists } = useUserContext(
    ({ user, updateUserProfile }) => ({
      profile: userProfileSelector(user),
      updateUserArtists: (next) =>
        updateUserProfile({ spotify_artist_id: { $set: next } }),
    })
  )

  const intl = useIntl()
  const [artistState] = useReceiveArtists(profile.spotify_artist_id)
  const isAbleToDeleteArtists = profile?.plan?.canChangeArtist

  const deleteArtistHandler = (artistId) =>
    spotimatchEndpoints
      .removeArtist(artistId)
      .toPromise()
      .then(({ user }) => {
        updateUserArtists(user.spotify_artist_id)
        toastFeedback('success', intl.formatMessage({ id: 'feedback.success.artist_remove' }))
      })
      .catch(({ response }) => {
        const error = intl.formatMessage({
          id: response?.data?.message || 'feedback.error.default',
        })
        toastFeedback('error', error)
      })

  return (
    <ProfileWrapper
      icon="headphones"
      title={<FormattedMessage id="navigation.my_artists" />}
    >
      {profile?.plan?.name !== PLANS.AGENCY && (
        <Alert
          inline
          variant="primary"
          className="mb-4"
          cta={
            <Button
              small
              to={`${ROUTES.PRICING}?plan=${PLANS.AGENCY}`}
              variant="primary"
            >
              {intl.formatMessage({ id: 'banner.free.cta_upgrade' })}
            </Button>
          }
        >
          <p className="pr-4 text-sm">
            <FormattedMessage id="profile.my_artists.alert.text" />
          </p>
        </Alert>
      )}

      {artistState.isLoading ? (
        <Spinner />
      ) : (
        <>
          <ArtistsTable
            artists={artistState.artists}
            deleteArtistHandler={deleteArtistHandler}
            isAbleToDeleteArtists={isAbleToDeleteArtists}
          />

          <ArtistForm
            className="mt-4"
            handleForm={updateUserArtists}
          />
        </>
      )}
    </ProfileWrapper>
  )
}
