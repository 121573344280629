import React, { Fragment, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { isEmpty } from 'lodash-es'
import Tooltip from 'rc-tooltip'
import { ROUTES } from '@fnd/screens/constants'
import Avatar from '@fnd/components/Avatar'
import Button from '@fnd/components/Button'
import ConfirmationPopup from '@fnd/components/ConfirmationPopup'
import Empty from '@fnd/components/Empty'
import Icon from '@fnd/components/Icon'
import { formatNumber } from '@fnd/core/libs/currency'
import { useConsent } from '@fnd/context/ConsentProvider'
import { nanoid } from 'nanoid'

function ArtistsTable({
  artists,
  deleteArtistHandler,
  isAbleToDeleteArtists
}) {
  const [confirmPopupArtistId, setConfirmPopupArtistId] = useState('')
  const { handleIntercomToggle } = useConsent()
  const openConfirmPopup = (artistId) => setConfirmPopupArtistId(artistId)
  const closeConfirmPopup = () => setConfirmPopupArtistId('')

  const intl = useIntl()

  if (isEmpty(artists)) {
    return <Empty message={intl.formatMessage({ id: 'messages.no_artists' })} />
  }

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="profile.my_artists.table.artist" />
            </th>
            <th>
              <Tooltip
                placement="top"
                trigger={['hover']}
                overlay={
                  <span>
                    <FormattedMessage id="profile.my_artists.table.popularity.tooltip" />
                  </span>
                }
              >
                <span className="flex items-center">
                  <FormattedMessage id="profile.my_artists.table.popularity" />
                  <Icon name="info-circle" className="ml-2" />
                </span>
              </Tooltip>
            </th>
            <th>
              <FormattedMessage id="profile.my_artists.table.followers" />
            </th>
            <th>
              <FormattedMessage id="profile.my_artists.table.genres" />
            </th>
            <th className="text-right">
              <FormattedMessage id="messages.actions" />
            </th>
          </tr>
        </thead>
        <tbody>
          {artists.map(artist => (
            <Fragment key={nanoid()}>
              <tr>
                <td>
                  <div className="flex items-center gap-2">
                    {artist.images.length ? (
                      <Avatar
                        src={artist.images[0].url}
                        alt={artist.name}
                        size="small"
                      />
                    ) : (
                      <div className="w-12 h-12 rounded-full bg-gray-200" />
                    )}
                    {artist.name ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://open.spotify.com/artist/${artist.id}`}
                      >
                        {artist.name}
                      </a>
                    ) : (
                      '-'
                    )}
                  </div>
                </td>
                <td>{artist?.popularity ? `${artist.popularity}%` : '-'}</td>
                <td>{artist?.followers?.total ? formatNumber(artist.followers.total) : '-'}</td>
                <td>
                  {!isEmpty(artist.genres) ? (
                    <div className="tags">
                      {artist.genres.map((genre, genreIndex) => (
                        <span key={genreIndex} className="tag tag-sm capitalize">
                          {genre}
                        </span>
                      ))}
                    </div>
                  ) : null}
                </td>
                <td>
                  <div className="table-actions">
                    <Button
                      small
                      icon="file-chart-line"
                      to={ROUTES.ANALYSIS}
                      label={<FormattedMessage id="profile.my_artists.table.request_analysis" />}
                    />

                    {isAbleToDeleteArtists ? (
                      <Button
                        small
                        variant="outline-primary"
                        icon="trash-alt"
                        onClick={() => openConfirmPopup(artist.id)}
                      />
                    ) : (
                      <Button
                        small
                        label={<FormattedMessage id="profile.my_artists.table.request_change" />}
                        variant="outline-primary"
                        icon="sync-alt"
                        onClick={handleIntercomToggle}
                      />
                    )}
                  </div>
                </td>
              </tr>

              <ConfirmationPopup
                title={<FormattedMessage id="profile.my_artist.table.delete_popup.title" />}
                bodyClasses="p-5"
                isOpen={confirmPopupArtistId === artist.id}
                onReject={closeConfirmPopup}
                onConfirm={() => deleteArtistHandler(artist.id)}
                text={
                  <FormattedMessage
                    id="profile.my_artist.table.delete_popup"
                    values={{
                      artistName: artist.name ? (
                        artist.name
                      ) : (
                        <FormattedMessage id="profile.my_artist.table.this_artist" />
                      ),
                    }}
                  />
                }
              />
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ArtistsTable
