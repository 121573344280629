import { create } from 'zustand'
import { spotifyEndpoints } from '@fnd/core/spotify'

export const usePlaylistTracksStore = create(
  (set, get) => ({
    playlists: {},
    getPlaylistTracks: async (playlistId) => {
      const playlists = get().playlists

      if (playlists && playlists[playlistId]) {
        return playlists[playlistId]
      }

      const trackData = await spotifyEndpoints.getPlaylistTracks(playlistId)
      const tracks = trackData.items.map(({ track }) => track)

      set({
        playlists: {
          ...playlists,
          [playlistId]: tracks,
        }
      })

      return tracks
    },
  }),
)
