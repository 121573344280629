import React, { useEffect } from 'react'
import ProfileWrapper from '@fnd/modules/Profile/ProfileWrapper'
import { FormattedMessage } from 'react-intl'
import { Tabs, TabContent, useTabs } from '@fnd/components/Tabs'
import VipCampaignTable from './VipCampaignTable'
import TracksPushedTable from '@fnd/modules/TrackQueue/TracksPushedTable'
import { ROUTES } from '@fnd/screens/constants'
import { useSearchParams } from 'react-router-dom'

export default function VipCampaigns() {
  const [searchParams] = useSearchParams()

  const tabsData = {
    tabs: [
      {
        id: ROUTES.MY_PROFILE.VIP_CAMPAIGN_TAB,
        icon: 'chart-line',
        label: "VIP Campaigns",
        children: (
          <VipCampaignTable className="mt-5" />
        ),
      },
      {
        id: ROUTES.MY_PROFILE.VIP_MANAGER_TAB,
        icon: 'user-tie',
        label: "VIP Manager Promo",
        children: (
          <TracksPushedTable
            vip={true}
            className="mt-5"
          />
        ),
      },
    ],
    initialTabId: ROUTES.MY_PROFILE.VIP_CAMPAIGN_TAB,
  }

  const tabs = useTabs(tabsData)

  useEffect(() => {
    const tabParam = searchParams.get('tab')

    if (tabParam) {
      const tabIndex = tabsData.tabs.findIndex((tab) => tab.id === tabParam)
      tabs.tabProps.setSelectedTab(tabIndex > -1 ? [tabIndex] : [0])
    }
  }, [])

  return (
    <ProfileWrapper
      icon="gem"
      title={<FormattedMessage id="navigation.vip_campaigns" />}
    >
      <Tabs {...tabs.tabProps} />
      <TabContent {...tabs.contentProps} className="p-0">
        {tabs.selectedTab.children}
      </TabContent>
    </ProfileWrapper>
  )
}
