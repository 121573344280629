import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useUserStore = create(
  persist(
    (set) => ({
      starUsers: [],
      setStarUsers: (users) => set({ starUsers: users }),
    }),
    {
      name: 'user-storage'
    }
  )
)
