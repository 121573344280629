import React from 'react'
import { useParams } from 'react-router-dom'
import ProfileWrapper from '@fnd/modules/Profile/ProfileWrapper'
import { PlaylistAnalysis } from '@fnd/modules/PlaylistCheck'
import { useIntl } from 'react-intl'


export default function MyPlaylistAnalysis() {
  const { playlistId } = useParams()
  const intl = useIntl()

  return (
    <ProfileWrapper
      back
      title={intl.formatMessage({ id: 'navigation.report' })}
    >
      <PlaylistAnalysis playlistId={playlistId} />
    </ProfileWrapper>
  )
}
