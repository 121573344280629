import React from 'react'
import Button from '@fnd/components/Button'
import Icon from '@fnd/components/Icon'
import { DEFAULT_ICON_TYPE } from '@fnd/constants'
import classNames from 'classnames'
import Tooltip from 'rc-tooltip'
import { Link } from 'react-router-dom'
import { truncate } from 'lodash-es'


function Badge({
  children,
  className,
  image,
  clearable,
  emoji,
  icon,
  iconType = DEFAULT_ICON_TYPE,
  label,
  large,
  onClick,
  onClear,
  medium,
  small,
  to,
  tooltip,
  value,
  values,
  truncateLength,
  variant = 'primary',
  vertical,
  ...props
}) {
  const handleBadgeClick = () => {
    if (onClick) {
      onClick()
    }
  }

  const BadgeWrapper = ({ children, ...props }) => {
    if (to) {
      return (
        <Link to={to} {...props} onClick={handleBadgeClick}>
          {children}
        </Link>
      )
    } else if (tooltip) {
      return (
        <Tooltip
          mouseEnterDelay={0.5}
          placement="bottom"
          trigger={['hover']}
          overlay={<span>{tooltip}</span>}
        >
          {children}
        </Tooltip>
      )
    }
    return children
  }

  const classes = classNames({
    badge: true,
    [`badge-${variant}`]: variant,
    [`badge-small`]: small,
    [`badge-medium`]: medium,
    [`badge-large`]: large,
    'badge-icon': !label && !children,
    'badge-vertical': vertical,
    'cursor-pointer': onClick,
    [className]: className,
  })

  return (
    <BadgeWrapper {...props}>
      <div className={classes}>
        {(emoji || icon || label) && (
          <div className="badge-label" onClick={handleBadgeClick}>
            {emoji && emoji}

            {image && <img src={image} alt={label} />}

            {icon && <Icon name={icon} type={iconType} />}

            {label && !truncateLength && label}

            {label && truncateLength && (
              <span title={label}>
                {truncate(label, { length: truncateLength })}
              </span>
            )}
          </div>
        )}

        {values && (
          <div className="badge-values flex">
            {values.map(({ value, icon }, index) => (
              <div key={index} className="badge-value flex gap-1">
                {icon && <Icon name={icon} />}
                {value}
              </div>
            ))}
          </div>
        )}

        {value && (
          <span className={classNames({
            'badge-value': emoji || icon || label,
          })}>
            {value}
          </span>
        )}

        {clearable && (
          <Button
            variant="ghost"
            small
            icon="times"
            className="badge-clear"
            onClick={onClear}
          />
        )}

        {children}
      </div>
    </BadgeWrapper>
  )
}

export default Badge
