import React from 'react'
import { stripHtml } from '@fnd/core/libs/helpers'
import Icon from '@fnd/components/Icon'
import dayjs from 'dayjs'
import parse from 'html-react-parser'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/es'
import 'dayjs/locale/it'

dayjs.extend(relativeTime)

import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

export const NotificationDropdownItem = ({ notification }) => {
  const { content, type } = notification
  const intl = useIntl()
  const { locale } = intl

  const notificationHasLink =
    notification.metadata &&
    notification.metadata.cta &&
    notification.metadata.cta.url

  const getIcon = () => {
    switch (type) {
      case 'accelerate':
        return 'rocket'
      case 'analysis':
        return 'chart-bar'
      case 'limit':
        return 'exclamation-triangle'
      case 'submission':
        return 'chart-line'
      case 'submission_accept':
        return 'check'
      case 'plan':
        return 'star'
      case 'push':
        return 'bell'
      case 'vip':
        return 'crown'
      default:
        return 'bell'
    }
  }

  const getTime = () => {
    return dayjs(notification.created_at).locale(locale).fromNow()
  }

  return (
    <div className="notification-item">
      <div className="notification-item-inner">
        <div className="notification-item-icon">
          <Icon name={getIcon()} />
        </div>
        <div className="notification-item-content relative">
          {notificationHasLink ? (
            <Link to={notification.metadata.cta.url} className="flex flex-col items-start gap-1 text-sm">
              {stripHtml(content)}
              <div className="notification-item-time">
                {getTime()}
              </div>
            </Link>
          ) : (
            <div className="p-0">
              {parse(content)}
              <div className="notification-item-time">
                {getTime()}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default NotificationDropdownItem
