import { spotifyEndpoints } from '@fnd/core/spotify'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { create } from 'zustand'

export const usePlaylistStore = create(
  (set, getState) => ({
    error: false,
    isLoading: false,
    playlists: undefined,
    currentPlaylist: undefined,
    currentPlaylistId: undefined,
    success: false,
    loadSpotifyPlaylist: async (playlistId) => {
      const { setPlaylist } = getState()

      const playlistData = await spotifyEndpoints.getPlaylist(playlistId).toPromise()
      setPlaylist(playlistId, {
        ...playlistData,
        image: playlistData?.images?.[0]?.url,
        followers: playlistData?.followers?.total,
      })

      return playlistData
    },
    loadTracks: async (playlistId) => {
      const tracks = await spotifyEndpoints.getPlaylistTracks(playlistId)

      set(state => ({
        playlists: {
          ...state.playlists,
          [playlistId]: {
            ...state[playlistId],
            tracks: tracks.items,
          },
        }
      }))

      return tracks?.items || []
    },
    getTracks: (playlistId) => {
      const playlistTracks = getState().playlists?.[playlistId]?.tracks
      if (!playlistTracks || !playlistTracks?.length) return []
      return playlistTracks.map((item) => item.track)
    },
    setIsLoading: (isLoading) => set({ isLoading }),
    setPlaylist: (playlistId, playlist) => {
      set(state => ({
        playlists: {
          ...state.playlists,
          [playlistId]: {
            ...state[playlistId],
            ...playlist,
          },
        },
        currentPlaylist: playlist,
        currentPlaylistId: playlistId,
      }))
    },
    addTrackToPlaylist: (playlistId, track) => {
      set(state => ({
        playlists: {
          ...state.playlists,
          [playlistId]: {
            ...state[playlistId],
            tracks: [...state[playlistId].tracks, track],
          },
        },
        success: 'feedback.success.track_add'
      }))
    },
    removeTrackFromPlaylist: (playlistId, trackId) => {
      set((state) => ({
        playlists: {
          ...state.playlists,
          [playlistId]: {
            ...state[playlistId],
            tracks: state[playlistId].tracks.filter((t) => t.track.id !== trackId),
          },
        },
        success: 'feedback.success.track_remove'
      }))
    },
    createUserPlaylist: async (userId, data) => {
      try {
        const playlist = await spotifyEndpoints.createPlaylist(userId, data).toPromise()
        await spotimatchEndpoints.createPlaylist(playlist).toPromise()

        set(() => ({ success: 'feedback.success.playlist_create' }))
      } catch (error) {
        console.log(error)
      }
    },
    genresUpdateRequest: async () => {
      try {
        const playlist = getState().currentPlaylist
        const { PlaylistId } = playlist
        await spotimatchEndpoints.genresUpdateRequest(PlaylistId).toPromise()

        set(() => ({ success: 'feedback.success.tags_update' }))
      } catch (error) {
        set(() => ({ error: 'feedback.error.generic' }))
        throw error
      }
    },
    genresManualUpdate: async (genres) => {
      try {
        const playlistId = getState().currentPlaylistId
        await spotimatchEndpoints.genresManualUpdate(playlistId, genres).toPromise()

        set(() => ({ success: 'feedback.success.tags_updated' }))
      } catch (error) {
        set(() => ({ error: 'feedback.error.generic' }))
        throw error
      }
    },
    toggleVerification: async (playlistId, isPlaylistVerified) => {
      const feedbackMessage = isPlaylistVerified ? 'feedback.success.playlist_verified' : 'feedback.success.playlist_unverified'
      await spotimatchEndpoints.togglePlaylistVerified(playlistId, isPlaylistVerified).toPromise()

      set(() => ({ success: feedbackMessage }))
    },
    toggleWatch: async (playlistId, watch) => {
      try {
        const feedbackMessage = watch ? 'feedback.success.playlist_watched' : 'feedback.success.playlist_unwatched'

        if (watch) {
          await spotimatchEndpoints.setMonitoring(playlistId).toPromise()
        } else {
          await spotimatchEndpoints.removeMonitoring(playlistId).toPromise()
        }
        set(() => ({ success: feedbackMessage }))
      } catch (error) {
        const message = error?.response?.data?.message || 'feedback.error.generic'
        set(() => ({ error: message }))
        throw error
      }
    },
    enableAutoSubmit: async (playlistId, rate) => {
      await spotimatchEndpoints.enableAutoSubmit(playlistId, rate).toPromise()
      set(() => ({ success: 'feedback.success.auto_accept' }))
    },
    disableAutoSubmit: async (playlistId) => {
      await spotimatchEndpoints.disableAutoSubmit(playlistId).toPromise()
      set(() => ({ success: 'feedback.success.auto_accept_disabled' }))
    },
    getPlaylistById: (playlistId) => {
      const playlists = getState().playlists
      const playlist = Object.values(playlists).find((p) => p.PlaylistId === playlistId)

      if (!playlist) return
      return playlist
    },
    getPlaylistFeatures: async (playlistId) => {
      return getState().playlists[playlistId].features
    },
    getPlaylistAudioFeatures: async (playlistId) => {
      return getState().playlists[playlistId].audioFeatures
    },
    resetTracks: (playlistId) => set(state => ({
      playlists: {
        ...state.playlists,
        [playlistId]: {
          ...state[playlistId],
          tracks: [],
        }
      }
    })),
    resetFeedback: () => set({ success: false, error: false }),
  }),
)

export default usePlaylistStore
