import React, { useCallback } from 'react'
import ReactGA from 'react-ga4'
import { isEmpty } from 'lodash-es'
import { generateSpotifySignLink } from '@fnd/core/spotify/endpoints'
import {
  isUserAuthorizedSelector,
  isUserLoadingSelector,
  useUserContext,
} from '@fnd/modules/User'
import Button from '@fnd/components/Button'
import Icon from '@fnd/components/Icon'
import { SPOTIFY_REDIRECT_URL } from '../../constants'
import Emoji from '../Emoji'
import { useAppStore } from '@fnd/store'
import classNames from 'classnames'

function SignButton({
  className, variant, icon, label, redirect, type = 'web'
}) {
  const { setRedirect } = useAppStore()
  const { isAuthorized } = useUserContext(({ user }) => ({
    isAuthorized: isUserAuthorizedSelector(user),
    isLoading: isUserLoadingSelector(user),
    error: !isEmpty(user.error) ? user.error.message : void 0,
  }))

  const handleAuthorize = useCallback(() => {
    if (redirect) {
      setRedirect(redirect)
    } else {
      setRedirect(window.location.pathname)
    }

    ReactGA.event({
      category: 'Login',
      action: 'User Logged in',
    })

    window.location.href = generateSpotifySignLink(SPOTIFY_REDIRECT_URL, type)
  }, [])

  if (isAuthorized) {
    return null
  }

  const classes = classNames({
    [className]: className,
  })

  return (
    <Button variant={variant} className={classes} onClick={handleAuthorize}>
      {!label && (
        <>
          <Emoji symbol="🤟" className="mr-1" aria-label="rock" />
          Login <span className="with-spotify">with Spotify</span>
        </>
      )}
      {icon && <Icon name={icon} className="mr-1" />}
      {label && label}
    </Button>
  )
}

export default SignButton

SignButton.defaultProps = {
  label: '',
  variant: 'outline',
  className: '',
}
