import React from 'react'
import classNames from 'classnames'

const SectionTitle = ({
  title,
  emoji,
  tag = 'h1',
  subTag = 'span',
  badge,
  center = false,
  subtitle,
  children,
  headingClasses,
  subHeadingClasses,
  className,
}) => {
  const classes = classNames({
    'section-title': true,
    'section-title-center': center,
    [className]: className,
  })

  const Heading = tag
  const SubHeading = subTag

  return (
    <div className={classes}>
      {emoji && emoji}

      {badge && badge}

      {title && (
        <Heading
          className={classNames({
            [headingClasses]: headingClasses,
          })}>
          {title}
        </Heading>
      )}

      {subtitle && (
        <SubHeading
          className={classNames({
            'subtitle': true,
            [subHeadingClasses]: subHeadingClasses,
          })}
        >
          {subtitle}
        </SubHeading>
      )}
      {children}
    </div>
  )
}

export default SectionTitle
