import { make } from './endpoints'


const logout = () => make.post('/auth/logout')

export const authorizeDistribution = () =>
  make.post('/auth/authorize-distribution').toPromise()

export default {
  authorizeDistribution,
  logout,
}
