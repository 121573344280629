import { make } from './endpoints'


export const getNotifications = () =>
  make.get('/notifications').toPromise()

export const getNotificationDigest = () =>
  make.get('/notifications/digest').toPromise()

export const getNotificationsRead = () =>
  make.get('/notifications/read').toPromise()

export const getNotificationsUnread = () =>
  make.get('/notifications/unread').toPromise()

export const markNotificationsAsRead = (notificationsIds) =>
  make.post('/notifications/read', { body: { ids: notificationsIds } }).toPromise()


export default {
  getNotifications,
  getNotificationDigest,
  getNotificationsRead,
  getNotificationsUnread,
  markNotificationsAsRead,
}
