import React from 'react'
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share'
import CopyToClipboard from '@fnd/components/CopyToClipboard'
import Icon from '@fnd/components/Icon'
import Button from '@fnd/components/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { Modal } from '@fnd/components/Modal'
import classNames from 'classnames'

const Share = ({
  title,
  url,
  className,
  trigger,
  triggerVariant = 'outline-primary',
  triggerClasses,
  small
}) => {
  const intl = useIntl()

  const classes = classNames({
    'share': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      <Modal
        title={intl.formatMessage({ id: 'messages.share' })}
        trigger={
          trigger ? trigger : <Button
            small={small}
            className={triggerClasses ?? null}
            variant={triggerVariant}
            icon="share-square"
            label={<FormattedMessage id="messages.share" />}
          />
        }
      >
        <div className="share-platforms p-5">
          <FacebookShareButton resetButtonStyle={false} url={url} quote={title}>
            <Icon name="facebook" type="brands" />
            <span className="button-label">
              Facebook
            </span>
          </FacebookShareButton>
          <TwitterShareButton resetButtonStyle={false} url={url} title={title}>
            <Icon name="twitter" type="brands" />
            <span className="button-label">
              Twitter
            </span>
          </TwitterShareButton>
          <LinkedinShareButton resetButtonStyle={false} url={url} title={title}>
            <Icon name="linkedin" type="brands" />
            <span className="button-label">
              LinkedIn
            </span>
          </LinkedinShareButton>
          <CopyToClipboard
            label={<FormattedMessage id="messages.copy" />}
            text={url}
          />
        </div>
      </Modal>
    </div>
  )
}

Share.defaultProps = {
  className: '',
  small: false,
}

export default Share
