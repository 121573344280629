import { API_URL } from '@fnd/constants'
import { request } from '@fnd/core/libs'
import { map, mergeMap, retryWhen } from 'rxjs/operators'
import { throwError, timer } from 'rxjs'

import { default as accelerateEndpoints } from './accelerate'
import { default as affiliateEndpoints } from './affiliate'
import { default as analysisEndpoints } from './analysis'
import { default as auditLogsEndpoints } from './auditLogs'
import { default as authEndpoints } from './auth'
import { default as blockEndpoints } from './block'
import { default as chatEndpoints } from './chat'
import { default as cartEndpoints } from './cart'
import { default as curatorEndpoints } from './curator'
import { default as discountEndpoints } from './discount'
import { default as downloadEndpoints } from './downloads'
import { default as eventEndpoints } from './event'
import { default as genresEnrpoints } from './genres'
import { default as invoiceEndpoints } from './invoice'
import { default as magazineEndpoints } from './magazine'
import { default as mediaEndpoints } from './media'
import { default as notificationsEndpoints } from './notification'
import { default as openEndpoints } from './open'
import { default as plansEndpoints } from './plans'
import { default as playlistEndpoints } from './playlist'
import { default as playlistWatchEndpoints } from './playlist_check'
import { default as radioEndpoints } from './radio'
import { default as referralsEndpoints } from './referrals'
import { default as reportEndpoints } from './report'
import { default as requestEndpoints } from './request'
import { default as roleEndpoints } from './role'
import { default as tiktokEndpoints } from './tiktok'
import { default as tokenEndpoints } from './token'
import { default as trackEndpoints } from './track'
import { default as trackQueueEndpoints } from './tracks_queue'
import { default as transactionEndpoints } from './transaction'
import { default as userEndpoints } from './user'
import { default as vipCampaignEndpoints } from './vip_campaign'
import { ROUTES } from '@fnd/screens/constants'


export const make = request({
  uri: API_URL,
  middleware: [
    retryWhen((errors) => {
      let maxRetries = 2
      return errors.pipe(
        mergeMap((error) => {
          if (error.response && error.response.status === 403 && window.location.pathname !== ROUTES.BLOCKED && error.response.headers.get("X-User-Blocked") !== undefined) {
            return window.location.replace(ROUTES.BLOCKED)
          }
          if (error.response && error.response.status === 401) {
            if (maxRetries === 0) {
              return throwError(error)
            }
            maxRetries--
            return timer(500)
          }

          if (error?.response?.data?.error) {
            return throwError(error.response.data.error)
          }

          return throwError(error)
        })
      )
    }),
    map((response) => response.data)
  ],
})

export default {
  ...accelerateEndpoints,
  ...affiliateEndpoints,
  ...analysisEndpoints,
  ...auditLogsEndpoints,
  ...authEndpoints,
  ...blockEndpoints,
  ...chatEndpoints,
  ...cartEndpoints,
  ...curatorEndpoints,
  ...discountEndpoints,
  ...downloadEndpoints,
  ...eventEndpoints,
  ...genresEnrpoints,
  ...invoiceEndpoints,
  ...magazineEndpoints,
  ...mediaEndpoints,
  ...notificationsEndpoints,
  ...openEndpoints,
  ...plansEndpoints,
  ...playlistEndpoints,
  ...playlistWatchEndpoints,
  ...radioEndpoints,
  ...referralsEndpoints,
  ...reportEndpoints,
  ...requestEndpoints,
  ...roleEndpoints,
  ...tiktokEndpoints,
  ...tokenEndpoints,
  ...trackEndpoints,
  ...trackQueueEndpoints,
  ...transactionEndpoints,
  ...userEndpoints,
  ...vipCampaignEndpoints,
}
