import React, { useState, useCallback } from 'react'
import cx from 'classnames'
import Button from '@fnd/components/Button'
import Spinner from '@fnd/components/Spinner'

const getInitialState = (state) => {
  const initialState = {
    name: {
      touched: false,
      value: '',
      valid: true,
    },
    description: {
      touched: false,
      value: '',
      valid: true,
    },
    public: {
      value: true,
      valid: true,
      touched: false,
    },
    collaborative: {
      value: false,
      valid: true,
      touched: false,
    },
  }
  return Object.entries(state).reduce((acc, [key, value]) => {
    acc[key].value = value
    return acc
  }, initialState)
}

export default function MyPlaylistDetailsForm({ playlistDetails, onSave }) {
  const [createState, setCreateState] = useState(
    getInitialState({
      name: playlistDetails.name,
      collaborative: playlistDetails.collaborative,
      description: playlistDetails.description,
      public: playlistDetails.public,
    })
  )
  const [isFormLoading, setIsFormLoading] = useState(false)
  const updateField = useCallback(
    (field) => (key, value) =>
      setCreateState((currentState) => ({
        ...currentState,
        [key]: { ...currentState[key], [field]: value },
      })),
    []
  )
  const updateTouched = useCallback(updateField('touched'), [])
  const updateValue = useCallback(updateField('value'), [])
  const updateValid = useCallback(updateField('valid'), [])
  const isFormValid = Object.values(createState).every(({ valid }) => valid)
  const isFormChanged = Object.entries(createState).some(
    ([key, { value }]) => value !== playlistDetails[key]
  )
  const isSubmitButtonDisabled = !isFormValid || isFormLoading || !isFormChanged

  const changeHandler = ({ target }) => {
    const valueField = target.type === 'checkbox' ? 'checked' : 'value'
    updateValue(target.name, target[valueField])
    if (['public', 'collaborative'].includes(target.name)) {
      updateValue(
        target.name === 'collaborative' ? 'public' : 'collaborative',
        false
      )
    }
  }

  const focusHandler = ({ target }) => {
    updateTouched(target.name, false)
  }

  const blurHandler = ({ target }) => {
    updateValid(target.name, !!target.value)
    updateTouched(target.name, true)
  }

  const submitHandler = async () => {
    if (isSubmitButtonDisabled) {
      return Object.keys(createState).forEach((key) => updateTouched(key, true))
    }

    setIsFormLoading(true)
    const data = Object.entries(createState).reduce((acc, [key, input]) => {
      acc[key] = input.value
      return acc
    }, {})
    await onSave(data)
    setIsFormLoading(false)
    return data
  }

  const getInputClasses = (inputName) =>
    cx({
      invalid: !createState[inputName].valid && createState[inputName].touched,
    })

  return (
    <div className="form">
      <div className="form-group">
        <label htmlFor="create-playlist-name">
          Name
        </label>
        <input
          onBlur={blurHandler}
          onFocus={focusHandler}
          onChange={changeHandler}
          value={createState.name.value}
          className={getInputClasses('name')}
          type="text"
          name="name"
          id="create-playlist-name"
          required
        />
      </div>
      <div className="flex">
        <label className="mr-5">
          <input
            type="checkbox"
            checked={createState.public.value}
            onChange={changeHandler}
            name="public"
          />
          Public
        </label>
        <label>
          <input
            type="checkbox"
            checked={createState.collaborative.value}
            onChange={changeHandler}
            name="collaborative"
          />
          Collaborative
        </label>
      </div>
      <div className="form-group">
        <label htmlFor="create-playlist-description">
          Description
        </label>
        <textarea
          onFocus={focusHandler}
          onChange={changeHandler}
          onBlur={blurHandler}
          value={createState.description.value}
          className={getInputClasses('description')}
          type="text"
          name="description"
          id="create-playlist-description"
          required
        />
      </div>
      <Button disabled={isSubmitButtonDisabled} onClick={submitHandler}>
        {isFormLoading ? <Spinner inline /> : 'Save'}
      </Button>
    </div>
  )
}
