import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ROUTES } from '@fnd/screens/constants'
import { CREDIT_DISCOUNT_PERCENTAGE } from '@fnd/constants'
import BillingInfoEdit from '@fnd/modules/Profile/BillingInfo/BillingInfoEdit'
import { getBillingInfo } from '@fnd/modules/Profile/BillingInfo/BillingInfo'
import Portal from '@fnd/components/Portal'
import classNames from 'classnames'
import {
  isUserAuthorizedSelector,
  userProfileSelector,
  useUserContext,
} from '@fnd/modules/User'
import StripeGateway from './StripeGateway'
import PaymentCalculator from './PaymentCalculator'
import PaymentSummary from './PaymentSummary'
import { useDiscountStore } from '@fnd/store'

export default function PaymentGateway({
  addons,
  onClose,
  autoApply = true,
  mode,
  monthly,
  plan,
  price,
  payload,
  contract = false,
  creditsDiscount = CREDIT_DISCOUNT_PERCENTAGE,
  disableAffiliate,
  disableCredits,
  disableCoupon,
  maxCredits,
  className,
}) {
  const [paymentPrice, setPaymentPrice] = useState(price)
  const [affiliateDiscount, setAffiliateDiscount] = useState(0)
  const [creditsScoreUsed, setCreditsScoreUsed] = useState(0)
  const [isTermsAgreed, setTermsAgreed] = useState(false)
  const [isContractAgreed, setContractAgreed] = useState(false)

  const { profile, updateUserBillingInfo } = useUserContext(
    ({ user, updateUserProfile }) => ({
      profile: userProfileSelector(user),
      isAuthorized: isUserAuthorizedSelector(user),
      updateUserBillingInfo: (next) =>
        updateUserProfile({ billingInfo: { $set: next } }),
    })
  )

  const { discountAmount } = useDiscountStore()

  const handleUseCredits = (price, creditsUsed, affiliateDiscount) => {
    setPaymentPrice(price)
    setCreditsScoreUsed(creditsUsed)
    setAffiliateDiscount(affiliateDiscount)
  }

  if (!profile.billingInfo) {
    return (
      <BillingInfoEdit
        billingInfo={getBillingInfo(profile.billingInfo)}
        updateUserBillingInfo={updateUserBillingInfo}
        onCancel={onClose}
      />
    )
  }

  const classes = classNames({
    payment: true,
    [className]: className,
  })

  return (
    <div className={classes}>
      <PaymentCalculator
        autoApply={autoApply}
        billing={monthly ? 'monthly' : 'one-time'}
        price={price}
        disableCredits={disableCredits}
        disableAffiliate={disableAffiliate}
        disableCoupon={disableCoupon}
        creditsDiscount={creditsDiscount}
        maxCredits={maxCredits}
        plan={plan}
        onChange={(price, creditsUsed, affiliateDiscount) =>
          handleUseCredits(price, creditsUsed, affiliateDiscount)
        }
      />

      {paymentPrice > 0 && (
        <StripeGateway
          amount={paymentPrice}
          creditsScoreUsed={creditsScoreUsed}
          disabled={!isTermsAgreed || (contract && !isContractAgreed)}
          mode={mode}
          payload={payload}
          profile={profile}
          plan={plan}
          addons={addons}
        />
      )}

      <Portal id="before-pay">
        <PaymentSummary
          className="mt-4"
          affiliateDiscount={affiliateDiscount}
          discountCodeAmount={discountAmount}
          disableAffiliate={disableAffiliate}
          disableCoupon={disableCoupon}
          billing={monthly ? 'monthly' : 'one-time'}
          creditsScoreUsed={creditsScoreUsed}
          price={price}
          paymentPrice={paymentPrice}
        />

        <div className="payment-terms">
          <label className="terms-label">
            <input
              type="checkbox"
              checked={isTermsAgreed}
              onChange={(e) => setTermsAgreed(e.target.checked)}
            />
            <FormattedMessage id="payment.i_agree_to" />{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={ROUTES.TERMS}
              className="font-semibold underline lowercase"
            >
              <FormattedMessage id="payment.terms_and_conditions" />
            </a>
            {', '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={ROUTES.PRIVACY}
              className="font-semibold underline lowercase"
            >
              <FormattedMessage id="payment.privacy_policy" />
            </a>
          </label>
        </div>

        {contract && (
          <div className="mt-5 payment-terms">
            <label className="terms-label">
              <input
                type="checkbox"
                checked={isContractAgreed}
                onChange={(e) => setContractAgreed(e.target.checked)}
              />
              <FormattedMessage id="payment.i_agree_to" />{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={ROUTES.CONTRACT_OF_SERVICE}
                className="font-semibold underline lowercase"
              >
                <FormattedMessage id="payment.contract_of_service" />
              </a>
            </label>
          </div>
        )}
      </Portal>
    </div>
  )
}

PaymentGateway.defaultProps = {
  btnIcon: 'check',
  className: '',
}
