import { createSelector } from 'reselect'
import { isEmpty } from 'lodash-es'

export const isUserAuthorizedSelector = (user) => user.isAuthorized
export const isUserLoadingSelector = (user) => user.isLoading
export const userProfileSelector = (user) => user.profile
export const userPlanSelector = (user) => user.profile.plan
export const userFeaturedSelector = (user) => user.profile.featured
export const isVerifiedUserSelector = (user) => user.profile.verified
export const isNotificationEnabled = (user) => user.profile.web_notifications
export const isCuratorFeatured = (user) => {
  if(!user.profile.featured) return false
  const endDate = new Date(user.profile.featured.ends_at)
  return endDate >= Date.now()
}

export const isUserAllowedSelector = createSelector(
  isUserAuthorizedSelector,
  isUserLoadingSelector,
  (user) => user.error,

  (isAuthorized, isLoading, error) =>
    isAuthorized && !isLoading && isEmpty(error)
)

export const isBlockedUserSelector = createSelector(
  userProfileSelector,
  isUserLoadingSelector,
  ({ isBlocked }, isLoading) => isLoading || (isBlocked ?? false)
)

export const userContactsSelector = createSelector(
  userProfileSelector,

  ({ contacts = {} }) => contacts
)
