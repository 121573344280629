import React, { useContext, useEffect, useMemo } from 'react'
import Spinner from '@fnd/components/Spinner'
import { EmailVerifyContext } from '@fnd/components/EmailVerifyPrompt/useEmailVerify'
import { usePushToPlaylist } from './core'
import CuratorPageButton from './CuratorPageButton'
import CuratorContacts from '@fnd/modules/Curator/CuratorContacts'
import MatchMessage from '@fnd/components/Match/MatchMessage'
import MonthlyPushLimitPopup from './MonthlyPushLimitPopup'
import VipCampaignPopup from './VipCampaignPopup'
import PushButton from './PushButton'
import { usePushToPlaylistStore } from '@fnd/store'
import { useIntl } from 'react-intl'
import ReactGA from 'react-ga4'
import { toastFeedback } from '@fnd/core/libs/toast'
import classNames from 'classnames'


function PushToPlaylist({
  className,
  disabled,
  curatorId,
  curatorName,
  playlist,
  score,
  track,
  tracks,
  trackId,
  userName,
  userContacts,
}) {
  const { openEmailVerifyPopup } = useContext(EmailVerifyContext)

  const {
    increment,
    pushedPlaylists,
    setPushedPlaylists
  } = usePushToPlaylistStore()

  const intl = useIntl()

  const isTrackInPlaylist = useMemo(() => {
    if (!tracks || !track || tracks.length === 0) {
      return false
    }

    const filteredTracks = tracks.filter(track => track !== null && track !== undefined)
    return Boolean(
      filteredTracks.find(({ track }) => track?.id === trackId)
    )
  }, [trackId, tracks])

  const [pushToPlaylistState, pushToPlaylistQueueHandler] = usePushToPlaylist({
    trackId,
    playlistId: playlist.id,
  })

  const { isLoading, error, success } = pushToPlaylistState

  const pushHandler = () => {
    if (!userContacts.email) {
      openEmailVerifyPopup()
    }

    const trackData = {
      name: track.name,
      image: track?.album?.images[0].url,
    }

    pushToPlaylistQueueHandler(score, trackData)
    increment()
    setPushedPlaylists([...pushedPlaylists, playlist.id])

    ReactGA.event({
      category: 'Push to playlist',
      action: 'Push to playlist',
    })
  }

  useEffect(() => {
    if (error && !isLoading) {
      toastFeedback('error', intl.formatMessage({ id: error }))

    } else if (!error && !isLoading && success) {
      toastFeedback('success', intl.formatMessage({ id: 'feedback.success.push_to_playlist' }))
    }
  }, [success, error])

  const classes = classNames('push-to-playlist', className)

  if (pushToPlaylistState.isLoading) {
    return (
      <div className={classes}>
        <Spinner inline />
      </div>
    )
  }

  if (pushToPlaylistState.isTrackInQueue || isTrackInPlaylist) {
    return (
      <div className={classes}>
        <CuratorPageButton
          playlistId={playlist.id}
          isTrackInPlaylist={isTrackInPlaylist}
        />

        <CuratorContacts curatorId={curatorId} />

        <MatchMessage
          className="mt-4"
          typology="spotify"
          score={score}
          track={track}
          playlist={playlist}
          curatorName={curatorName}
          userName={userName}
        />
      </div>
    )
  }

  return (
    <>
      <MonthlyPushLimitPopup
        isOpen={error === 'feedback.error.playlist_push_limit'}
      />

      <VipCampaignPopup />

      <PushButton
        className={classes}
        disabled={disabled}
        onClick={pushHandler}
      />
    </>
  )
}

export default PushToPlaylist
