import { useState, useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { toastFeedback } from '@fnd/core/libs/toast'


export default function useBlockUnblockUser(
  spotifyId, _isBlocked = false, checkOnline = false
) {
  const [isBlocked, toggleBlock] = useState(_isBlocked)
  const [isLoading, setIsLoading] = useState(false)
  const intl = useIntl()
  useEffect(() => {
    if (checkOnline) {
      spotimatchEndpoints
        .checkIfUserIsBlocked(spotifyId)
        .then((response) => {
          toggleBlock(response.isBlocked)
        })
        .catch((error) => {
          console.error(error)
          toggleBlock(false)
        })
    }
  }, [checkOnline])


  const blockUser = useCallback((id) => {
    setIsLoading(true)
    spotimatchEndpoints
      .blockUser(id)
      .then(() => {
        toastFeedback('success', intl.formatMessage({ id: 'feedback.success.block_user' }))
        toggleBlock(true)
        setIsLoading(false)
      })
      .catch((error) => {
        toastFeedback('error', intl.formatMessage({ id: 'feedback.error.block_user' }))
        console.error(error)
        setIsLoading(false)
      })
  }, [isBlocked])

  const unblockUser = useCallback((id) => {
    setIsLoading(true)
    spotimatchEndpoints
      .unblockUser(id)
      .then(() => {
        toastFeedback('success', intl.formatMessage({ id: 'feedback.success.unblock_user' }))
        toggleBlock(false)
        setIsLoading(false)
      })
      .catch((error) => {
        toastFeedback('error', intl.formatMessage({ id: 'feedback.error.unblock_user' }))
        console.error(error)
        setIsLoading(false)
      })
  }, [isBlocked])

  const toggle = () => {
    if (isBlocked) {
      unblockUser(spotifyId)
    } else {
      blockUser(spotifyId)
    }
  }

  return [isLoading, isBlocked, toggle]
}
