import React from 'react'

export const AmericanExpressLogo = () => (
  <svg viewBox="0 0 114 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M113.079 6.26134H95.8624L86.8635 32.0859L77.4726 6.26134H59.8655V46.4722L41.8664 6.26134H27.3899L9.39089 47.3451H21.9112L25.4327 39.1288H44.2144L47.7359 47.3451H71.2124V18.7817L81.7767 47.3451H91.5583L102.123 18.7817V47.3451H113.079V53.6053H98.6012L86.8635 66.5176L74.7339 53.6053H25.0419V94.6891H74.3431L86.4716 81.386L98.6012 94.6891H113.079V99.7747H95.8624L86.4716 89.6023L77.0807 99.7747H18.7817V52.8226H0L23.4766 0H46.1705L54.3868 17.9991V0H82.5594L87.2542 13.695L91.9502 0H113.079V6.26134ZM113.079 62.6043L102.515 73.9513L113.079 85.2982V94.6891L93.9063 73.9513L113.079 53.6053V62.6043ZM59.8655 94.6891V84.9074H36.3889V78.6472H59.4735V69.2564H36.3889V62.9962H59.8655V53.6053L79.4287 73.9513L59.8655 94.6891ZM28.9541 30.1287L34.4328 16.8256L39.9103 30.1287H28.9541Z" fill="#006FCF" />
  </svg>
)

export const GPayLogo = () => (
  <svg viewBox="0 0 128 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M59.4786 39.0593H54.8002V2.65592H67.2027C70.3459 2.65592 73.0262 3.70368 75.2192 5.79919C77.4609 7.89469 78.5817 10.4532 78.5817 13.4746C78.5817 16.5691 77.4609 19.1276 75.2192 21.1987C73.0506 23.2699 70.3703 24.2933 67.2027 24.2933H59.4786V39.0593ZM59.4786 7.13934V19.8342H67.3001C69.152 19.8342 70.7114 19.2007 71.9297 17.958C73.1724 16.7153 73.8059 15.2046 73.8059 13.499C73.8059 11.8177 73.1724 10.3313 71.9297 9.08865C70.7114 7.79723 69.1763 7.1637 67.3001 7.1637H59.4786V7.13934Z" fill="currentColor" />
    <path d="M90.8131 13.3284C94.2731 13.3284 97.0021 14.2543 99.0001 16.1062C100.998 17.958 101.997 20.4921 101.997 23.7085V39.0593H97.5382V35.5992H97.3432C95.4183 38.4501 92.8355 39.8634 89.6191 39.8634C86.8657 39.8634 84.5753 39.0593 82.7235 37.4267C80.8717 35.7942 79.9457 33.7718 79.9457 31.3351C79.9457 28.7523 80.9204 26.7055 82.8697 25.1948C84.819 23.6597 87.4262 22.9044 90.6669 22.9044C93.4446 22.9044 95.7351 23.4161 97.5138 24.4395V23.3673C97.5138 21.7348 96.8803 20.3703 95.5889 19.225C94.2974 18.0798 92.7868 17.5194 91.0567 17.5194C88.4496 17.5194 86.3784 18.6159 84.8677 20.8332L80.7498 18.2504C83.0159 14.9609 86.3784 13.3284 90.8131 13.3284ZM84.7702 31.4082C84.7702 32.6265 85.2819 33.6499 86.3297 34.454C87.3531 35.2581 88.5714 35.6723 89.9603 35.6723C91.9339 35.6723 93.6883 34.9413 95.2234 33.4794C96.7584 32.0174 97.5382 30.3117 97.5382 28.3381C96.0762 27.1929 94.0538 26.6081 91.4466 26.6081C89.546 26.6081 87.9622 27.071 86.6952 27.9726C85.4038 28.9229 84.7702 30.0681 84.7702 31.4082Z" fill="currentColor" />
    <path d="M127.436 14.1327L111.841 50H107.017L112.816 37.4513L102.533 14.1327H107.626L115.033 32.0176H115.131L122.343 14.1327H127.436Z" fill="currentColor" />
    <path d="M40.8698 21.3449C40.8698 19.8196 40.7333 18.3601 40.4799 16.9566H20.8699V24.9975L32.1637 24.9999C31.7056 27.6753 30.2315 29.956 27.9727 31.4765V36.6933H34.6953C38.6207 33.0603 40.8698 27.6899 40.8698 21.3449Z" fill="#4285F4" />
    <path d="M27.9751 31.4767C26.1038 32.7389 23.6939 33.4772 20.8748 33.4772C15.4289 33.4772 10.8091 29.8076 9.1546 24.8613H2.21997V30.2413C5.65561 37.0591 12.7169 41.7374 20.8748 41.7374C26.5131 41.7374 31.2499 39.8831 34.6977 36.6911L27.9751 31.4767Z" fill="#34A853" />
    <path d="M8.50138 20.8697C8.50138 19.4809 8.73285 18.1383 9.15439 16.8761V11.496H2.21976C0.799209 14.3152 0 17.4974 0 20.8697C0 24.2421 0.801646 27.4243 2.21976 30.2435L9.15439 24.8634C8.73285 23.6012 8.50138 22.2586 8.50138 20.8697Z" fill="#FABB05" />
    <path d="M20.8748 8.26019C23.9522 8.26019 26.708 9.32013 28.8839 11.3913L34.8415 5.43857C31.2231 2.06871 26.5058 0 20.8748 0C12.7194 0 5.6554 4.6783 2.21976 11.496L9.15439 16.8761C10.8089 11.9297 15.4289 8.26019 20.8748 8.26019Z" fill="#E94235" />
  </svg>
)

export const KlarnaLogo = () => (
  <svg viewBox="0 0 148 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M143.422 24.4811C141.114 24.4811 139.244 26.3686 139.244 28.6972C139.244 31.0253 141.114 32.9133 143.422 32.9133C145.729 32.9133 147.6 31.0254 147.6 28.6972C147.6 26.3685 145.73 24.4811 143.422 24.4811ZM129.675 21.2215C129.675 18.0336 126.975 15.4498 123.644 15.4498C120.313 15.4498 117.612 18.0337 117.612 21.2215C117.612 24.4095 120.312 26.994 123.644 26.994C126.975 26.994 129.675 24.4095 129.675 21.2215ZM129.698 10.0034H136.354V32.4388H129.698V31.0049C127.817 32.2991 125.547 33.0599 123.099 33.0599C116.62 33.0599 111.367 27.7597 111.367 21.2214C111.367 14.6831 116.62 9.38328 123.099 9.38328C125.547 9.38328 127.817 10.144 129.698 11.4386V10.0034ZM76.4254 12.9258V10.0038H69.61V32.4387H76.4407V21.9642C76.4407 18.4302 80.2366 16.5307 82.8705 16.5307C82.8975 16.5307 82.9227 16.5333 82.9496 16.5337V10.0055C80.2463 10.0055 77.76 11.1731 76.4254 12.9258ZM59.4482 21.2216C59.4482 18.0337 56.7476 15.4499 53.4163 15.4499C50.0851 15.4499 47.3843 18.0338 47.3843 21.2216C47.3843 24.4096 50.085 26.9941 53.4163 26.9941C56.7477 26.9941 59.4482 24.4096 59.4482 21.2216ZM59.4702 10.0035H66.1273V32.4389H59.4702V31.005C57.5896 32.2992 55.3195 33.06 52.8715 33.06C46.3919 33.06 41.1392 27.7598 41.1392 21.2215C41.1392 14.6832 46.3919 9.38336 52.8715 9.38336C55.3197 9.38336 57.5896 10.1441 59.4702 11.4387V10.0035ZM99.5381 9.4001C96.879 9.4001 94.3622 10.2332 92.6796 12.5315V10.0049H86.0515V32.4391H92.7611V20.6491C92.7611 17.2375 95.0283 15.5669 97.7583 15.5669C100.684 15.5669 102.366 17.3304 102.366 20.6029V32.4391H109.015V18.1721C109.015 12.9511 104.901 9.4001 99.5381 9.4001ZM31.4426 32.4391H38.4101V0.00571395H31.4426V32.4391ZM0.835693 32.4481H8.21355V-0.000165646H0.835693V32.4481ZM26.641 -0.000165646C26.641 7.02456 23.928 13.5594 19.0938 18.4167L29.292 32.4493H20.1795L9.0962 17.1989L11.9566 15.0373C16.7002 11.4517 19.4211 5.97095 19.4211 -0.000244141L26.641 -0.000165646Z" fill="currentColor" />
  </svg>
)

export const MastercardLogo = () => (
  <svg viewBox="0 0 114 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M71.9414 7.48304H41.3164V62.517H71.9414V7.48304Z" fill="#FF5F00" />
    <path d="M43.2611 35C43.2562 29.6998 44.4573 24.468 46.7733 19.7006C49.0893 14.9333 52.4596 10.7553 56.629 7.48302C51.4658 3.42461 45.2649 0.900743 38.7351 0.199895C32.2053 -0.500954 25.6101 0.649466 19.7032 3.51971C13.7963 6.38996 8.81614 10.8642 5.33191 16.4311C1.84768 21.9979 0 28.4327 0 35C0 41.5673 1.84768 48.0021 5.33191 53.5689C8.81614 59.1358 13.7963 63.61 19.7032 66.4803C25.6101 69.3505 32.2053 70.501 38.7351 69.8001C45.2649 69.0993 51.4658 66.5754 56.629 62.517C52.4596 59.2447 49.0894 55.0667 46.7733 50.2993C44.4573 45.532 43.2563 40.3002 43.2611 35Z" fill="#EB001B" />
    <path d="M113.256 35C113.256 41.5672 111.409 48.002 107.925 53.5688C104.441 59.1357 99.4609 63.6099 93.5542 66.4802C87.6474 69.3505 81.0523 70.5009 74.5225 69.8001C67.9928 69.0993 61.7921 66.5754 56.629 62.517C60.7947 59.2414 64.1624 55.0627 66.478 50.2961C68.7937 45.5295 69.9969 40.2993 69.9969 35C69.9969 29.7007 68.7937 24.4705 66.478 19.7039C64.1624 14.9373 60.7947 10.7586 56.629 7.48302C61.7921 3.4246 67.9928 0.900725 74.5225 0.199886C81.0523 -0.500954 87.6474 0.649516 93.5542 3.51979C99.4609 6.39007 104.441 10.8643 107.925 16.4312C111.409 21.998 113.256 28.4328 113.256 35Z" fill="#F79E1B" />
    <path d="M109.917 56.6881V55.5613H110.371V55.3318H109.214V55.5613H109.669V56.6881H109.917ZM112.163 56.6881V55.3296H111.809L111.401 56.264L110.993 55.3296H110.638V56.6881H110.888V55.6633L111.271 56.5468H111.531L111.913 55.6611V56.6881H112.163Z" fill="#F79E1B" />
  </svg>
)

export const PayPalLogo = () => (
  <svg viewBox="0 0 207 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.7942 49.0364L13.6609 43.5326L11.7305 43.4877H2.51269L8.91864 2.87362C8.9382 2.751 9.00307 2.63668 9.09751 2.5555C9.19195 2.47433 9.31311 2.42959 9.43903 2.42959H24.9817C30.1418 2.42959 33.7025 3.50322 35.5618 5.6223C36.4332 6.61643 36.9884 7.65524 37.257 8.79846C37.5384 9.99805 37.5437 11.4312 37.2684 13.1792L37.2484 13.3068V14.4267L38.1203 14.9205C38.8544 15.3098 39.4373 15.7555 39.8847 16.2659C40.6307 17.1158 41.113 18.1961 41.3166 19.4768C41.527 20.794 41.4574 22.3614 41.113 24.1358C40.7152 26.1771 40.0722 27.9549 39.2041 29.4096C38.4051 30.75 37.3877 31.8615 36.18 32.7233C35.0266 33.5417 33.6562 34.1632 32.1069 34.5609C30.6054 34.9515 28.894 35.149 27.0165 35.149H25.8069C24.9421 35.149 24.1017 35.4604 23.4425 36.0189C22.7813 36.5889 22.3435 37.3672 22.2094 38.219L22.1183 38.7146L20.5872 48.415L20.5176 48.7712C20.4994 48.8843 20.468 48.9406 20.4217 48.9787C20.3802 49.0131 20.3206 49.0364 20.2624 49.0364H12.7942Z" fill="#253B80" />
    <path d="M38.9426 13.4383C38.8964 13.7348 38.8434 14.038 38.7838 14.3495C36.7337 24.8721 29.7215 28.5072 20.7651 28.5072H16.2051C15.1099 28.5072 14.1869 29.3025 14.0162 30.3827L11.0202 49.385C10.9091 50.0942 11.4562 50.7338 12.1716 50.7338H20.2599C21.2177 50.7338 22.031 50.0379 22.1817 49.0936L22.2614 48.6825L23.7844 39.0197L23.8822 38.4898C24.031 37.5422 24.8462 36.8463 25.804 36.8463H27.0136C34.8496 36.8463 40.9841 33.6652 42.7771 24.4596C43.526 20.614 43.1382 17.4031 41.1563 15.1448C40.5568 14.4638 39.8127 13.8988 38.9426 13.4383Z" fill="#179BD7" />
    <path d="M36.7942 12.5807C36.4813 12.4896 36.1579 12.4067 35.8264 12.3322C35.4934 12.2593 35.1519 12.1947 34.8009 12.1384C33.5712 11.9395 32.2242 11.8451 30.7808 11.8451H18.5985C18.2985 11.8451 18.0137 11.913 17.7585 12.0356C17.1967 12.3057 16.7793 12.8375 16.6782 13.4887L14.0862 29.9013L14.0118 30.3801C14.1826 29.2999 15.1055 28.5046 16.2007 28.5046H20.7607C29.7171 28.5046 36.7298 24.8678 38.7794 14.3469C38.8405 14.0354 38.892 13.7322 38.9383 13.4357C38.4198 13.1606 37.8579 12.9253 37.2531 12.7248C37.1042 12.6751 36.9502 12.6271 36.7942 12.5807Z" fill="#222D65" />
    <path d="M16.6843 13.4898C16.7854 12.8386 17.2032 12.3068 17.7647 12.0384C18.0217 11.9157 18.3051 11.8478 18.6046 11.8478H30.7874C32.2307 11.8478 33.5778 11.9423 34.807 12.1411C35.1585 12.1974 35.5 12.262 35.833 12.3349C36.1645 12.4095 36.4874 12.4924 36.8008 12.5834C36.9563 12.6299 37.1103 12.6779 37.2611 12.726C37.8659 12.9264 38.4278 13.1634 38.9463 13.4367C39.5563 9.54813 38.9415 6.90051 36.8389 4.50304C34.5208 1.86372 30.3366 0.733765 24.9829 0.733765H9.43978C8.34605 0.733765 7.41353 1.52901 7.2442 2.61094L0.770518 43.6426C0.642685 44.4543 1.26897 45.1869 2.08748 45.1869H11.6835L16.6843 13.4898Z" fill="#253B80" />
    <path fillRule="evenodd" clipRule="evenodd" d="M77.482 11.4593H66.1011C65.3223 11.4593 64.6599 12.0253 64.5385 12.7938L59.9355 41.9747C59.844 42.5507 60.29 43.0697 60.8741 43.0697H66.3074C67.0862 43.0697 67.7486 42.5037 67.8701 41.7337L69.1115 33.8632C69.2313 33.0924 69.8953 32.5272 70.6725 32.5272H74.2753C81.7723 32.5272 86.0988 28.8997 87.2293 21.7115C87.7383 18.5667 87.2508 16.0957 85.7776 14.3646C84.1604 12.465 81.291 11.4593 77.482 11.4593ZM78.7951 22.1173C78.1728 26.2007 75.0525 26.2007 72.0354 26.2007H70.318L71.5228 18.5744C71.5944 18.1139 71.9938 17.7743 72.4597 17.7743H73.2469C75.3021 17.7743 77.241 17.7743 78.2429 18.9455C78.8397 19.6447 79.023 20.6827 78.7951 22.1173ZM111.502 21.9864H106.052C105.588 21.9864 105.187 22.3259 105.115 22.7872L104.874 24.3111L104.493 23.759C103.313 22.0464 100.682 21.4743 98.0563 21.4743C92.0332 21.4743 86.8897 26.0352 85.8878 32.4333C85.3672 35.6243 86.1072 38.6759 87.9178 40.8042C89.5789 42.7609 91.9554 43.5763 94.7825 43.5763C99.635 43.5763 102.326 40.4562 102.326 40.4562L102.083 41.9708C101.992 42.5499 102.437 43.0689 103.018 43.0689H107.928C108.708 43.0689 109.367 42.5029 109.49 41.7329L112.436 23.0813C112.529 22.5077 112.085 21.9864 111.502 21.9864ZM103.905 32.5927C103.379 35.7059 100.908 37.7958 97.7567 37.7958C96.1741 37.7958 94.9088 37.2883 94.0971 36.3266C93.2916 35.3717 92.9851 34.0119 93.2415 32.498C93.7329 29.411 96.2457 27.2533 99.3493 27.2533C100.896 27.2533 102.155 27.7669 102.983 28.7372C103.814 29.7174 104.143 31.085 103.905 32.5927ZM135.052 21.9856H140.529C141.296 21.9856 141.743 22.8457 141.307 23.4748L123.092 49.765C122.797 50.1909 122.311 50.4442 121.792 50.4442H116.322C115.552 50.4442 115.102 49.5771 115.548 48.9465L121.22 40.9413L115.187 23.24C114.979 22.6247 115.433 21.9856 116.088 21.9856H121.469C122.168 21.9856 122.785 22.4445 122.987 23.1137L126.188 33.8047L133.742 22.6794C134.038 22.2451 134.529 21.9856 135.052 21.9856Z" fill="#253B80" />
    <path fillRule="evenodd" clipRule="evenodd" d="M194.434 41.9721L199.104 12.2576C199.176 11.7963 199.576 11.4567 200.04 11.4552H205.298C205.879 11.4552 206.325 11.9757 206.233 12.5517L201.627 41.7311C201.507 42.5011 200.845 43.0671 200.065 43.0671H195.369C194.788 43.0671 194.342 42.5481 194.434 41.9721ZM158.663 11.4568H147.28C146.503 11.4568 145.841 12.0227 145.719 12.7912L141.116 41.9721C141.024 42.5481 141.47 43.0671 142.052 43.0671H147.892C148.435 43.0671 148.899 42.6713 148.984 42.1323L150.29 33.8606C150.41 33.0899 151.074 32.5247 151.851 32.5247H155.452C162.951 32.5247 167.277 28.8971 168.408 21.7089C168.918 18.5641 168.428 16.0931 166.955 14.3621C165.339 12.4624 162.472 11.4568 158.663 11.4568ZM159.976 22.1147C159.355 26.1981 156.235 26.1981 153.216 26.1981H151.5L152.707 18.5718C152.778 18.1113 153.174 17.7717 153.642 17.7717H154.429C156.483 17.7717 158.423 17.7717 159.424 18.9429C160.022 19.6421 160.204 20.6801 159.976 22.1147ZM192.68 21.9838H187.234C186.766 21.9838 186.368 22.3234 186.298 22.7846L186.057 24.3085L185.674 23.7564C184.495 22.0438 181.865 21.4717 179.239 21.4717C173.216 21.4717 168.074 26.0326 167.072 32.4307C166.553 35.6217 167.29 38.6733 169.101 40.8017C170.765 42.7583 173.139 43.5738 175.966 43.5738C180.818 43.5738 183.509 40.4536 183.509 40.4536L183.266 41.9683C183.175 42.5473 183.621 43.0663 184.205 43.0663H189.112C189.889 43.0663 190.552 42.5003 190.673 41.7303L193.62 23.0788C193.71 22.5051 193.265 21.9838 192.68 21.9838ZM185.084 32.5901C184.561 35.7033 182.086 37.7932 178.934 37.7932C177.355 37.7932 176.087 37.2857 175.275 36.324C174.469 35.3691 174.167 34.0093 174.42 32.4954C174.914 29.4084 177.423 27.2507 180.527 27.2507C182.075 27.2507 183.333 27.7643 184.162 28.7346C184.995 29.7148 185.325 31.0824 185.084 32.5901Z" fill="#179BD7" />
  </svg>
)

export const StripeLogo = () => (
  <svg viewBox="0 0 152 63" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M82.0435 8.52446L71.4775 10.792V2.2256L82.0435 0V8.52446Z" fill="#635BFF" />
    <path d="M39.1056 4.87065L28.8343 7.054L28.7922 40.6861C28.7922 46.9002 33.4648 51.4769 39.695 51.4769C43.1468 51.4769 45.6725 50.847 47.0617 50.0913V41.5678C45.7146 42.1136 39.0635 44.0451 39.0635 37.8309V22.9253H47.0617V13.982H39.0635L39.1056 4.87065Z" fill="#635BFF" />
    <path fillRule="evenodd" clipRule="evenodd" d="M151.543 32.498C151.543 21.7492 146.323 13.2677 136.346 13.2677C126.328 13.2677 120.266 21.7492 120.266 32.414C120.266 45.0523 127.422 51.4344 137.693 51.4344C142.703 51.4344 146.491 50.3007 149.354 48.7052V40.3077C146.491 41.7353 143.208 42.617 139.04 42.617C134.957 42.617 131.337 41.1894 130.874 36.2349H151.459C151.459 36.0035 151.474 35.4778 151.491 34.8721L151.491 34.8707L151.491 34.8693L151.491 34.8682C151.515 34.046 151.543 33.0776 151.543 32.498ZM130.747 28.5097C130.747 23.7645 133.652 21.7909 136.304 21.7909C138.872 21.7909 141.608 23.7645 141.608 28.5097H130.747Z" fill="#635BFF" />
    <path d="M14.2283 22.3797C11.9972 22.3797 10.6501 23.0095 10.6501 24.6471C10.6501 26.435 12.9684 27.2215 15.8446 28.1972C20.5333 29.7878 26.7045 31.8814 26.7306 39.6366C26.7306 47.1524 20.711 51.4771 11.9551 51.4771C8.3349 51.4771 4.37793 50.7634 0.46305 49.0839V39.0908C3.99907 41.0222 8.46118 42.4498 11.9551 42.4498C14.3124 42.4498 15.9963 41.82 15.9963 39.8886C15.9963 37.9081 13.4832 37.0029 10.4492 35.91C5.82866 34.2457 0 32.1462 0 25.1509C0 17.7191 5.68288 13.2684 14.2283 13.2684C17.7222 13.2684 21.174 13.8143 24.6679 15.1998V25.0669C21.4687 23.3454 17.4275 22.3797 14.2283 22.3797Z" fill="#635BFF" />
    <path d="M60.1542 17.0888L59.4807 13.9817H50.3881V50.7209H60.9119V25.8222C63.3955 22.5892 67.6051 23.177 68.91 23.6389V13.9817C67.563 13.4779 62.6378 12.5541 60.1542 17.0888Z" fill="#635BFF" />
    <path d="M71.4768 13.9823H82.0428V50.7214H71.4768V13.9823Z" fill="#635BFF" />
    <path fillRule="evenodd" clipRule="evenodd" d="M95.7656 16.5427C97.239 15.1991 99.891 13.2677 104.016 13.2677C111.383 13.2677 118.329 19.9017 118.371 32.0782C118.371 45.3882 111.509 51.4345 103.974 51.4345C100.27 51.4345 98.0388 49.8809 96.5234 48.7892L96.4813 60.6717L85.9574 62.8971V13.9395H95.2184L95.7656 16.5427ZM96.5236 40.2257C97.5339 41.3175 99.0073 42.1993 101.491 42.1993C105.364 42.1993 107.974 38.0001 107.974 32.3731C107.974 26.8721 105.322 22.5889 101.491 22.5889C99.0915 22.5889 97.576 23.4287 96.4816 24.6465L96.5236 40.2257Z" fill="#635BFF" />
  </svg>
)

export const VisaLogo = () => (
  <svg viewBox="0 0 147 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M72.7284 1.16291L63.0265 46.5142H51.2951L60.9983 1.16291H72.7284ZM122.087 30.4464L128.264 13.4154L131.818 30.4464H122.087ZM135.177 46.5142H146.028L136.559 1.16291H126.543C124.292 1.16291 122.393 2.47199 121.548 4.49016L103.947 46.5142H116.267L118.713 39.742H133.764L135.177 46.5142ZM104.558 31.7067C104.608 19.7369 88.0052 19.0781 88.1201 13.7312C88.1545 12.1021 89.7062 10.3738 93.0952 9.9303C94.7789 9.71069 99.408 9.54274 104.664 11.9614L106.723 2.34424C103.899 1.31937 100.266 0.333252 95.7478 0.333252C84.1526 0.333252 75.991 6.49827 75.9221 15.326C75.8489 21.8541 81.7484 25.4972 86.1938 27.6675C90.7641 29.8909 92.2999 31.3148 92.2798 33.3028C92.2497 36.3473 88.6339 37.688 85.2608 37.7411C79.3613 37.8344 75.9379 36.1492 73.2092 34.8775L71.0834 44.8147C73.8236 46.0735 78.8833 47.1673 84.1326 47.2233C96.4554 47.2233 104.518 41.1343 104.558 31.7067ZM55.9644 1.16291L36.9569 46.5142H24.5537L15.2007 10.3207C14.6323 8.09156 14.1385 7.27626 12.4117 6.33607C9.59403 4.80738 4.93618 3.37055 0.838135 2.4806L1.11804 1.16291H21.08C23.6236 1.16291 25.913 2.85668 26.49 5.78632L31.4307 32.0311L43.6387 1.16291H55.9644Z" fill="#1434CB" />
  </svg>
)

export const ApplePay = () => (
  <svg viewBox="0 0 118 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.2761 6.44802C23.6638 4.71232 24.6055 2.38178 24.3571 0.000244141C22.3257 0.101253 19.8468 1.34042 18.4116 3.07749C17.123 4.56501 15.9825 6.99313 16.2798 9.27483C18.5602 9.47263 20.8384 8.13502 22.2761 6.44802Z" fill="currentColor" />
    <path d="M24.3313 9.72037C21.0197 9.52311 18.204 11.5999 16.6226 11.5999C15.0403 11.5999 12.6185 9.81977 9.99923 9.86775C6.59005 9.91782 3.42673 11.8454 1.69672 14.9112C-1.86162 21.0442 0.757677 30.1416 4.21798 35.1366C5.89837 37.6078 7.92354 40.3287 10.592 40.231C13.1133 40.132 14.1015 38.5984 17.1662 38.5984C20.2286 38.5984 21.1188 40.231 23.7877 40.1815C26.5554 40.132 28.2857 37.7091 29.9661 35.2355C31.8939 32.4185 32.683 29.6984 32.7326 29.5491C32.683 29.4997 27.3957 27.4713 27.3467 21.3893C27.2967 16.2967 31.4978 13.8744 31.6955 13.7241C29.3231 10.2154 25.6164 9.81977 24.3313 9.72037Z" fill="currentColor" />
    <path d="M53.166 2.82838C60.3637 2.82838 65.3757 7.78982 65.3757 15.0133C65.3757 22.2626 60.2606 27.2498 52.9855 27.2498H45.0163V39.9232H39.2585V2.82837L53.166 2.82838ZM45.0163 22.4168H51.6229C56.6359 22.4168 59.489 19.7179 59.489 15.0391C59.489 10.3608 56.6359 7.68718 51.6487 7.68718H45.0163V22.4168Z" fill="currentColor" />
    <path d="M66.88 32.237C66.88 27.5066 70.5046 24.6019 76.9318 24.2419L84.3348 23.8051V21.723C84.3348 18.7152 82.3038 16.9158 78.9112 16.9158C75.6971 16.9158 73.6919 18.4579 73.204 20.8746H67.9599C68.2684 15.99 72.4325 12.3912 79.1165 12.3912C85.6715 12.3912 89.8614 15.8616 89.8614 21.2857V39.9231H84.54V35.4758H84.4121C82.8442 38.4836 79.4249 40.3857 75.8776 40.3857C70.582 40.3857 66.88 37.0953 66.88 32.237ZM84.3348 29.7949V27.6613L77.6765 28.0724C74.3603 28.3039 72.484 29.7691 72.484 32.0828C72.484 34.4475 74.4377 35.99 77.4197 35.99C81.3012 35.99 84.3348 33.3164 84.3348 29.7949Z" fill="currentColor" />
    <path d="M94.8854 49.8717V45.3729C95.2959 45.4756 96.2212 45.4756 96.6843 45.4756C99.2548 45.4756 100.643 44.3961 101.491 41.6199C101.491 41.5683 101.98 39.9746 101.98 39.9489L92.2118 12.8796H98.2264L105.065 34.8848H105.167L112.006 12.8796H117.867L107.738 41.3367C105.425 47.8923 102.751 50.0002 97.1474 50.0002C96.6843 50.0002 95.2959 49.9486 94.8854 49.8717Z" fill="currentColor" />
  </svg>
)
