import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Spinner from '@fnd/components/Spinner'
import PlaylistQueueTable from './PlaylistQueueTable'
import { CURATOR_SUBMISSIONS_PER_PAGE, QUERIES } from '@fnd/constants'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useQueueStore } from '@fnd/store'
import { toastFeedback } from '@fnd/core/libs/toast'
import { useInView } from 'react-intersection-observer'
import PlaylistQueueFilters from './PlaylistQueueFilters'


function PlaylistQueue({
  playlistId,
}) {
  const [total, setTotal] = useState(0)
  const [query, setQuery] = useState({
    handled_by: 'all',
    status: 'all',
    archived: false,
  })

  const {
    success,
    error,
    loadingTrackId,
    resetFeedback,
    scoreOffsetValue,
  } = useQueueStore()

  const intl = useIntl()

  const queryClient = useQueryClient()
  const queryKey = `${QUERIES.PLAYLIST.QUEUE}.${playlistId}`

  const {
    data,
    isFetching,
    isRefetching,
    isFetchingNextPage,
    isLoading: queueLoading,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery({
    queryKey: [queryKey],
    queryFn: async (params) => {
      const res = await spotimatchEndpoints.getPlaylistQueue({
        ...params,
        limit: CURATOR_SUBMISSIONS_PER_PAGE,
        playlistId,
        ...query,
      })

      setTotal(res.total)
      return res
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage?.next_page) return
      return lastPage.next_page
    },
    enabled: !!playlistId
  })

  const { ref, inView } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: [`${QUERIES.PLAYLIST.QUEUE}.${playlistId}`] })
  }, [playlistId, query])

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage, hasNextPage])

  useEffect(() => {
    if (success) {
      toastFeedback(
        'success',
        intl.formatMessage({ id: success }, { offset: scoreOffsetValue })
      )
    } else if (error) {
      toastFeedback('error', intl.formatMessage({ id: error }))
    }
    resetFeedback()
  }, [success, error])

  const submissions = data?.pages?.map((page) => page.data).flat() || []

  return (
    <>
      <PlaylistQueueFilters
        query={query}
        setQuery={setQuery}
      />

      <PlaylistQueueTable
        isLoading={queueLoading || isFetching}
        isRefetching={isRefetching}
        playlistId={playlistId}
        queue={submissions}
        loadingTrackId={loadingTrackId}
      />

      {(queueLoading || isFetchingNextPage) && <Spinner />}

      <div ref={ref} />
    </>
  )
}

export default PlaylistQueue
