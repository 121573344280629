import Auth from '@fnd/components/Auth'
import { ROUTES } from '@fnd/screens/constants'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '@fnd/components/Button'


const PlanActionVip = ({
  color,
  disabled,
}) => {
  const getButtonTitle = () => {
    if (disabled) {
      return <FormattedMessage id="plans.card.button.login_to_start" />
    }

    return <FormattedMessage id="plans.card.button.calculate_price" />
  }

  return (
    <Auth
      login
      loginLabel={
        <FormattedMessage id="plans.card.button.login_to_start" />
      }
      redirect={ROUTES.VIP}
    >
      <Button
        variant={color}
        className="w-full"
        to={ROUTES.VIP}
        disabled={disabled}
      >
        {getButtonTitle()}
      </Button>
    </Auth>
  )
}

export default PlanActionVip
