import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Select from 'react-select'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import Button from '@fnd/components/Button'
import { toastFeedback } from '@fnd/core/libs/toast'
import { AVAILABLE_LANGUAGES } from '@fnd/screens/constants'
import Toggle from 'react-toggle'
import ConfirmationPopup from '@fnd/components/ConfirmationPopup'

function AccountSettings({
  settings,
  notifications = true,
  web_notifications = true,
  updateUserNotifications,
}) {
  const intl = useIntl()
  const [confirmationPopupState, setConfirmationPopupState] = useState({
    web: false,
    email: false,
  })

  const toggleConfirmationPopup = (type) => () => setConfirmationPopupState({ [type]: !confirmationPopupState[type] })

  const [selectedPreferredLanguage, setSelectedPreferredLanguage] = useState(
    settings.preferred_language
  )
  const [selectedDigestFrequency, setSelectedDigestFrequency] = useState(
    settings.digest_frequency
  )

  const preferredLanguageChangeHandler = (option, { action }) => {
    if (action !== 'select-option') {
      return
    }

    setSelectedPreferredLanguage(option.value)
  }

  const digestFrequencyChangeHandler = (option, { action }) => {
    if (action !== 'select-option') {
      return
    }

    setSelectedDigestFrequency(option.value)
  }

  const updateUserSettings = async () => {
    try {
      await spotimatchEndpoints
        .updateUserSettings({
          preferred_language: selectedPreferredLanguage,
          digest_frequency: selectedDigestFrequency,
        })
        .toPromise()

      toastFeedback(
        'success',
        intl.formatMessage({ id: 'feedback.success.user_settings_update' })
      )
    } catch (error) {
      console.log(error)
      toastFeedback(
        'error',
        intl.formatMessage({ id: 'feedback.error.default' })
      )
    }
  }

  const handleUpdateNotifications = (type, status) => {
    spotimatchEndpoints
      .toggleUserNotifications(type, status)
      .toPromise()
      .then(() => updateUserNotifications(type, status))
      .catch(console.log)
  }


  const availableLanguages = AVAILABLE_LANGUAGES.map(({ label, value }) => ({
    label: intl.formatMessage({ id: label }),
    value,
  }))

  const digestFrequencyOptions = [
    {
      label: intl.formatMessage({
        id: 'profile.account.settings.digest_frequency.daily',
      }),
      value: 'daily',
    },
    {
      label: intl.formatMessage({
        id: 'profile.account.settings.digest_frequency.weekly',
      }),
      value: 'weekly',
    },
    {
      label: intl.formatMessage({
        id: 'profile.account.settings.digest_frequency.monthly',
      }),
      value: 'monthly',
    },
  ]

  return (
    <div className="my-6 account-section">
      <h5>
        <FormattedMessage id="profile.account.settings" />
      </h5>

      <div className="mt-4 space-y-5">
        <div className="overview-button-bar mt-4">
          <div className="space-y-2 mb-4">
            <div className="flex items-center gap-3">
              <label className="mb-0" htmlFor="settings_preferred_language">
                <FormattedMessage id="profile.account.settings.email_notifications" />
              </label>
              <Toggle
                className=""
                checked={notifications}
                onChange={
                  notifications
                    ? toggleConfirmationPopup("email")
                    : () => handleUpdateNotifications("email", !notifications)
                }
              />
            </div>
            <p className="text-sm opacity-80 mb-3">
              <FormattedMessage id="profile.account.settings.email_notifications.help" />
            </p>
          </div>

          <div className="space-y-2">
            <div className="flex items-center gap-3">
              <label className="mb-0" htmlFor="settings_preferred_language">
                <FormattedMessage id="profile.account.settings.web_notifications" />
              </label>
              <Toggle
                className=""
                checked={web_notifications}
                onChange={
                  web_notifications
                    ? toggleConfirmationPopup("web")
                    : () => handleUpdateNotifications("web", !web_notifications)
                }
              />
            </div>
            <p className="text-sm opacity-80 mb-3">
              <FormattedMessage id="profile.account.settings.web_notifications.help" />
            </p>


            <ConfirmationPopup
              isOpen={confirmationPopupState.email}
              onConfirm={() =>
                handleUpdateNotifications("email", !notifications)
              }
              bodyClasses="p-5"
              onReject={toggleConfirmationPopup("email")}
              text={<FormattedMessage id="popup.email_notifications" />}
            />

            <ConfirmationPopup
              isOpen={confirmationPopupState.web}
              onConfirm={() =>
                handleUpdateNotifications("web", !web_notifications)
              }
              bodyClasses="p-5"
              onReject={toggleConfirmationPopup("web")}
              text={<FormattedMessage id="popup.web_notifications" />}
            />
          </div>
        </div>
        <div>
          <label htmlFor="settings_preferred_language">
            <FormattedMessage id="profile.account.settings.preferred_language" />
          </label>

          <p className="text-sm opacity-80 mb-3">
            <FormattedMessage id="profile.account.settings.preferred_language.help" />
          </p>

          <Select
            id="settings_preferred_language"
            name="preferred_language"
            onChange={preferredLanguageChangeHandler}
            options={availableLanguages}
            value={availableLanguages.find(
              ({ value }) => value === selectedPreferredLanguage
            )}
            className="select"
            classNamePrefix="select"
            isSearchable={false}
          />
        </div>
        <div>
          <label htmlFor="settings_digest_frequency">
            <FormattedMessage id="profile.account.settings.digest_frequency" />
          </label>

          <p className="text-sm opacity-80 mb-3">
            <FormattedMessage id="profile.account.settings.digest_frequency.help" />
          </p>

          <Select
            id="settings_digest_frequency"
            name="digest_frequency"
            onChange={digestFrequencyChangeHandler}
            options={digestFrequencyOptions}
            value={digestFrequencyOptions.find(
              ({ value }) => value === selectedDigestFrequency
            )}
            className="select"
            classNamePrefix="select"
            isSearchable={false}
          />
        </div>
        <Button
          className="w-full md:w-auto"
          variant="primary"
          onClick={updateUserSettings}
          label={<FormattedMessage id="profile.account.settings.save" />}
        />
      </div>
    </div>
  )
}

export default AccountSettings
