import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Badge from '@fnd/components/Badge'
import Icon from '@fnd/components/Icon'
import TrackPlayer from '@fnd/components/Track'
import { TrackStatus } from './TrackStatus'
import PlaylistQueueActions from './PlaylistQueueActions'
import SubmitterInfo from './SubmitterInfo'
import { nFormatter } from '@fnd/core/libs/helpers'


function PlaylistQueueRow({
  playlistId,
  isVipSubmission,
  track,
  submitter,
  status,
  matchRate,
  handled_by,
  archived,
}) {
  const intl = useIntl()

  return (
    <tr key={track.id}>
      <td>
        <div className="flex items-start gap-3">
          <TrackPlayer
            cover={track?.album?.images[0]?.url}
            audioSrc={track?.preview_url ? track?.preview_url : null}
          />

          <div className="flex flex-col">
            <div className='flex gap-2'>
              <a
                href={track.external_urls.spotify}
                target="_blank"
                className="block font-medium"
                rel="noopener noreferrer"
              >
                {track.name}
              </a>

              {isVipSubmission && (
                <Badge variant="gold-light" icon="gem" className="gap-1">
                  <FormattedMessage id="profile.my_playlists.settings_popup.submissions.queue_table.vip_submission" />
                </Badge>
              )}
            </div>

            <div className="hidden md:flex gap-x-1 flex-wrap">
              {track.artists.map(owner => (
                <a key={owner.id} href={owner.external_urls.spotify} target="_blank" rel="noopener noreferrer">
                  <Badge
                    key={owner.id}
                    variant="light"
                    className="mt-1"
                    label={owner.name}
                    truncateLength={16}
                    values={[
                      {
                        icon: 'star',
                        value: `${owner.popularity}%`
                      },
                      {
                        icon: 'users',
                        value: nFormatter(owner.followers.total)
                      }
                    ]}
                  />
                </a>
              ))}
            </div>

            <span className="block md:hidden mt-1">
              <a href={track.artists[0].external_urls.spotify} target="_blank" rel="noopener noreferrer">
                {track?.artists[0]?.name}
              </a>
            </span>

            <span className="block md:hidden mt-1">
              <TrackStatus
                track={track}
                status={status}
              />
            </span>
          </div>
        </div>
      </td>

      <td className="hidden md:table-cell">
        <SubmitterInfo submitter={submitter} />
      </td>

      <td className="text-center hidden md:table-cell">
        {matchRate ? `${matchRate}%` : '-'}
      </td>

      <td className="text-center hidden md:table-cell">
        {
          handled_by === 'auto' ?
            <Badge
              variant="light"
              icon="sparkles"
              label={intl.formatMessage({ id: 'profile.my_playlists.settings_popup.submissions.queue_table.handled_by.auto' })}
            />
            :
            <Badge
              variant="light"
              icon="user"
              label={intl.formatMessage({ id: 'profile.my_playlists.settings_popup.submissions.queue_table.handled_by.user' })}
            />
        }
      </td>

      <td className="text-center hidden md:table-cell">
        <TrackStatus
          track={track}
          status={status}
        />
      </td>

      {!archived && (
        <td className="text-right">
          <PlaylistQueueActions
            handled_by={handled_by}
            archived={archived}
            playlistId={playlistId}
            track={track}
            submitter={submitter}
            status={status}
          />
        </td>
      )}
    </tr>
  )

}

export default PlaylistQueueRow
