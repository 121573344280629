import { from, throwError, timer } from 'rxjs'
import { map, mergeMap, retryWhen, tap } from 'rxjs/operators'

import { chunk, isEmpty } from 'lodash-es'

import { API_URL, EVENTS, SPOTIFY_API_URL } from '@fnd/constants'
import { request, requestLogger, serialize } from '@fnd/core/libs'
import eventEmitter from '@fnd/core/libs/eventEmitter'

import { spotimatchEndpoints } from '@fnd/core/spotimatch'

const make = request({
  uri: SPOTIFY_API_URL,
  middleware: [
    retryWhen((errors) => {
      let maxRetries = 3
      return errors.pipe(
        tap(({ response }) => requestLogger(response)),

        mergeMap((error) => {
          if (error.response && error.response.status === 429) {
            if (maxRetries === 0) {
              eventEmitter.emit(EVENTS.ERROR_429)
              return throwError(error)
            }
            const delay = error.response.headers['retry-after']
            maxRetries--
            return timer(delay * 1000)
          }

          if (error?.response?.data?.error) {
            return throwError(error.response.data.error)
          }

          return throwError(error)
        })
      )
    }
    ),

    map((response) => {
      return response.data
    }),
  ],
})


async function getAllEntities(url, limit = 50, offset = 0, items = []) {
  const result = await make
    .get(url, {
      query: {
        limit,
        offset,
      },
    })
    .toPromise()

  if (typeof result === 'string' || !result)
    throw new Error('Something went wrong, can`t get playlist`s tracks!')

  const totalItemsFetched = [...items, ...result.items]

  if (result.next) {
    return getAllEntities(url, limit, offset + limit, totalItemsFetched)
  }

  result.items = totalItemsFetched
  return result
}

function getPlaylistTracks(playlistId, limit = 100, offset = 0) {
  return getAllEntities(`/playlists/${playlistId}/tracks`, limit, offset)
}


export const generateSpotifySignLink = (redirectUri, type) => {
  return `${API_URL}/auth/login${serialize({
    redirect_uri: redirectUri,
    type,
  })}`
}


export function getPlaylistsByTerm(term, offset = 0) {
  return make.get('/search', {
    query: {
      q: term,
      type: 'playlist',
      limit: 20,
      offset,
    },
  })
}

export function searchItem(query, type) {
  return make.get('/search', {
    query: {
      q: query,
      type: type,
      limit: 40,
    },
  }).toPromise()
}

export function getPlaylist(playlistId) {
  return make.get(`playlists/${playlistId}`)
}

export function getPlaylistById(playlistId) {
  return make.get(`playlists/${playlistId}`).toPromise()
}

export function followPlaylist(playlistId) {
  return make.put(`playlists/${playlistId}/followers`).toPromise()
}

export function followArtists(artistIds) {
  return make.put('/me/following',
    { query: { type: 'artist', ids: artistIds } },
  ).toPromise()
}

export function unfollowArtists(artistIds) {
  return make.delete('/me/following',
    { query: { type: 'artist', ids: artistIds } },
  ).toPromise()
}

export function getPlaylistWithTracks(playlistId) {
  const promises = []
  promises.push(getPlaylistTracks(playlistId))
  promises.push(getPlaylist(playlistId).toPromise())
  return from(
    Promise.all(promises)
      .then((res) => {
        res[1].tracks.items = res[0].items
        return res[1]
      })
      .catch(async (error) => {
        console.log(error)
        if (error.status === 404) {
          await spotimatchEndpoints.deleteMissingPlaylist(playlistId).toPromise()
        }
      })
  )
}

export async function getAlbumById(albumId) {
  return make.get(`/albums/${albumId}`).toPromise()
}

export async function getArtist(artistId) {
  return make.get(`/artists/${artistId}`).toPromise()
}

export async function getArtists(artistIds) {
  return make.get('/artists', { query: { ids: artistIds.join(',') } }).toPromise()
}

export async function getArtistById(artistId) {
  return make.get(`/artists/${artistId}`).toPromise()
}

export function getTrack(trackId) {
  return make.get(`/tracks/${trackId}`)
}

export function getTrackById(trackId) {
  return make.get(`/tracks/${trackId}`).toPromise()
}

export function getTrackFeatures(trackId) {
  return make.get(`/audio-features/${trackId}`)
}

export function getShowById(showId) {
  return make.get(`/shows/${showId}`).toPromise()
}

export function getSelfProfile() {
  return make.get('/me')
}

export function getUserProfile(userId) {
  return make.get(`/users/${userId}`)
}

export function getUserPlaylists(userId) {
  return make.get(`/users/${userId}/playlists`)
}

async function getSeveralArtists(artistIds) {
  if (!Array.isArray(artistIds) || !artistIds)
    throw new Error('Something went wrong, can`t get several artists!')
  return await make.get('/artists', { query: { ids: artistIds } }).toPromise()
}

export async function getArtistsBulk(artistIds) {
  if (!artistIds || !Array.isArray(artistIds))
    throw new Error('Something went wrong, can`t get several artists!')
  if (isEmpty(artistIds)) return { artists: [] }

  if (artistIds.length <= 50) return await getSeveralArtists(artistIds)

  const promises = []
  const chunksArtistIds = chunk(artistIds, 50)
  chunksArtistIds.forEach((chunkArtistIds) =>
    promises.push(getSeveralArtists(chunkArtistIds))
  )

  const chunksArtists = await Promise.all(promises)

  return chunksArtists.reduce((acc, { artists }) => {
    return { artists: [...artists, ...acc.artists] }
  })
}

export function removeTracksFromPlaylist(playlistId, tracksId) {
  const urn = `/playlists/${playlistId}/tracks`

  const tracks = tracksId.map((trackId) => ({
    uri: `spotify:track:${trackId}`,
  }))
  const body = { tracks }
  return make.delete(urn, { body })
}

export function addTracksToPlaylist(playlistId, tracksId) {
  const urn = `/playlists/${playlistId}/tracks`

  const uris = tracksId.map((trackId) => `spotify:track:${trackId}`)
  const body = { uris }

  return make.post(urn, { body })
}

export function getAllMyPlaylists() {
  return getAllEntities('/me/playlists')
}

export function createPlaylist(userId, playlistData) {
  return make.post(`/users/${userId}/playlists`, { body: playlistData })
}

export function updatePlaylistDetails(playlistId, playlistData) {
  return make.put(`/playlists/${playlistId}`, { body: playlistData })
}

export function reorderTracksInPlaylist(
  playlistId,
  insertBefore,
  rangeStart,
  rangeLength = 1
) {
  return make.put(`/playlists/${playlistId}/tracks`, {
    body: {
      insert_before: insertBefore,
      range_start: rangeStart,
      range_length: rangeLength,
    },
  })
}

export function spotifyPlayerStart(trackId, deviceId) {
  const spotifyUri = `spotify:track:${trackId}`
  return make.put(
    `https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`,
    {
      body: JSON.stringify({ uris: [spotifyUri] }),
    }
  )
}

export function getPlaybackState() {
  return make.get('/me/player')
}

export function getCurrentlyPlaying() {
  return make.get('/me/player/currently-playing')
}

export function getRecentlyPlayed() {
  return make.get('/me/player/recently-played')
}

export function startTrack(trackId) {
  return make.put('/me/player/play', {
    body: {
      uris: [`spotify:track:${trackId}`],
    },
  })
}

export function pauseTrack() {
  return make.put('/me/player/pause')
}

export default {
  addTracksToPlaylist,
  createPlaylist,
  followArtists,
  getAllMyPlaylists,
  getAlbumById,
  getArtist,
  getArtists,
  getArtistsBulk,
  getCurrentlyPlaying,
  getPlaybackState,
  getPlaylist,
  getPlaylistById,
  getPlaylistsByTerm,
  getPlaylistTracks,
  getPlaylistWithTracks,
  getRecentlyPlayed,
  getSelfProfile,
  getTrack,
  getTrackFeatures,
  getUserPlaylists,
  getUserProfile,
  pauseTrack,
  removeTracksFromPlaylist,
  reorderTracksInPlaylist,
  searchItem,
  spotifyPlayerStart,
  startTrack,
  unfollowArtists,
  updatePlaylistDetails
}
