import { make } from './endpoints'


export const getAcceleratesThisMonth = () =>
  make.get('/accelerate/requested/this/month')

export const createAccelerate = (payload) =>
  make.post('/accelerate/create', { body: payload })

export const getMyAccelerates = () => make.get('/accelerate/requested')


export default {
  getAcceleratesThisMonth,
  createAccelerate,
  getMyAccelerates,
}
