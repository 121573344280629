import React, { useState, useCallback } from 'react'
import Modal from 'react-modal'
import cx from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '@fnd/components/Button'
import Spinner from '@fnd/components/Spinner'
import { ModalHeader } from '@fnd/components/Modal'
import { usePlaylistStore } from '@fnd/store'

const initialState = {
  name: {
    touched: false,
    value: '',
    valid: false,
  },
  description: {
    touched: false,
    value: '',
    valid: true,
  },
  public: {
    value: true,
    valid: true,
    touched: false,
  },
  collaborative: {
    value: false,
    valid: true,
    touched: false,
  },
}

export default function PlaylistCreatePopup({ onClose, userId }) {
  const { createUserPlaylist } = usePlaylistStore()
  const [createState, setCreateState] = useState(initialState)
  const [isFormLoading, setIsFormLoading] = useState(false)
  const updateField = useCallback(
    (field) => (key, value) =>
      setCreateState((currentState) => ({
        ...currentState,
        [key]: { ...currentState[key], [field]: value },
      })),
    []
  )
  const updateTouched = useCallback(updateField('touched'), [])
  const updateValue = useCallback(updateField('value'), [])
  const updateValid = useCallback(updateField('valid'), [])
  const isFormValid = Object.values(createState).every(({ valid }) => valid)
  const isSubmitButtonDisabled = !isFormValid || isFormLoading

  const changeHandler = ({ target }) => {
    const valueField = target.type === 'checkbox' ? 'checked' : 'value'
    updateValue(target.name, target[valueField])
    if (['public', 'collaborative'].includes(target.name)) {
      updateValue(
        target.name === 'collaborative' ? 'public' : 'collaborative',
        false
      )
    }
  }

  const focusHandler = ({ target }) => {
    updateTouched(target.name, false)
  }

  const blurHandler = ({ target }) => {
    updateValid(target.name, !!target.value)
    updateTouched(target.name, true)
  }

  const submitHandler = async () => {
    if (isSubmitButtonDisabled) {
      return Object.keys(createState).forEach((key) => updateTouched(key, true))
    }

    setIsFormLoading(true)
    const data = Object.entries(createState).reduce((acc, [key, input]) => {
      acc[key] = input.value
      return acc
    }, {})

    createUserPlaylist(userId, data)
    setIsFormLoading(false)
    onClose(true)
    return data
  }

  const getInputClasses = (inputName) =>
    cx({
      invalid: !createState[inputName].valid && createState[inputName].touched,
    })

  const intl = useIntl()

  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      overlayClassName="modal show"
      className="modal-dialog modal-center modal-sm"
    >
      <div className="modal-content">
        <ModalHeader
          title={intl.formatMessage({ id: "playlist.create" })}
          onClose={onClose}
        />
        <div className="modal-body p-5">
          <form className="form">
            <div className="form-group">
              <label
                className="field playlist-create-label"
                htmlFor="create-playlist-name"
              >
                <FormattedMessage id="playlist.create.name" />
              </label>
              <input
                onBlur={blurHandler}
                onFocus={focusHandler}
                onChange={changeHandler}
                value={createState.name.value}
                className={getInputClasses('name')}
                type="text"
                name="name"
                id="create-playlist-name"
                required
              />
            </div>
            <div className="flex">
              <label className="playlist-create-label mr-5">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={createState.public.value}
                  onChange={changeHandler}
                  name="public"
                />
                <FormattedMessage id="playlist.create.public" />
              </label>
              <label className="playlist-create-label">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={createState.collaborative.value}
                  onChange={changeHandler}
                  name="collaborative"
                />
                <FormattedMessage id="playlist.create.collaborative" />
              </label>
            </div>
            <div className="form-group">
              <label
                className="field playlist-create-label"
                htmlFor="create-playlist-description"
              >
                <FormattedMessage id="playlist.create.description" />
              </label>
              <textarea
                onFocus={focusHandler}
                onChange={changeHandler}
                onBlur={blurHandler}
                value={createState.description.value}
                className={getInputClasses('description')}
                type="text"
                name="description"
                id="create-playlist-description"
                required
              />
            </div>
            <Button className="w-full mt-4" disabled={isSubmitButtonDisabled} onClick={submitHandler}>
              {isFormLoading ? <Spinner inline /> : intl.formatMessage({ id: "playlist.create" })}
            </Button>
          </form>
        </div>
      </div>
    </Modal>
  )
}
