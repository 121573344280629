import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import {
  isUserAuthorizedSelector,
  userProfileSelector,
  useUserContext,
} from '@fnd/modules/User'
import Button from '@fnd/components/Button'
import PaymentPopup from './PaymentPopup'
import SignButton from '../Sign/SignButton'
import { EVENTS, trackEvent } from '@fnd/core/libs/event-tracker'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'

const Payment = React.memo(
  function Payment({
    addons,
    autoApply,
    color,
    contract,
    price,
    icon,
    save,
    plan,
    title,
    payload,
    creditsDiscount,
    disabledLabel,
    defaultOpen,
    disabledDowngrade,
    disabled,
    disableCredits,
    disableAffiliate,
    disableCoupon,
    isActive,
    label,
    maxCredits,
    monthly,
    mode,
    onBeforePayment,
    children,
    className,
  }) {
    const { profile, isAuthorized, updateReferral, updateUserBillingInfo } =
      useUserContext(({ user, updateUserProfile }) => ({
        profile: userProfileSelector(user),
        isAuthorized: isUserAuthorizedSelector(user),
        updateReferral: (next) =>
          updateUserProfile({ referral: { $set: next } }),
        updateUserBillingInfo: (next) =>
          updateUserProfile({ billingInfo: { $set: next } }),
      }))

    const [isPaymentPopupOpen, setPaymentPopupOpen] = useState(defaultOpen)

    const openPaymentPopup = async () => {
      if (onBeforePayment) {
        await onBeforePayment()
      }

      spotimatchEndpoints.createCart({ plan })
      setPaymentPopupOpen(true)

      trackEvent(EVENTS.INITIATE_CHECKOUT, {
        slug: plan,
        title,
        price,
      })
    }

    const closePaymentPopup = () => {
      setPaymentPopupOpen(false)
    }

    const intl = useIntl()

    const getStatus = () => {
      let btnLabel = intl.formatMessage({ id: 'payment.purchase' })
      let btnIcon = icon

      if (isActive) {
        btnLabel = intl.formatMessage({ id: 'plans.card.button.active' })
        btnIcon = 'check'
      } else if (disabledDowngrade) {
        btnLabel = intl.formatMessage({ id: 'plans.card.button.better_plan' })
        btnIcon = 'times'
      } else if (label) {
        return { label, icon: btnIcon }
      } else if (price && save) {
        btnLabel = `${intl.formatMessage({ id: 'plans.card.button.upgrade' })}`
      } else if (price) {
        btnLabel = `${intl.formatMessage({ id: 'plans.card.button.upgrade' })}`
      } else if (disabled) {
        btnLabel = disabledLabel
      } else if (!isAuthorized) {
        btnLabel = intl.formatMessage({
          id: 'plans.card.button.login_to_start',
        })
      }

      return {
        label: btnLabel,
        icon: btnIcon,
      }
    }

    const isButtonDisabled = disabledLabel || isActive || disabledDowngrade
    const location = useLocation()

    useEffect(() => {
      if (defaultOpen) {
        openPaymentPopup()
      }
    }, [defaultOpen])

    const classes = classNames({
      'payment-wrapper': true,
      [className]: className,
    })

    return (
      <div className={classes}>
        {!isAuthorized && (
          <SignButton
            className="w-full"
            redirect={location.pathname}
            label={getStatus().label}
            variant={color}
          />
        )}

        {isAuthorized && (
          <Button
            className="w-full"
            variant={color}
            onClick={openPaymentPopup}
            disabled={isButtonDisabled}
            icon={getStatus().icon}
          >
            {getStatus().label}
          </Button>
        )}

        {isPaymentPopupOpen && (
          <PaymentPopup
            addons={addons}
            autoApply={autoApply}
            mode={mode}
            contract={contract}
            price={price}
            monthly={monthly}
            plan={plan}
            disable={disableCredits}
            disableCredits={disableCredits}
            disableAffiliate={disableAffiliate}
            disableCoupon={disableCoupon}
            payload={payload}
            updateReferral={updateReferral}
            userProfile={profile}
            onClose={closePaymentPopup}
            creditsDiscount={creditsDiscount}
            maxCredits={maxCredits}
            updateBillingInfo={updateUserBillingInfo}
          >
            {children}
          </PaymentPopup>
        )}
      </div>
    )
  },
  (prevProps, nextProps) => {
    return (
      prevProps.price === nextProps.price &&
      prevProps.plan === nextProps.plan &&
      prevProps.defaultOpen === nextProps.defaultOpen
    )
  }
)

export default Payment

Payment.defaultProps = {
  color: 'primary',
}
