import React, { useState, useEffect, useRef } from 'react'
import { themes, setThemeVariables } from '@fnd/core/libs/theme'
import { isServer } from '@fnd/core/libs/helpers'

export const ThemeContext = React.createContext()

const ThemeProvider = ({ children }) => {
  const DEFAULT_THEME = 'dark'

  const [currentTheme, setTheme] = useState(DEFAULT_THEME)
  const [audio, setAudio] = useState()
  const [isPlaying, setIsPlaying] = useState(false)
  const audioPlayer = useRef()
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)

  const applyTheme = (themeId) => {
    const theme = themes.find((t) => t.id === themeId)
    if (theme) {
      setThemeVariables(theme)
      if (currentTheme !== theme.id) setTheme(theme.id)
      if (!isServer) window.localStorage.setItem('theme', theme.id)
    }
  }

  const getCurrentTheme = () => {
    return themes.find((theme) => theme.id === currentTheme)
  }

  // get current theme from local storage
  useEffect(() => {
    if (!isServer) {
      const savedTheme = window.localStorage.getItem('theme')
      if (savedTheme) {
        setTheme(savedTheme)
        applyTheme(savedTheme)
      }

      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setTheme('dark')
        applyTheme('dark')
        window.localStorage.setItem('theme', 'dark')
      } else {
        setTheme('light')
        applyTheme('light')
        window.localStorage.setItem('theme', 'light')
      }
    }
  }, [])

  // audio player
  const play = () => setIsPlaying(true)
  const pause = () => setIsPlaying(false)

  const togglePlay = () => {
    if (audioPlayer && audio) {
      if (!isPlaying) {
        audioPlayer.current.pause()
      } else {
        audioPlayer.current.play()
      }
    }
  }

  useEffect(() => {
    togglePlay()
  }, [isPlaying])

  useEffect(() => {
    audioPlayer.current.load()
    audioPlayer.current.addEventListener('loadedmetadata', () => {
      audioPlayer.current.play()
      setDuration(audioPlayer.current.duration)
    })
    audioPlayer.current.addEventListener('timeupdate', () => {
      if (audioPlayer.current?.currentTime) {
        setCurrentTime(audioPlayer.current.currentTime)
      }
    })
    audioPlayer.current.addEventListener('ended', () => {
      setIsPlaying(false)
    })
    setIsPlaying(true)
  }, [audio])

  return (
    <ThemeContext.Provider
      value={{
        audio,
        currentTheme,
        getCurrentTheme,
        setAudio,
        setTheme,
        applyTheme,
        isPlaying,
        setIsPlaying,
        play,
        pause,
        currentTime,
        duration
      }}
    >
      <audio ref={audioPlayer}>
        <source src={audio} type="audio/mp3" />
      </audio>
      <>{children}</>
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
