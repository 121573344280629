import { make } from './endpoints'
import { PER_PAGE } from '@fnd/constants'


export const getMagazines = (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)
  const { genres, trackId } = params
  return make.get(`/magazine`, { query: { limit, offset, genres, trackId } }).toPromise()
}

export const checkMagazineQueue = (magazineId, trackId) =>
  make.get(`/magazine/${magazineId}/queue`, { query: { trackId } }).toPromise()

export const pushMagazineQueue = (magazineId, trackId) =>
  make.post(`/magazine/${magazineId}/queue`, { body: { trackId } }).toPromise()


export default {
  getMagazines,
  checkMagazineQueue,
  pushMagazineQueue,
}
