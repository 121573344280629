import React from 'react'
import Date from '@fnd/components/Date'
import SubmitterInfo from '@fnd/components/PlaylistQueue/SubmitterInfo'
import SubmissionsStatus from './SubmissionsStatus'


function SubmissionsTableItem({
  submitter,
  playlist
}) {
  return (
    <tr>
      <td>
        <a
          href={`https://open.spotify.com/playlist/${playlist?.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {playlist?.name}
        </a>
      </td>

      <td className="text-center">
        <SubmissionsStatus status={playlist?.status} />
      </td>

      <td>
        <SubmitterInfo submitter={playlist?.curator} />
      </td>

      <td>
        <a
          href={submitter?.external_urls?.spotify}
          target="_blank"
          rel="noopener noreferrer"
        >
          {submitter?.display_name}
        </a>
      </td>

      <td>
        {`${playlist?.matchRate > 0 ? `${playlist?.matchRate}%` : '-'}`}
      </td>

      <td>
        <Date
          dateString={playlist?.createdAt}
          includeTime
        />
      </td>
    </tr>
  )
}

export default SubmissionsTableItem
