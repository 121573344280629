import React, { useState } from 'react'
import Icon from '@fnd/components/Icon'

const AccordionItem = ({
  children,
  label,
  icon,
  iconType,
  defaultOpen = false
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)

  const handleAccordionItemClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className="accordion">
      <header
        className="accordion-header"
        onClick={() => handleAccordionItemClick(label)}
      >
        <h5>
          {icon ? <Icon name={icon} type={iconType} /> : ''}
          {label}
        </h5>
        <Icon name={isOpen ? 'angle-up' : 'angle-down'} className="ml-3" />
      </header>
      <div className={`accordion-body ${isOpen ? 'open' : ''}`}>
        {isOpen && children}
      </div>
    </div>
  )
}

export default AccordionItem
