import React, { memo } from 'react'
import Tooltip from 'rc-tooltip'
import Icon from '@fnd/components/Icon'

function Progress({
  title,
  progress = 0,
  icon,
  color,
  label,
  tooltipText,
  customText,
  unit = '%',
}) {
  const progressContent = (
    <div className={`progress progress-${color}`}>
      {title && (
        <div className="progress-label">
          {icon && <Icon name={icon} />}
          {title}
        </div>
      )}

      <div
        className="progress-bar"
        role="progressbar"
        aria-valuenow={progress}
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ width: `${progress}%` }}
      />

      {!customText && <div className="progress-value">{progress}{unit}</div>}

      {customText && <div className="progress-value">{customText}</div>}
    </div>
  )

  return (
    <div className="playlist-progress">
      {label && (
        <label className="text-sm mb-1">
          {label}
        </label>
      )}

      {tooltipText ? (
        <Tooltip
          mouseEnterDelay={0.5}
          mouseLeaveDelay={0}
          placement="top"
          trigger={['hover']}
          overlay={<span>{tooltipText}</span>}
        >
          {progressContent}
        </Tooltip>
      ) : progressContent}
    </div>
  )
}

export default memo(Progress)
