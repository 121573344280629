import { make } from './endpoints'

export const getFeaturedCurators = () =>
  make.get('/open/curators/featured')

export const getRandomCurators = () =>
  make.get('/open/curators/random')

export const getStarCurators = () =>
  make.get('/open/curators/star')

export const getPopularSubmissions = (query) =>
  make.get(`/open/submissions/popular?${query}`).toPromise()

export default {
  getFeaturedCurators,
  getPopularSubmissions,
  getRandomCurators,
  getStarCurators
}
