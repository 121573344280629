import React from "react"
import classNames from "classnames"
import Emoji from '@fnd/components/Emoji'
import { CURATOR_RANGE } from '@fnd/constants'

export default ({ className, curator }) => {
  if (!curator?.rankNormalized) return null
  const { rankNormalized } = curator

  const emojiClasses = classNames({
    'mr-2': true,
    [className]: className,
  })

  if (rankNormalized >= CURATOR_RANGE.HIGHEST) {
    return <Emoji className={emojiClasses} symbol="👑" label="Crown" />
  } else if (rankNormalized >= CURATOR_RANGE.HIGH) {
    return <Emoji className={emojiClasses} symbol="🏆" label="Trophy" />
  } else if (rankNormalized >= CURATOR_RANGE.MEDIUM) {
    return <Emoji className={emojiClasses} symbol="🏅" label="Sports Medal" />
  } else if (rankNormalized >= CURATOR_RANGE.LOW) {
    return <Emoji className={emojiClasses} symbol="🔥" label="Fire" />
  } else if (rankNormalized > CURATOR_RANGE.LOWEST) {
    return <Emoji className={emojiClasses} symbol="😥" label="Sad face" />
  }

  return <Emoji className={emojiClasses} symbol="😥" label="Sad face" />
}