import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Tabs, TabContent, useTabs } from '@fnd/components/Tabs'
import { ROUTES } from '@fnd/screens/constants'
import Account from '@fnd/modules/User/Account/Account'
import BillingInfo from '@fnd/modules/Profile/BillingInfo'
import BlockedUsers from '@fnd/modules/User/BlockedUsers'
import TransactionTable from '@fnd/modules/User/PaymentsHistory/Transactions/TransactionTable'
import useUserContext from '@fnd/modules/User/core/useUserContext'
import {
  isUserAuthorizedSelector,
  userProfileSelector,
  isUserLoadingSelector,
} from '@fnd/modules/User'
import ProfileWrapper from '@fnd/modules/Profile/ProfileWrapper'
import { useSearchParams } from 'react-router-dom'

export default function Settings() {
  const [searchParams] = useSearchParams()

  const { profile, updateUserBillingInfo } = useUserContext(
    ({ user, updateUserProfile }) => ({
      profile: userProfileSelector(user),
      isAuthorized: isUserAuthorizedSelector(user),
      isUserLoading: isUserLoadingSelector(user),
      updateUserBillingInfo: (next) =>
        updateUserProfile({ billingInfo: { $set: next } }),
    })
  )

  const tabsData = {
    tabs: [
      {
        id: ROUTES.MY_PROFILE.BILLING_INFO,
        icon: 'credit-card',
        label: <FormattedMessage id="navigation.billing_info" />,
        children: (
          <BillingInfo
            hideTitle
            userProfile={profile}
            updateUserBillingInfo={updateUserBillingInfo}
            mode="view"
          />
        ),
      },
      {
        id: ROUTES.MY_PROFILE.PAYMENTS,
        icon: 'history',
        label: <FormattedMessage id="navigation.payments_history" />,
        children: (
          <TransactionTable className="mt-4" />
        ),
      },
      {
        id: ROUTES.MY_PROFILE.PLAN,
        icon: 'user-circle',
        label: <FormattedMessage id="messages.account" />,
        children: <Account />,
      },
      {
        id: ROUTES.MY_PROFILE.BLOCKED_USERS,
        icon: 'user-slash',
        label: <FormattedMessage id="navigation.blocked_users" />,
        children: <BlockedUsers className="mt-4" />,
      },
    ],
    initialTabId: 'push-to-playlist',
  }

  const tabs = useTabs(tabsData)

  useEffect(() => {
    const tabParam = searchParams.get('tab')

    if (tabParam) {
      const tabIndex = tabsData.tabs.findIndex((tab) => tab.id === tabParam)
      tabs.tabProps.setSelectedTab(tabIndex > -1 ? [tabIndex] : [0])
    }
  }, [])

  return (
    <ProfileWrapper
      icon="cog"
      title={<FormattedMessage id="navigation.settings" />}
    >
      <Tabs {...tabs.tabProps} />
      <TabContent {...tabs.contentProps} className="p-0">
        {tabs.selectedTab.children}
      </TabContent>
    </ProfileWrapper>
  )
}
