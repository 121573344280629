import React, { useState, Fragment } from 'react'
import Badge from '@fnd/components/Badge'
import Button from '@fnd/components/Button'
import Empty from '@fnd/components/Empty'
import Spinner from '@fnd/components/Spinner'
import { PLAN_PRICES, QUERIES } from '@fnd/constants'
import { ROUTES } from '@fnd/screens/constants'
import ConfirmationPopup from '@fnd/components/ConfirmationPopup'
import dayjs from 'dayjs'
import Payment from '@fnd/components/Payment'
import { PaymentItem } from '@fnd/components/Plans/PaymentItem'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, useNavigate } from 'react-router-dom'
import { toastFeedback } from '@fnd/core/libs/toast'
import { useVipCampaignStore } from '@fnd/store'
import classNames from 'classnames'
import { formatCurrency } from '@fnd/core/libs/currency'
import { addSpacesToCamelCase, getDateFormat } from '@fnd/core/libs/helpers'


export default function VipCampaignTable({ className }) {
  const [confirmDelete, setConfirmDelete] = useState(false)
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const intl = useIntl()
  const { locale } = intl

  const { getMaxCredits } = useVipCampaignStore()

  const { data = { data: [] }, isPending, isLoading } = useQuery({
    queryKey: [QUERIES.VIP_CAMPAIGN.LIST],
    queryFn: () => spotimatchEndpoints.getVipCampaigns(),
  })

  const handleCampaignView = (vipCampaignId) => {
    navigate(
      generatePath(
        `${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.VIP_CAMPAIGN_VIEW}`,
        { campaignId: vipCampaignId }
      )
    )
  }

  const getCampaignStatus = (status) => {
    if (status === 'paid') return {
      variant: 'yellow-light',
      icon: 'money-bill-wave',
    }

    if (status === 'pending') return {
      variant: 'blue-light',
      icon: 'clock',
    }

    if (status === 'running') return {
      variant: 'green-light',
      icon: 'play',
    }

    if (status === 'ended') return {
      variant: 'light',
      icon: 'stop',
    }
  }

  const handleDelete = async () => {
    try {
      await spotimatchEndpoints.deleteVipCampaign()
      setConfirmDelete(false)
      queryClient.invalidateQueries('user.vipCampaigns')
      toastFeedback('success', intl.formatMessage({ id: 'feedback.success.delete_vip_campaign' }))
    } catch (error) {
      console.error(error)
      toastFeedback('error', intl.formatMessage({ id: 'feedback.error.generic' }))
    }
  }

  const classes = classNames({
    'table-responsive': true,
    [className]: className,
  })

  if (isLoading || isPending) {
    return (
      <Spinner />
    )
  }

  if (!isLoading && data?.length === 0) {
    return (
      <Empty
        className="mt-4"
        message={<FormattedMessage id="profile.vip_campaigns.no_data" />}
      >
        <Button to={ROUTES.VIP} className="mt-5">
          <FormattedMessage id="profile.vip_campaigns.no_data.cta" />
        </Button>
      </Empty>
    )
  }

  return (
    <div className={classes}>
      <table className="table">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="profile.vip_campaigns.table.name" />
            </th>
            <th>
              <FormattedMessage id="profile.vip_campaigns.table.duration" />
            </th>
            <th>
              <FormattedMessage id="profile.vip_campaigns.table.status" />
            </th>
            <th>
              <FormattedMessage id="profile.vip_campaigns.table.price" />
            </th>
            <th>
              <FormattedMessage id="profile.vip_campaigns.table.created_at" />
            </th>
            <th className="text-right">
              <FormattedMessage id="messages.actions" />
            </th>
          </tr>
        </thead>

        <tbody>
          {data && data.length > 0 && data.map((vipCampaign, index) => (
            <Fragment key={`${vipCampaign._id}_${index}`}>
              <tr>
                <td>
                  <div className="flex items-center gap-1">
                    {vipCampaign.typologies.map((typology) => (
                      <Badge
                        key={typology}
                        variant="primary-light"
                        className="capitalize"
                      >
                        {addSpacesToCamelCase(typology)}
                      </Badge>
                    ))}
                  </div>
                </td>

                <td>
                  {vipCampaign?.duration > 0 ? (
                    <>
                      {vipCampaign.duration}{' '}
                      {intl.formatMessage({ id: 'messages.days' })}
                    </>
                  ) : '-'}
                </td>

                <td>
                  <Badge
                    variant={getCampaignStatus(vipCampaign?.status)?.variant}
                    icon={getCampaignStatus(vipCampaign?.status)?.icon}
                    className="capitalize"
                    label={intl.formatMessage({ id: `status.${vipCampaign.status}` })}
                  />
                </td>

                <td>
                  {vipCampaign.status === 'pending' ? (
                    <span className="font-bold text-green">
                      {formatCurrency(vipCampaign?.payment?.totalWithVAT, locale)}
                    </span>
                  ) : (
                    <span className="font-bold text-green">
                      {formatCurrency(vipCampaign?.transaction?.amount, locale)}
                    </span>
                  )}
                </td>

                <td>
                  {dayjs(vipCampaign.created_at).format(getDateFormat(locale))}
                </td>

                <td className="text-right">
                  <div className="table-actions">
                    <Button
                      small
                      icon="eye"
                      onClick={() => handleCampaignView(vipCampaign._id)}
                    />

                    {vipCampaign.status === 'pending' && (
                      <>
                        <Payment
                          contract={true}
                          icon="credit-card"
                          label={<FormattedMessage id="payment.pay" />}
                          className="flex justify-center w-100"
                          plan="vip"
                          title="VIP"
                          price={vipCampaign?.payment?.totalWithVAT}
                          maxCredits={getMaxCredits(vipCampaign)}
                          autoApply={false}
                          creditsDiscount={0.5}
                          addons={vipCampaign.addOns}
                        >
                          <div className="flex flex-col gap-2">
                            <PaymentItem
                              title="VIP"
                              color="gold"
                              subtitle={<FormattedMessage id="navigation.account_plan" />}
                              price={vipCampaign?.payment?.total}
                              icon="gem"
                            />

                            {vipCampaign?.addOns?.length > 0 && vipCampaign?.addOns.map(addOn => (
                              <PaymentItem
                                key={addOn.plan}
                                title={addOn.name}
                                subtitle={<FormattedMessage id="messages.add_on" />}
                                price={PLAN_PRICES[addOn.plan].salePrice}
                                salePrice={addOn.price}
                                icon="plus"
                              />
                            ))}
                          </div>
                        </Payment>

                        <Button
                          small
                          icon="trash"
                          variant="inverse-light"
                          onClick={() => setConfirmDelete(true)}
                        />

                        <ConfirmationPopup
                          isOpen={confirmDelete}
                          onConfirm={handleDelete}
                          bodyClasses="p-5"
                          onReject={() => setConfirmDelete(false)}
                          text={<FormattedMessage id="popup.vip_campaign.delete" />}
                        />
                      </>
                    )}
                  </div>
                </td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}
