import { createContext } from 'react'
import { createIntl, createIntlCache } from 'react-intl'
import { DEFAULT_LANGUAGE } from '@fnd/screens/constants'

import enMessages from '@fnd/translations/en.json'
import itMessages from '@fnd/translations/it.json'
import esMessages from '@fnd/translations/es.json'
import frMessages from '@fnd/translations/fr.json'

export const messages = {
  en: enMessages,
  it: itMessages,
  es: esMessages,
  fr: frMessages,
}


const cache = createIntlCache()

const intlEn = createIntl({ locale: 'en', messages: enMessages }, cache)
const intlEs = createIntl({ locale: 'es', messages: esMessages }, cache)
const intlIt = createIntl({ locale: 'it', messages: itMessages }, cache)
const intlFr = createIntl({ locale: 'fr', messages: frMessages }, cache)

export const translations = {
  en: intlEn,
  es: intlEs,
  fr: intlFr,
  it: intlIt,
}

const getLanguage = (language) =>
  Object.keys(messages).includes(language) ? language : DEFAULT_LANGUAGE

export const getExistingLocale = () => {
  const existingLanguage = localStorage.getItem('language')

  const userLang = (navigator?.language || navigator?.userLanguage || '')
    .toLowerCase()
    .split('-')[0]

  return getLanguage(existingLanguage || userLang)
}

export const setExistingLocale = (language) => {
  localStorage.setItem('language', language)
}

export const TranslationContext = createContext({
  locale: DEFAULT_LANGUAGE,
  setLocale: () => { },
})
