import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import queryString from 'query-string'

export const useDiscountStore = create(
  persist(
    (set, get) => ({
      discount: '',
      discountAmount: 0,
      canUseDiscount: false,
      applyDiscountError: '',
      isLoading: false,
      setCanUseDiscount: (canUseDiscount) => set({ canUseDiscount }),
      setIsLoading: (isLoading) => set({ isLoading }),
      setDiscount: (discount) => set({ discount }),
      resetDiscount: () => set({ discount: '', discountAmount: 0, canUseDiscount: false, applyDiscountError: '' }),
      calculateDiscountAmount: (price) => {
        const { discount } = get()
        if (discount?.amount && discount.is_percent) {
          const discountAmount = price * (discount?.amount / 100)
          set({ discountAmount })
          return discountAmount
        } else if (discount?.amount && !discount.is_percent) {
          set({ discountAmount: discount?.amount })
          return discount?.amount
        }
      },
      applyDiscountCode: async (plan, price, code) => {
        const { discount, calculateDiscountAmount, setIsLoading } = get()
        setIsLoading(true)

        const discountCode = code ? code : discount?.code

        try {
          if (!discountCode) {
            setIsLoading(false)
            return
          }

          const query = queryString.stringify({ plan, price })
          const { can_use: isValid } = await spotimatchEndpoints.canUseDiscountCode(discountCode, query)
          const discount = await spotimatchEndpoints.getDiscountByCode(discountCode)

          set({
            canUseDiscount: isValid,
            discount: { code: discountCode },
          })

          if (isValid) set({ discount })
          else set({ applyDiscountError: 'coupon.error.not_found' })

          calculateDiscountAmount(price)
          setIsLoading(false)
        } catch (error) {
          set({
            canUseDiscount: false,
            applyDiscountError: error,
            isLoading: false
          })
        }
      },
      clearDiscount: () => set({
        discount: '',
        discountAmount: 0,
        canUseDiscount: false,
        applyDiscountError: ''
      })
    }),
    {
      name: 'discount-storage'
    }
  )
)

export default useDiscountStore
