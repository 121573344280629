import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import Badge from '@fnd/components/Badge'
import Modal from '@fnd/components/Modal/Modal'
import { Coins } from '@fnd/components/Credits'
import classNames from 'classnames'
import {
  userProfileSelector,
  useUserContext,
} from '@fnd/modules/User'
import { ROUTES } from '@fnd/screens/constants'


export function CreditsModal({
  variant = 'primary',
  className,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false)
  const intl = useIntl()

  const { profile } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
  }))

  const classes = classNames('credit-balance', className)

  return (
    <>
      <span
        className="cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        <Badge
          variant={variant}
          className={classes}
          value={<Coins value={profile?.referral?.score} />}
          {...props}
        />
      </span>

      <Modal
        bodyClasses="p-5"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={intl.formatMessage({ id: 'messages.credit_balance' })}
      >
        <p className="mb-4">
          {intl.formatMessage({ id: 'tutorial.credits' })}
        </p>

        <p>
          {intl.formatMessage({ id: 'tutorial.credits_how_to' })}
        </p>

        <div className="grid md:grid-cols-2 gap-3 mt-4">
          <Badge
            to={`${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.MY_PLAYLISTS}`}
            icon="list-music"
            variant="primary-light"
            className="w-full text-lg hover:opacity-70 hover:scale-95 p-4"
            label={intl.formatMessage({ id: 'navigation.my_playlists' })}
            onClick={() => setIsOpen(false)}
          />

          <Badge
            to={`${ROUTES.TRACKS}`}
            icon="play"
            variant="gold-light"
            className="w-full text-lg hover:opacity-70 hover:scale-95 p-4"
            label={intl.formatMessage({ id: 'navigation.vip_tracks' })}
            onClick={() => setIsOpen(false)}
          />
        </div>
      </Modal>
    </>

  )
}

export default CreditsModal
