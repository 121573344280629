import React from 'react'
import classNames from 'classnames'
import Icon from '@fnd/components/Icon'

export const Placeholder = ({
  className,
  variant,
  icon,
  label,
  star,
  ...props
}) => {
  const classes = classNames({
    'placeholder': true,
    [`placeholder-${variant}`]: variant,
    'avatar-star': star,
    [className]: className,
  })

  return (
    <div className={classes} {...props}>
      {icon && <Icon name={icon} />}
      {label && <p>{label}</p>}
    </div>
  )
}

export default Placeholder
