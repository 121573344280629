import React, { useState, useEffect } from 'react'
import { DEVELOPMENT, USER_TYPES } from '@fnd/constants'
import Avatar from '@fnd/components/Avatar'
import Button from '@fnd/components/Button'
import BlockUser from '@fnd/components/BlockUser'
import ConfirmationPopup from '@fnd/components/ConfirmationPopup'
import { useIntl } from 'react-intl'
import { useNavigate, Link } from 'react-router-dom'
import { ROUTES } from '@fnd/screens/constants'
import { useChatStore } from '@fnd/store'
import useDimensions from '@fnd/core/hooks/useDimensions'
import { useQueryClient } from '@tanstack/react-query'


export const ConversationHeader = ({
  mobile
}) => {
  const {
    activeConversation,
    archiveConversation,
    blockUser,
    blockedList,
    unblockUser,
    unarchiveConversation,
    isConversationArchived,
    deleteConversation,
  } = useChatStore()
  const [archived, setArchived] = useState(activeConversation?.status === 'archived')
  const [deletePopupOpen, setDeletePopupOpen] = useState(false)

  const [ref, { height }] = useDimensions({})

  useEffect(() => {
    const chatWrapper = document.querySelector('.chat-wrapper')
    if (!chatWrapper) return
    chatWrapper.style.setProperty('--chat-header-height', `${height}px`)
  }, [height])

  useEffect(() => {
    setArchived(isConversationArchived(activeConversation?.sid))
  }, [activeConversation])

  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const intl = useIntl()

  const handleArchive = async () => {
    await archiveConversation(queryClient, activeConversation.sid)
    setArchived(true)
  }

  const handleUnarchive = async () => {
    await unarchiveConversation(queryClient, activeConversation.sid)
    setArchived(false)
  }

  const handleUserBlock = (isBlocked, spotifyId) => {
    if (isBlocked) {
      unblockUser(spotifyId)
    } else {
      blockUser(spotifyId)
    }
  }

  const handleDelete = async () => {
    await deleteConversation(queryClient, activeConversation.sid)
    navigate(ROUTES.CHAT)
  }

  const profileLink = ROUTES.CURATOR_DETAIL.replace(':curatorId', activeConversation?.spotify_id)

  if (!activeConversation) return null

  return (
    <div ref={ref} className="chat-header">
      <Link
        to={profileLink}
        className="inline-flex items-center gap-4 text-xl m-0"
      >
        {mobile && (
          <Button
            variant="ghost"
            icon="arrow-left"
            onClick={() => navigate(-1)}
          />
        )}

        {activeConversation?.avatar ? (
          <Avatar
            src={activeConversation.avatar}
            star={activeConversation.type === USER_TYPES.STAR}
          />
        ) : (
          <Avatar
            star={activeConversation.type === USER_TYPES.STAR}
          />
        )}

        <span className="text-sm md:text-xl font-medium">
          {activeConversation?.friendlyName}
        </span>
      </Link>

      <div className="flex items-center gap-2">
        {!archived ? (
          <Button
            small
            variant="light"
            onClick={handleArchive}
            icon="archive"
            label={intl.formatMessage({ id: 'messages.archive' })}
          />) : (
          <Button
            small
            variant="light"
            onClick={handleUnarchive}
            icon="undo"
            label={intl.formatMessage({ id: 'messages.unarchive' })}
          />
        )}

        <BlockUser
          checkOnlineStatus
          spotifyId={activeConversation?.spotify_id}
          showTitle={false}
          callback={handleUserBlock}
        />

        {DEVELOPMENT && (
          <>
            <ConfirmationPopup
              isOpen={deletePopupOpen}
              onConfirm={handleDelete}
              bodyClasses="p-5"
              onReject={() => setDeletePopupOpen(false)}
              title={intl.formatMessage({ id: 'messages.delete.confirm.title' })}
              text={intl.formatMessage({ id: 'messages.delete.confirm.text' })}
            />

            <Button
              small
              variant="red-light"
              onClick={() => setDeletePopupOpen(true)}
              icon="trash"
              label={intl.formatMessage({ id: 'messages.delete' })}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default ConversationHeader
