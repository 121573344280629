import React from 'react'
import { PLAN_PRICES } from '@fnd/constants'
import { ROUTES } from '@fnd/screens/constants'
import { FormattedMessage } from 'react-intl'
import Button from '@fnd/components/Button'
import Empty from '@fnd/components/Empty'
import Payment from '@fnd/components/Payment'
import { PaymentItem } from '@fnd/components/Plans/PaymentItem'
import VipCampaignSummary from '@fnd/components/VipCampaign/VipCampaignSummary'
import classNames from 'classnames'
import { useVipCampaignStore } from '@fnd/store'
import VipCampaignOverview from './VipCampaignOverview'

export default function VipCampaignDetails({
  campaign, className
}) {
  const { getMaxCredits } = useVipCampaignStore()

  const classes = classNames({
    [className]: className,
  })

  return (
    <div className={classes}>
      {campaign ? (
        <div className="grid md:grid-cols-3 gap-8">
          <div className="flex flex-col md:col-span-2">
            <VipCampaignOverview campaign={campaign} />
          </div>

          <div>
            <VipCampaignSummary
              campaign={campaign}
              payment={campaign?.payment}
              impressions={campaign?.estimate}
              showEstimate={true}
            />

            {campaign.status === 'pending' && (
              <>
                <Payment
                  contract={true}
                  icon="credit-card"
                  label={<FormattedMessage id="payment.pay" />}
                  className="flex justify-center w-100 mt-4"
                  plan="vip"
                  title="VIP"
                  price={campaign?.payment?.totalWithVAT}
                  maxCredits={getMaxCredits(campaign)}
                  autoApply={false}
                  creditsDiscount={0.5}
                  addons={campaign.addOns}
                >
                  <div className="flex flex-col gap-2">
                    <PaymentItem
                      title="VIP"
                      color="gold"
                      subtitle={<FormattedMessage id="navigation.account_plan" />}
                      price={campaign?.payment?.total}
                      icon="gem"
                    />

                    {campaign?.addOns?.length > 0 && campaign?.addOns.map(addOn => (
                      <PaymentItem
                        key={addOn.plan}
                        title={addOn.name}
                        subtitle={<FormattedMessage id="messages.add_on" />}
                        price={PLAN_PRICES[addOn.plan].salePrice}
                        salePrice={addOn.price}
                        icon="plus"
                      />
                    ))}
                  </div>
                </Payment>
              </>
            )}
          </div>
        </div>
      ) : (
        <Empty
          className="m-0"
          message={<FormattedMessage id="messages.no_data" />}
        >
          <Button to={ROUTES.VIP} className="mt-5">
            <FormattedMessage id="vip_campaign.title" />
          </Button>
        </Empty>
      )}
    </div>
  )
}
