
import React, { useState } from 'react'
import { ConversationRestrictions } from './ConversationRestrictions'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import Button from '@fnd/components/Button'
import { ROUTES } from '@fnd/screens/constants'
import { toastFeedback } from '@fnd/core/libs/toast'
import { useNavigate } from 'react-router-dom'
import { useUserContext, userProfileSelector } from '@fnd/modules/User'
import { useIntl } from 'react-intl'

export const ConversationStart = ({
  inline,
  icon = 'comments',
  label = 'Chat',
  variant = 'primary',
  user,
  userId,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const intl = useIntl()

  const { profile } = useUserContext(
    ({ user }) => ({
      profile: userProfileSelector(user)
    })
  )

  const handleConversationStart = async () => {
    setError(null)
    setIsLoading(true)

    if (!user && !userId && !profile) {
      toastFeedback('error', intl.formatMessage({ id: 'feedback.error.default' }))
      setIsLoading(false)
      return
    }

    const conversationUserId = userId || user.spotify_id

    try {
      const { conversation } = await spotimatchEndpoints.createConversation(
        [conversationUserId]
      )

      if (conversation && conversation?.sid && !conversation.exists) {
        await spotimatchEndpoints.joinConversation(
          conversation.sid, [profile.spotify_id, conversationUserId]
        )
      }

      navigate(`${ROUTES.CHAT}/${conversation.sid}`)
    } catch ({ response }) {
      if (response.status === 404) {
        toastFeedback('error', intl.formatMessage({
          id: `feedback.error.${response.data.code}`
        }))
      } else if (response?.data?.code) {
        setError(response.data.code)
      } else {
        toastFeedback('error', intl.formatMessage({
          id: 'feedback.error.default'
        }))
      }

      setIsLoading(false)
    }
  }

  return (
    <>
      <ConversationRestrictions error={error} />

      <Button
        onClick={handleConversationStart}
        label={label}
        icon={icon}
        loading={isLoading}
        disabled={isLoading}
        variant={inline ? 'link-plain' : variant}
      />
    </>
  )
}

export default ConversationStart
