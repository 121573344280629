import React from 'react'
import classNames from 'classnames'
import { CURRENCY_SYMBOL } from '@fnd/constants'

export function Coins({ value = 0, className }) {
  const classes = classNames({
    'credit-score': true,
    [className]: className,
  })

  return (
    <span className={classes}>
      <span className="coin-symbol">
        {CURRENCY_SYMBOL}
      </span>
      {value}
    </span>
  )
}

export default Coins
