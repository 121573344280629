import { make } from './endpoints'

export const getCuratorPlaylists = (userId) =>
  make.get(`/curators/${userId}/playlists`)

export const getCuratorDashboard = (userId) =>
  make.get(`/curators/${userId}/dashboard`)

export const getCuratorStats = (userId) =>
  make.get(`/curators/${userId}/stats`)

export const getCuratorRank = () =>
  make.get(`/curators/rank`)


export default {
  getCuratorDashboard,
  getCuratorPlaylists,
  getCuratorRank,
  getCuratorStats
}
