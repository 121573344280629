import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import classNames from 'classnames'
import Alert from '@fnd/components/Alert'
import Button from '@fnd/components/Button'
import { ModalHeader } from '@fnd/components/Modal'
import { FormattedMessage, useIntl } from 'react-intl'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import parse from 'html-react-parser'
import Field, { FieldRepeater } from '@fnd/components/Field'
import Genres from '../Analysis/Search/Genres'
import { isValidEmail } from '@fnd/core/libs/validate'
import { MAXIMUM_GENRES_PER_MAGAZINE } from '@fnd/constants'
import { getSlugFromURL } from '@fnd/core/libs/getSlugFromUrl'
import {
  socials,
  appendSocialPrefixes,
  removeSocialPrefix,
} from '@fnd/core/libs/platforms'


const initialMagazineState = {
  name: '',
  description: '',
  email: '',
  location: '',
  facebook: '',
  instagram: '',
  twitter: '',
  tags: [],
  contacts: [],
}

const validation = {
  name: (value) => value.length,
  description: (value) => value.length,
  email: (value) => isValidEmail(value),
  location: (value) => value.length,
}

const MagazineRequest = ({
  className,
  icon = 'external-link',
  variant = 'primary',
  small,
  triggerClasses,
  magazineURL,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState(null)
  const [requestExists, setRequestExists] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const intl = useIntl()

  const fieldsConfig = [
    { name: 'name', placeholder: intl.formatMessage({ id: 'field.full_name.label' }), type: 'text', required: false },
    { name: 'email', placeholder: intl.formatMessage({ id: 'profile.billing_info.email' }), type: 'text', required: true },
  ]

  const [magazineState, setMagazineState] = useState(initialMagazineState)
  const [magazineCover, setMagazineCover] = useState(null)
  const [magazineLogo, setMagazineLogo] = useState(null)

  const [isFormValid, setFormValid] = useState(true)
  const [formErrors, setFormErrors] = useState({})

  const validate = (value, type) => {
    if (typeof value !== 'string' || !validation[type]) {
      return true
    }

    return Boolean(validation[type](value.trim()))
  }

  const validateElement = (key) => {
    const isValid = validate(magazineState[key], key)
    setFormErrors({ ...formErrors, [key]: !isValid })
    setFormValid(isValid)
    return isValid
  }

  const validateForm = () => {
    const errors = Object.keys(validation).reduce((acc, key) => {
      const isValid = validate(magazineState[key], key)
      if (!isValid) {
        acc[key] = true
      }
      return acc
    }, {})
    setFormErrors(errors)
    setFormValid(Object.keys(errors).length === 0)
    return Object.keys(errors).length === 0
  }

  const focusHandler = ({ target }) => {
    setFormErrors({ ...formErrors, [target.name]: false })
  }

  const blurHandler = ({ target }) => validateElement(target.name)
  const blurContactHandler = ({ target }) => {
    validateElement(target.name)
    const newValue = removeSocialPrefix(target.value)
    setMagazineState({ ...magazineState, [target.name]: newValue })
  }


  useEffect(() => {
    const fetchData = async () => {
      const data = await spotimatchEndpoints.getUserRequests()
      if (data.length > 0) {
        const request = data.find((request) => request.typology === 'magazine')
        if (request) {
          setStatus(request.status)
        }
      }
    }

    const magazineExists = async () => {
      const data = await spotimatchEndpoints.getRequestExists('magazine')
      if (data && data.exists) {
        setRequestExists(true)
      }
    }

    fetchData()
    magazineExists()
  }, [])

  const classes = classNames({
    'payment-modal': true,
    [className]: className,
  })

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleRequestSend = async (e) => {
    e.preventDefault()
    if (!validateForm() || isLoading) {
      return
    }

    setIsLoading(true)

    if (magazineLogo || magazineCover) {
      const magazineSlug = getSlugFromURL(magazineURL)
      const formData = new FormData()
      formData.set('name', magazineSlug)
      formData.set('folder', 'magazine')

      if (magazineLogo) {
        formData.set('logo', magazineLogo)
      }

      if (magazineCover) {
        formData.set('cover', magazineCover)
      }

      const mediaResponse = await spotimatchEndpoints.uploadMedia(formData)

      magazineState.logo = mediaResponse.filter(
        (media) => media.fieldname === 'logo'
      )?.[0]?.filename ?? ''

      magazineState.cover = mediaResponse.filter(
        (media) => media.fieldname === 'cover'
      )?.[0]?.filename ?? ''
    }

    try {
      const data = await spotimatchEndpoints.sendRequest('magazine', appendSocialPrefixes(magazineState))
      if (data) {
        setRequestExists(true)
        setStatus(data.status)
      }
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      setStatus('exists')
    }
  }

  return (
    <div className={classes}>
      <Button
        small={small}
        className={triggerClasses ?? null}
        icon={icon}
        variant={variant}
        disabled={false}
        label={<FormattedMessage id="magazine.publish.trigger_cta" />}
        onClick={() => setIsOpen(!isOpen)}
      />

      {isOpen ? (
        <Modal
          isOpen={isOpen}
          onRequestClose={() => setIsOpen(!isOpen)}
          shouldCloseOnOverlayClick
          overlayClassName="modal show"
          className="modal-dialog modal-center modal-sm"
        >
          <div className="modal-content">
            <ModalHeader
              title={<FormattedMessage id="magazine.publish.title" />}
              onClose={handleClose}
            />

            <div className="p-6 modal-body">
              <div className="flex flex-col gap-4">
                <p className="text-balance">
                  {parse(intl.formatMessage({ id: 'magazine.publish.description.1' }))}
                </p>
                <p className="text-balance">
                  {parse(intl.formatMessage({ id: 'magazine.publish.description.2' }))}
                </p>
              </div>

              {!requestExists && (
                <form className="w-full flex flex-col gap-8 mt-8" onSubmit={handleRequestSend}>
                  <div className="grid md:grid-cols-2 gap-6">
                    <Field
                      id="website"
                      name="website"
                      label={intl.formatMessage({ id: 'field.website.label' })}
                      type="url"
                      value={magazineURL}
                      iconBefore={<i className="fad fa-globe" />}
                      disabled={true}
                    />

                    <Field
                      id="name"
                      name="name"
                      label={intl.formatMessage({ id: 'field.magazine.name.label' })}
                      type="text"
                      placeholder={intl.formatMessage({ id: 'field.magazine.name.label' })}
                      value={magazineState.name}
                      errors={formErrors.name ? [
                        intl.formatMessage({ id: 'form.error.required' }),
                      ] : null}
                      onBlur={blurHandler}
                      onFocus={focusHandler}
                      onChange={(e) =>
                        setMagazineState({ ...magazineState, name: e.target.value })
                      }
                    />

                    <Field
                      id="description"
                      name="description"
                      className="md:col-span-2"
                      label={intl.formatMessage({ id: 'magazine.form.description' })}
                      type="textarea"
                      maxLength={1000}
                      placeholder={intl.formatMessage({ id: 'magazine.form.description' })}
                      value={magazineState.description}
                      errors={formErrors.description ? [
                        intl.formatMessage({ id: 'form.error.required' }),
                      ] : null}
                      onBlur={blurHandler}
                      onFocus={focusHandler}
                      onChange={(e) =>
                        setMagazineState({
                          ...magazineState,
                          description: e.target.value,
                        })
                      }
                    />

                    <Field
                      id="email"
                      name="email"
                      label={intl.formatMessage({ id: 'magazine.form.primary_mail' })}
                      type="email"
                      placeholder={intl.formatMessage({ id: 'magazine.form.primary_mail' })}
                      value={magazineState.email}
                      errors={formErrors.email ? [
                        intl.formatMessage({ id: 'form.error.required' }),
                      ] : null}
                      onFocus={focusHandler}
                      onBlur={blurHandler}
                      onChange={(e) =>
                        setMagazineState({ ...magazineState, email: e.target.value })
                      }
                    />

                    <Field
                      id="location"
                      name="location"
                      label={intl.formatMessage({ id: 'magazine.form.location' })}
                      type="text"
                      placeholder={intl.formatMessage({ id: 'magazine.form.location' })}
                      value={magazineState.location}
                      errors={formErrors.location ? [
                        intl.formatMessage({ id: 'form.error.required' }),
                      ] : null}
                      onBlur={blurHandler}
                      onFocus={focusHandler}
                      onChange={(e) =>
                        setMagazineState({ ...magazineState, location: e.target.value })
                      }
                    />

                    <Field
                      className="col-span-2"
                      id="facebook"
                      name="facebook"
                      label="Facebook"
                      placeholder="Facebook URL"
                      prefix={socials.facebook.prefix}
                      value={magazineState.facebook}
                      errors={formErrors.facebook ? [
                        intl.formatMessage({ id: 'form.error.required' }),
                      ] : null}
                      onBlur={blurContactHandler}
                      onFocus={focusHandler}
                      onChange={(e) =>
                        setMagazineState({ ...magazineState, facebook: e.target.value })
                      }
                    />

                    <Field
                      className="col-span-2"
                      id="instagram"
                      name="instagram"
                      label="Instagram"
                      placeholder="Instagram URL"
                      prefix={socials.instagram.prefix}
                      value={magazineState.instagram}
                      errors={formErrors.instagram ? [
                        intl.formatMessage({ id: 'form.error.required' }),
                      ] : null}
                      onBlur={blurContactHandler}
                      onFocus={focusHandler}
                      onChange={(e) =>
                        setMagazineState({ ...magazineState, instagram: e.target.value })
                      }
                    />

                    <Field
                      className="col-span-2"
                      id="twitter"
                      name="twitter"
                      label="Twitter"
                      placeholder="Twitter URL"
                      prefix={socials.twitter.prefix}
                      value={magazineState.twitter}
                      errors={formErrors.twitter ? [
                        intl.formatMessage({ id: 'form.error.required' }),
                      ] : null}
                      onBlur={blurContactHandler}
                      onFocus={focusHandler}
                      onChange={(e) =>
                        setMagazineState({ ...magazineState, twitter: e.target.value })
                      }
                    />

                    <Genres
                      className="md:col-span-2"
                      label={intl.formatMessage({ id: 'magazine.form.genres' })}
                      genres={magazineState.tags}
                      onBlur={blurHandler}
                      onFocus={focusHandler}
                      maxLimit={MAXIMUM_GENRES_PER_MAGAZINE}
                      onGenreSelect={(tags) => setMagazineState({ ...magazineState, tags })}
                    />

                    <Field
                      id="cover-image"
                      name="cover-image"
                      label={intl.formatMessage({ id: 'magazine.form.cover' })}
                      type="file"
                      accept="image/*"
                      maxSize={1048576}
                      isClearable={true}
                      onChange={(files) => setMagazineCover(files?.[0] ?? null)}
                    />

                    <Field
                      id="logo-image"
                      name="logo-image"
                      label={intl.formatMessage({ id: 'magazine.form.logo' })}
                      type="file"
                      accept="image/*"
                      maxSize={1048576}
                      isClearable={true}
                      onChange={(files) => setMagazineLogo(files?.[0] ?? null)}
                    />
                  </div>

                  <div className="field mt-5">
                    <FieldRepeater
                      label={intl.formatMessage({ id: 'magazine.form.contacts' })}
                      fieldsConfig={fieldsConfig}
                      buttonLabel={intl.formatMessage({ id: 'magazine.form.add_contact' })}
                      onChange={(contacts) => setMagazineState({ ...magazineState, contacts })}
                    />
                  </div>

                  <div className="form-footer">
                    <Button
                      type="submit"
                      loading={isLoading}
                      className="w-full mt-4"
                      variant="primary"
                      disabled={!isFormValid}
                      label={<FormattedMessage id="messages.send_request" />}
                    />
                  </div>
                </form>
              )}

              {requestExists && !status && (
                <Alert variant="warning" className="mt-4">
                  <FormattedMessage id="magazine.publish.exists" />
                </Alert>
              )}

              {requestExists && status === 'pending' && (
                <Alert variant="warning" className="mt-4">
                  <FormattedMessage id="magazine.publish.pending" />
                </Alert>
              )}

              {requestExists && status === 'rejected' && (
                <Alert variant="danger" className="mt-4">
                  <FormattedMessage id="magazine.publish.rejected" />
                </Alert>
              )}

              {requestExists && status === 'approved' && (
                <Alert variant="success" className="mt-4">
                  <FormattedMessage id="magazine.publish.approved" />
                </Alert>
              )}
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  )
}

export default MagazineRequest
