import React, { useCallback, useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Modal from 'react-modal'
import { spotifyEndpoints } from '@fnd/core/spotify'
import update from 'update-immutable'
import Alert from '@fnd/components/Alert'
import Button from '@fnd/components/Button'
import Card from '@fnd/components/Card'
import { Empty } from '@fnd/components/Empty'
import { ModalHeader } from '@fnd/components/Modal'
import SearchSelect from '@fnd/modules/Analysis/Search/SearchSelect'
import { useUserContext } from '@fnd/modules/User'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import Spinner from '@fnd/components/Spinner'
import { QUERIES } from '@fnd/constants'
import { saveAs } from 'file-saver'

const analysisDownloadPopupInitialState = {
  visible: false,
  artistId: '',
  orderData: null,
  triggerApi: false,
}

const AnalysisCalculator = ({
  isOpen,
  availableAnalysis,
  onSuccessCallback,
  isArtistAnalysisFree
}) => {
  const { user } = useUserContext()
  const intl = useIntl()
  const queryClient = useQueryClient()

  const [analysisDownloadPopupState, setAnalysisDownloadPopupState] = useState(
    analysisDownloadPopupInitialState
  )

  const [state, setState] = useState({
    isExistsArtist: false,
    isExistsTrack: false,
    artist: {},
    trackId: '',
    artistId: '',
    track: {},
    artistError: null,
    trackError: null,
    price: null,
    discountPrice: null,
    discount: '',
    discountError: null,
  })

  const patch = (next) => setState((current) => update(current, next))

  const { data, isLoading, isSuccess, isError } = useQuery(
    {
      queryKey: [QUERIES.ANALYSIS.GET, state.artistId],
      queryFn: () =>
        spotimatchEndpoints.getArtistAnalysis(
          analysisDownloadPopupState.artistId
        ),
      enabled: analysisDownloadPopupState.triggerApi,
    },
    queryClient
  )

  useEffect(() => {
    if (data && !isLoading && analysisDownloadPopupState.triggerApi) {
      saveAs(
        data,
        `${state.artist.name}_${new Date().toLocaleDateString('it-IT')}.pdf`
      )
    }
  }, [data])

  const getArtist = (artistId) =>
    artistId &&
    spotifyEndpoints
      .getArtist(artistId)
      .then((artist) => {
        patch({
          isExistsArtist: { $set: true },
          artist: { $set: artist },
          artistError: { $set: void 0 },
        })
      })
      .catch((error) => {
        patch({
          isExistsArtist: { $set: false },
          artistError: { $set: error.message },
        })
      })

  const trackUrlHandler = (trackId) => {
    if (!trackId) return true

    return spotifyEndpoints
      .getTrack(trackId)
      .toPromise()
      .then((track) => {
        patch({
          isExistsTrack: { $set: true },
          track: { $set: track },
          trackError: { $set: void 0 },
        })

        return track
      })
      .then(({ artists }) => {
        if (artists.length) {
          return getArtist(artists[0].id)
        }
      })
      .catch((error) => {
        patch({
          isExistsTrack: { $set: false },
          trackError: { $set: error.message },
        })
      })
  }

  useEffect(() => {
    trackUrlHandler(state.trackId)
  }, [state.trackId])

  useEffect(() => {
    getArtist(state.artistId)
  }, [state.artistId])

  const handleTrackIdChanges = useCallback((value) => {
    const id = value
    if (!id) {
      return patch({
        isExistsTrack: { $set: false },
        isExistsArtist: { $set: false },
        trackId: { $set: '' },
        artistId: { $set: '' },
        price: { $set: null },
        discountPrice: { $set: null },
      })
    }

    return patch({
      price: { $set: null },
      discountPrice: { $set: null },
      artistId: { $set: id },
      trackError: { $set: void 0 },
      artistError: { $set: void 0 },
    })
  }, [])

  const handleDiscountChanges = ({ target }) => {
    patch({
      price: { $set: null },
      discountPrice: { $set: null },
      genres: { $set: null },
      discount: { $set: target.value },
    })
  }

  const resetForm = () => {

    handleTrackIdChanges('')
    handleDiscountChanges({ target: { value: '' } })
    queryClient.removeQueries({
      queryKey: [QUERIES.ANALYSIS.GET, state.artistId],
      exact: true,
    })

    queryClient.resetQueries({
      queryKey: [QUERIES.ANALYSIS.GET, state.artistId],
      exact: true,
    })

    setAnalysisDownloadPopupState(analysisDownloadPopupInitialState)
    onSuccessCallback()
  }

  const analysisPaymentSuccess = (data) => {
    setAnalysisDownloadPopupState({
      visible: true,
      artistId: state.artistId,
      orderData: data,
      triggerApi: true,
    })
  }

  const onCloseHandler = () => {
    resetForm()
  }

  return (
    <>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onRequestClose={() => onCloseHandler()}
          shouldCloseOnOverlayClick
          overlayClassName="modal show"
          className="modal-dialog modal-center modal-md"
        >
          <div className="modal-content">
            <ModalHeader
              title={<FormattedMessage id="analysis.card.title" />}
              onClose={() => onCloseHandler()}
            />
            <div className="p-5 modal-body">
              <div className="flex flex-col">
                <div className="flex flex-col gap-4 md:flex-row">
                  <SearchSelect
                    className="w-full"
                    entity="artist"
                    isDisabled={analysisDownloadPopupState.triggerApi}
                    onChange={handleTrackIdChanges}
                  />
                </div>

                {state.isExistsArtist &&
                  !analysisDownloadPopupState.triggerApi &&
                  !(isSuccess || isError) && (
                    <div className="mt-6">
                      <Card
                        className="card-info md:col-span-3"
                        title={<FormattedMessage id="analysis.card.title" />}
                        footer={
                          <>
                            <Button
                              className="px-8 mt-8 text-center button-wide"
                              onClick={() =>
                                analysisPaymentSuccess(state.artistId)
                              }
                            >
                              <FormattedMessage id="messages.continue" />
                            </Button>
                          </>
                        }
                      >
                        <p className="text-lg font-semibold text-primary">
                          {isArtistAnalysisFree
                            ? `${user.profile.plan.freeArtistAnalysisCount
                            } ${intl
                              .formatMessage({
                                id: 'analysis.card.free_remaining',
                              })
                              .toLowerCase()}`
                            : `${availableAnalysis} ${intl
                              .formatMessage({
                                id: 'analysis.card.remaining',
                              })
                              .toLowerCase()}`}
                        </p>
                      </Card>
                    </div>
                  )}
              </div>

              {analysisDownloadPopupState.triggerApi && !data && isLoading && (
                <Empty
                  className="justify-center mt-4 text-center"
                  emoji={<Spinner className="my-2" />}
                  message={<FormattedMessage id="popup.analysis.loading" />}
                />
              )}

              {!isLoading && isSuccess && (
                <>
                  <Alert
                    variant="success"
                    className="mt-4"
                    icon="check-circle"
                    label={intl.formatMessage({ id: 'feedback.success.analysis' })}
                  />
                  <Button
                    className="px-8 mt-3 text-center button-wide"
                    icon="times"
                    label={intl.formatMessage({ id: 'messages.close' })}
                    onClick={() => resetForm(isArtistAnalysisFree)}
                  />
                </>
              )}

              {!isLoading && isError && (
                <Alert
                  variant="danger"
                  className="mt-4"
                  icon="exclamation-triangle"
                  label={intl.formatMessage({ id: 'feedback.error.analysis' })}
                />
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default AnalysisCalculator
