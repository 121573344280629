import React, { useState, useEffect, useRef } from 'react'
import Button from '@fnd/components/Button'
import { useIntl } from 'react-intl'
import SearchSelect from '@fnd/modules/Analysis/Search/SearchSelect'
import useDimensions from '@fnd/core/hooks/useDimensions'
import { useChatStore } from '@fnd/store'
import classNames from 'classnames'


export const ConversationInput = ({ onSend }) => {
  const MESSAGE_MIN_LENGTH = 2
  const {
    activeSid,
    isCurrentUserBlocked,
    conversations
  } = useChatStore()
  const [addPlaylist, setAddPlaylist] = useState(false)
  const [addTrack, setAddTrack] = useState(false)
  const [track, setTrack] = useState(null)
  const [playlist, setPlaylist] = useState(null)
  const [message, setMessage] = useState('')
  const [error, setError] = useState(false)
  const [clear, setClear] = useState(false)
  const intl = useIntl()

  const [ref, { height }] = useDimensions({})
  const inputRef = useRef(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [activeSid])

  useEffect(() => {
    const chatWrapper = document.querySelector('.chat-wrapper')
    if (!chatWrapper) return
    chatWrapper.style.setProperty('--chat-footer-height', `${height}px`)
  }, [height])

  useEffect(() => {
    if (message.length > MESSAGE_MIN_LENGTH) {
      setError(false)
    }
  }, [message])

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && message?.length > 0) {
      e.preventDefault()
      handleSendMessage(e)
    }
  }

  const resetData = () => {
    setMessage('')
    setClear(true)
    setAddPlaylist(false)
    setPlaylist(null)
    setAddTrack(false)
    setTrack(null)
  }

  const handleSendMessage = async (e) => {
    e.preventDefault()

    const trimmedMessage = message.trim()

    if (
      !message ||
      message.length < MESSAGE_MIN_LENGTH ||
      trimmedMessage.length < MESSAGE_MIN_LENGTH
    ) {
      setError(true)
      return
    }

    if (message && conversations[activeSid]) {
      let attributes = {}
      if (track) {
        attributes.track = track
      }

      if (playlist) {
        attributes.playlist = playlist
      }

      await conversations[activeSid].sendMessage(message, attributes)
      resetData()

      if (onSend) {
        onSend(message)
      }
    }
  }

  const handleAttributeChange = (value, type) => {
    if (type === 'track') {
      setTrack({
        id: value.id,
        url: value.external_urls.spotify,
        name: value.name,
        type: 'track',
        icon: 'music',
        image: value.album.images[0].url
      })
    }

    if (type === 'playlist') {
      setPlaylist({
        id: value.id,
        url: value.external_urls.spotify,
        name: value.name,
        type: 'playlist',
        icon: 'list-music',
        image: value.images[0].url
      })
    }
  }

  return (
    <div ref={ref} className="chat-footer">
      <form
        className="w-full"
        onSubmit={handleSendMessage}
      >
        <textarea
          ref={inputRef}
          disabled={isCurrentUserBlocked()}
          value={message}
          className={classNames({
            'border-red-500': error,
          })}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={intl.formatMessage({ id: 'placeholder.type_message' })}
        />

        {error && (
          <span className="text-red-500 text-xs">
            {intl.formatMessage({ id: 'feedback.error.enter_message' })}
          </span>
        )}

        <div className="flex flex-col md:flex-row items-end justify-between gap-3">
          <div className="flex flex-col md:flex-row w-full md:w-auto items-center gap-2">
            {!addPlaylist && (
              <Button
                small
                variant="light"
                icon="list-music"
                disabled={isCurrentUserBlocked()}
                className="whitespace-nowrap w-full flex-1"
                label={intl.formatMessage({ id: 'messages.add_playlist' })}
                onClick={() => setAddPlaylist(true)}
              />
            )}

            {addPlaylist && (
              <SearchSelect
                entity="playlist"
                className="w-full"
                name="playlist"
                hideLabel={true}
                onDataChange={(value) => handleAttributeChange(value, 'playlist')}
                isDisabled={isCurrentUserBlocked()}
                clear={clear}
                onClear={() => setAddPlaylist(false)}
              />
            )}

            {!addTrack && (
              <Button
                small
                variant="light"
                icon="music"
                disabled={isCurrentUserBlocked()}
                className="whitespace-nowrap w-full flex-1"
                label={intl.formatMessage({ id: 'messages.add_track' })}
                onClick={() => setAddTrack(true)}
              />
            )}


            {addTrack && (
              <SearchSelect
                entity="track"
                className="w-full"
                name="track"
                hideLabel={true}
                onDataChange={(value) => handleAttributeChange(value, 'track')}
                isDisabled={isCurrentUserBlocked()}
                clear={clear}
                onClear={() => setAddTrack(false)}
              />
            )}
          </div>

          <Button
            type="submit"
            disabled={isCurrentUserBlocked()}
            className="w-full md:w-auto"
            label={intl.formatMessage({ id: 'messages.send' })}
            icon="arrow-right"
          />
        </div>
      </form>
    </div>
  )
}

export default ConversationInput
