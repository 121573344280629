import React, { useState } from 'react'
import ProfileWrapper from '@fnd/modules/Profile/ProfileWrapper'
import { useTabs, TabLinks } from '@fnd/components/Tabs'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { QUERIES } from '@fnd/constants'
import { useQuery } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import { PlaylistsCheckTable } from '.'

export const PlaylistCheck = () => {
  const [isOwner, setIsOwner] = useState(true)
  const intl = useIntl()

  const { data: playlistData } = useQuery({
    queryKey: [QUERIES.PLAYLIST_CHECK.MONITORED],
    queryFn: () => spotimatchEndpoints.getMonitoring().toPromise()
  })

  const filters = [
    {
      id: 'owner',
      label: intl.formatMessage({ id: 'playlist_check.filters.owned' }),
      icon: 'list-music',
      onClick: () => setIsOwner(true)
    },
    {
      id: 'monitored',
      label: intl.formatMessage({ id: 'playlist_check.filters.monitored' }),
      icon: 'eye',
      onClick: () => setIsOwner(false)
    },
  ]

  const filterTabs = {
    tabs: filters,
    initialTabId: filters[0].id
  }

  const tabs = useTabs(filterTabs)

  return (
    <ProfileWrapper
      icon="check"
      title={intl.formatMessage({ id: 'navigation.report' })}
    >
      <div className="flex">
        <div className="w-full">
          <TabLinks
            {...tabs.tabProps}
            inline
            variant="toggle"
            className="tab-light w-full mb-4"
            selectedTabIndex={isOwner ? 0 : 1}
            showIndicator={false}
            showHover={false}
          />

          <PlaylistsCheckTable
            isOwner={isOwner}
            isLoading={!playlistData}
            playlists={playlistData}
          />
        </div>
      </div>
    </ProfileWrapper>
  )
}

export default PlaylistCheck
