import React, { useState, useEffect } from 'react'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import Spinner from '@fnd/components/Spinner'
import Wrapper from '@fnd/components/Wrapper'
import { QUERIES } from '@fnd/constants'
import { ROUTES } from '@fnd/screens/constants'
import { useInView } from 'react-intersection-observer'
import { useInfiniteQuery } from '@tanstack/react-query'
import {
  ConversationActive,
  ConversationArchive,
  ConversationArchiveToggle,
  Conversation
} from '@fnd/components/Chat'
import { useChatStore } from '@fnd/store'
import { motion, AnimatePresence, MotionConfig } from 'framer-motion'
import { useParams, useNavigate } from 'react-router-dom'
import classNames from 'classnames'


export const ConversationList = ({
  detail,
  mobile,
  user,
  className,
}) => {
  const {
    activeSid,
    setActiveConversation,
    conversationList,
    archiveList,
    setArchiveList,
    setActiveSid,
    setConversationList,
    conversationTotal,
    setConversationTotal
  } = useChatStore()

  const [showArchive, setShowArchive] = useState(false)
  const [enabled, setEnabled] = useState(true)
  const { id } = useParams()
  const navigate = useNavigate()

  const {
    isFetchingNextPage,
    isLoading,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery({
    queryKey: [QUERIES.CHAT.LIST, user.spotify_id],
    queryFn: async (params) => {
      const res = await spotimatchEndpoints.getConversations(params, user.spotify_id)
      if (res?.total !== conversationTotal) {
        setConversationList(res?.conversations)
        setArchiveList(res?.archive)
        setConversationTotal(archiveList.length + conversationList.length)

        if (conversationTotal >= (archiveList.length + conversationList.length)) {
          setEnabled(false)
        }

        return res
      }
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage?.next_page) return undefined
      return lastPage.next_page
    },
    staleTime: 300000,
    cacheTime: 900000,
    enabled,
  })

  const { ref, inView } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage, hasNextPage, isFetchingNextPage])

  useEffect(() => {
    if (
      conversationList.length > 0 &&
      (id && activeSid && activeSid !== id) ||
      (id && !activeSid)
    ) {
      const chat = conversationList.find(chat => chat.sid === id)
      if (chat) {
        setActiveSid(id)
        setActiveConversation(chat)
      }
    }
  }, [id, conversationList])

  const handleConversationSelect = (chat) => {
    navigate(`${ROUTES.CHAT}/${chat.sid}`)
    setActiveConversation(chat)
    setActiveSid(chat.sid)
  }

  const classes = classNames({
    'chat-wrapper': true,
    'chat-detail': detail,
    'chat-list': !detail,
    'chat-mobile': mobile,
    [className]: className,
  })

  const variants = {
    initial: {
      opacity: 0,
      x: '-100%'
    },
    animate: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      x: '-100%'
    }
  }

  return (
    <MotionConfig transition={{ duration: 0.5, type: "spring", bounce: 0 }}>
      <Wrapper>
        {isLoading && <Spinner />}

        {!isLoading && (
          <div className={classes}>
            <div className="chat-sidebar">
              <ConversationArchiveToggle
                showArchive={showArchive}
                setShowArchive={setShowArchive}
                total={archiveList.length}
              />

              <AnimatePresence mode="popLayout">
                {showArchive ? (
                  <motion.div
                    key="chat-archive"
                    className="w-full h-full"
                    variants={variants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                  >
                    <ConversationArchive
                      archiveList={archiveList}
                      activeSid={activeSid}
                      showArchive={showArchive}
                      setShowArchive={setShowArchive}
                      handleConversationSelect={handleConversationSelect}
                      conversationList={conversationList}
                      isLoading={isLoading}
                    />
                  </motion.div>
                ) : (
                  <motion.div
                    key="chat-list"
                    className="w-full h-full"
                    variants={variants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                  >
                    <ConversationActive
                      activeSid={activeSid}
                      showArchive={showArchive}
                      handleConversationSelect={handleConversationSelect}
                      conversationList={conversationList}
                      isLoading={isLoading}
                    />
                  </motion.div>
                )}
              </AnimatePresence>

              {!isLoading && <div ref={ref} className="w-full h-1" />}

              {isLoading && <Spinner inline />}
            </div>

            <Conversation
              activeSid={activeSid}
              detail={detail}
              user={user}
              mobile={mobile}
            />
          </div>
        )}
      </Wrapper>
    </MotionConfig>
  )
}

export default ConversationList
