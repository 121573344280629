import { CURRENCY, VAT_AMOUNT } from '@fnd/constants'

export const formatCurrency = (amount, locale = 'en-US', currency = CURRENCY) => {
  switch (locale) {
    case 'en':
      locale = 'en-US'
      break
    case 'es':
      locale = 'es-ES'
      break
    case 'it':
      locale = 'it-IT'
      break
    default:
      locale = 'en-US'
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  })

  const formatted = formatter.format(amount)
  return formatted
}

export const formatNumber = (number, locale = 'en-US') => {
  const formatter = new Intl.NumberFormat(locale)
  const formatted = formatter.format(number)
  return formatted
}

export const calculateVAT = (total, vatRate = VAT_AMOUNT) => {
  const vatAmount = total * (vatRate / 100)
  const totalWithVAT = total + vatAmount
  return totalWithVAT
}
