import React, { useState, useEffect } from 'react'
import Alert from '@fnd/components/Alert'
import Button from '@fnd/components/Button'
import { usePlaylistStore } from '@fnd/store'
import { toastFeedback } from '@fnd/core/libs/toast'
import { useIntl } from 'react-intl'
import { ROUTES } from '@fnd/screens/constants'


export default function PlaylistAnalysisRequest({
  defaultWatch = true,
  hasAnalysis,
  playlistId
}) {
  const { success, error, resetFeedback, toggleWatch } = usePlaylistStore()
  const [watch, setWatch] = useState(defaultWatch)
  const [isLoading, setIsLoading] = useState(false)
  const intl = useIntl()

  const handleRequest = async () => {
    setIsLoading(true)
    resetFeedback()

    await toggleWatch(playlistId, watch)

    setIsLoading(false)
  }

  useEffect(() => {
    if (success) {
      setWatch(!watch)
      toastFeedback('success', intl.formatMessage({ id: success }))
    } else if (error) {
      toastFeedback('error', intl.formatMessage({ id: error }))
    }
    setIsLoading(false)
  }, [success, error])

  return (
    <>
      {watch && !success && !error && !hasAnalysis && (
        <Alert
          variant="primary-light"
          className="text-center"
        >
          <p className="md:text-lg">
            {intl.formatMessage({ id: 'playlist_check.request.info' })}
          </p>
        </Alert>
      )}

      {success && watch && (
        <Alert
          icon="check"
          variant="success"
          className="text-center"
        >
          <p className="md:text-lg">
            {intl.formatMessage({ id: 'playlist_check.request.sent' })}
          </p>
        </Alert>
      )}

      {success && !watch && (
        <Alert
          icon="undo"
          variant="info"
          className="text-center"
        >
          <p className="md:text-lg">
            {intl.formatMessage({ id: 'playlist_check.request.unsent' })}
          </p>
        </Alert>
      )}

      {error && (
        <Alert
          icon="exclamation-triangle"
          variant="danger"
          className="text-center"
        >
          <p className="md:text-lg">
            {intl.formatMessage({ id: 'playlist_check.request.error' })}
          </p>
        </Alert>
      )}


      <div className="flex flex-col gap-3">
        <Button
          icon={watch ? 'eye' : 'undo'}
          variant={watch ? 'primary' : 'light'}
          label={watch ?
            intl.formatMessage({ id: 'playlist_check.cta.watch' }) :
            intl.formatMessage({ id: 'playlist_check.cta.unwatch' })
          }
          loading={isLoading}
          onClick={handleRequest}
        />

        {<Button
          variant="outline-primary"
          icon="arrow-right"
          to={`${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.PLAYLIST_CHECK}`}
          label={intl.formatMessage({ id: 'playlist_check.cta.profile' })}
        />}
      </div>
    </>
  )
}
