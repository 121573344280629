import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import Icon from '@fnd/components/Icon'

const Card = ({
  title,
  text,
  overlay,
  overlayColor,
  overlayTitle,
  background,
  bgClasses,
  className,
  image,
  icon,
  titleTag,
  to,
  footer,
  external,
  imageClasses,
  contentClasses,
  children,
  ...props
}) => {
  const classes = classNames({
    card: true,
    [className]: className,
  })

  const imageCn = classNames({
    'card-image': true,
    [imageClasses]: imageClasses,
  })

  const cardContentCn = classNames({
    'card-content': true,
    [contentClasses]: contentClasses,
  })

  const bgCn = classNames({
    'background': true,
    'absolute inset-0': true,
    'w-full h-full': true,
    [bgClasses]: bgClasses,
  })

  const Heading = titleTag

  const CardWrapper = ({ children, ...props }) => {
    if (to && !external) {
      return (
        <Link className={classes} to={to} {...props}>
          {children}
        </Link>
      )
    } else if (to && external) {
      return (
        <a className={classes} href={to} target="_blank" rel="noreferrer" {...props}>
          {children}
        </a>
      )
    }
    return <div className={classes} {...props}>{children}</div>
  }

  return (
    <CardWrapper className={classes} {...props}>
      {(image || background) && (
        <div className={imageCn}>
          {image && <img src={image} alt={title} />}

          {overlay && <div className="overlay">{overlay}</div>}

          {overlayColor && (
            <div className={`overlay overlay-${overlayColor}`}>{overlay}</div>
          )}

          {overlayTitle && (
            <Heading className="overlay-title">{overlayTitle}</Heading>
          )}

          {background && <div className={bgCn}>{background}</div>}
        </div>
      )}

      <div className={cardContentCn}>
        {icon && (
          <div className="card-icon">
            <Icon name={icon} />
          </div>
        )}
        {title && <Heading>{title}</Heading>}
        {text && <p>{text}</p>}
        {children}
      </div>

      {footer && <div className="card-footer">{footer}</div>}
    </CardWrapper>
  )
}

export default Card

Card.defaultProps = {
  titleTag: 'h3',
}
