import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import classNames from 'classnames'
import Alert from '@fnd/components/Alert'
import Button from '@fnd/components/Button'
import dayjs from 'dayjs'
import PaymentGateway from '@fnd/components/Payment/PaymentGateway'
import { ModalHeader } from '@fnd/components/Modal'
import { FormattedMessage, useIntl } from 'react-intl'
import { RangeSlider } from '@fnd/components/Field'
import parse from 'html-react-parser'
import {
  userFeaturedSelector,
  isCuratorFeatured,
  useUserContext,
} from '@fnd/modules/User'
import {
  CURATOR_PROMOTION_COST_PER_WEEK,
  CURATOR_PROMOTION_MIN_WEEK,
  CURATOR_PROMOTION_MAX_WEEK,
} from '@fnd/constants'

const CuratorPromote = ({
  className,
  small,
  triggerVariant = 'primary',
  triggerClasses,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [timeToEnd, setTimeToEnd] = useState(0)
  const [timeToEndUnit, setTimeToEndUnit] = useState('days')
  const [duration, setDuration] = useState(CURATOR_PROMOTION_MAX_WEEK / 2)
  const intl = useIntl()

  const { featured, isFeatured } = useUserContext(({ user }) => ({
    isFeatured: isCuratorFeatured(user),
    featured: userFeaturedSelector(user),
  }))

  const classes = classNames({
    'payment-modal': true,
    [className]: className,
  })

  const handleClose = () => {
    setIsOpen(false)
  }

  const marks = {}
  for (
    let i = CURATOR_PROMOTION_MIN_WEEK;
    i <= CURATOR_PROMOTION_MAX_WEEK;
    i++
  ) {
    marks[i] = i
  }

  useEffect(() => {
    if (featured) {
      let timeLeft = dayjs(featured.ends_at).diff(dayjs(), 'days')
      if (timeLeft === 0) {
        timeLeft = dayjs(featured.ends_at).diff(dayjs(), 'hours')
        setTimeToEndUnit('hours')
      }
      setTimeToEnd(timeLeft)
    }
  }, [featured])

  return (
    <div className={classes}>
      <Button
        small={small}
        className={triggerClasses ?? null}
        icon="star"
        variant={triggerVariant}
        disabled={false}
        label={<FormattedMessage id="promote.cta" />}
        onClick={() => setIsOpen(!isOpen)}
      />

      {isOpen ? (
        <Modal
          isOpen={isOpen}
          onRequestClose={() => setIsOpen(!isOpen)}
          shouldCloseOnOverlayClick
          overlayClassName="modal show"
          className="modal-dialog modal-center modal-sm"
        >
          <div className="modal-content">
            <ModalHeader
              title={<FormattedMessage id="promote.title" />}
              onClose={handleClose}
            />

            <div className="p-6 modal-body">
              <p>{parse(intl.formatMessage({ id: 'promote.description' }))}</p>

              <ul className="mb-5 list">
                <li>{intl.formatMessage({ id: 'promote.feature.1' })}</li>
                <li>{intl.formatMessage({ id: 'promote.feature.2' })}</li>
              </ul>

              {!isFeatured ? (
                <div>
                  <RangeSlider
                    className="mb-5"
                    label={<FormattedMessage id="promote.duration" />}
                    min={CURATOR_PROMOTION_MIN_WEEK}
                    max={CURATOR_PROMOTION_MAX_WEEK}
                    value={duration}
                    marks={marks}
                    onChange={setDuration}
                    step={1}
                  />

                  <PaymentGateway
                    btnLabel={<FormattedMessage id="promote.cta" />}
                    plan="profile_featured"
                    price={duration * CURATOR_PROMOTION_COST_PER_WEEK}
                    payload={{ duration: duration }}
                    creditsDiscount={0.9}
                    onClose={handleClose}
                  />
                </div>
              ) : (
                <Alert variant="success" className="text-center">
                  <p className="md:text-xl">
                    <FormattedMessage id="curator.featured.timer" />
                    {timeToEndUnit === 'days' ? (
                      <FormattedMessage
                        id="curator.featured.timer.unit.days"
                        values={{ timeLeft: timeToEnd }}
                      />
                    ) : (
                      <FormattedMessage
                        id="curator.featured.timer.unit.hours"
                        values={{ timeLeft: timeToEnd }}
                      />
                    )}
                  </p>
                </Alert>
              )}
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  )
}

CuratorPromote.defaultProps = {
  className: '',
  small: false,
}

export default CuratorPromote
