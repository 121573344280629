import { spotifyEndpoints } from '@fnd/core/spotify'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { create } from 'zustand'

const config = {
  CHECK_INTERVAL: 40000,
  CHECK_RETRIES: 20,
  VALID_INTERVAL: 40000,
  TOLERANCE: 3000,
}

const initialState = {
  track: null,
  trackId: '',
  tracks: [],
  isLoading: false,
  timer: null,
  refresh: false,
  success: '',
  error: '',
}

const initialPlayerState = {
  count: 0,
  duration: 0,
  progress: 0,
  playbackState: null,
  isPlaying: false,
  maxRetries: false,
  elapsedTime: 0,
}

export const usePlayStore = create(
  (set, getState) => ({
    ...config,
    ...initialState,
    ...initialPlayerState,
    checkPlaybackState: async (skipCheck) => {
      const {
        CHECK_RETRIES,
        count,
        elapsedTime,
        incrementCount,
        isPlaying,
        maxRetries,
        refresh,
        resetFeedback,
        setDuration,
        setError,
        setIsLoading,
        setIsPlaying,
        setMaxRetries,
        setPlaybackState,
        setProgress,
        setRefresh,
        setSuccess,
        startTimer,
        stopTimer,
        timer,
        TOLERANCE,
        trackId,
        VALID_INTERVAL,
        resetAll
      } = getState()

      if (maxRetries) return
      if (refresh) return
      if (!skipCheck && !isPlaying) return

      if (count >= CHECK_RETRIES) {
        setMaxRetries(true)
        setError('track_player.error.max_retries')
        return
      }

      if (!isPlaying) {
        resetFeedback()
      }
      incrementCount()
      setIsLoading(true)

      const playbackData = await spotifyEndpoints.getPlaybackState().toPromise()
      setPlaybackState(playbackData)
      if (playbackData) {
        setIsPlaying(playbackData?.is_playing)
        setDuration(playbackData?.item?.duration_ms)
        setProgress(playbackData?.progress_ms)

        if (playbackData?.is_playing && !timer) {
          startTimer()
        }

        if (trackId.length > 0 && trackId !== playbackData?.item?.id) {
          setError('track_player.error.different_track')
          return
        }

        if (!playbackData?.is_playing) {
          setError('track_player.error.not_playing')
          return
        }

        if (
          playbackData?.progress_ms >= (VALID_INTERVAL - TOLERANCE) &&
          (elapsedTime * 1000) >= VALID_INTERVAL
        ) {
          try {
            await spotimatchEndpoints.saveTrackPlay(trackId)
            setSuccess('track_player.success')
            setRefresh(true)
            stopTimer()
          } catch (error) {
            setError('track_player.error.save_track_play')
          }
        }
      } else {
        if (isPlaying) {
          resetAll()
        }
      }

      setIsLoading(false)
    },
    playTrack: async () => {
      const { isPlaying, playbackState, track, trackId, resetFeedback, startTimer } = getState()
      if (!trackId) return
      resetFeedback()

      if (playbackState?.device && !isPlaying) {
        try {
          await spotifyEndpoints.startTrack(trackId).toPromise()
          startTimer()
          set({ isPlaying: true })

        } catch (error) {
          console.log(error)
        }
      } else {
        window.open(track.external_urls.spotify, '_blank')
      }
    },
    pauseTrack: async () => {
      const { playbackState } = getState()
      if (playbackState?.device) {
        await spotifyEndpoints.pauseTrack().toPromise()
      }
    },
    stopTrack: async () => {
      set({ isLoading: true })
      const { playbackState, pauseTrack, stopTimer } = getState()

      if (playbackState?.device) {
        try {
          await pauseTrack()
        } catch (error) {
          console.log(error)
        }
      }

      set({ isLoading: false, isPlaying: false })
      stopTimer()
    },
    startTimer: () => {
      if (getState().timer) {
        clearInterval(getState().timer)
      }

      const timer = setInterval(() => {
        set((state) => ({ elapsedTime: state.elapsedTime + 1 }))
      }, 1000)

      set({ timer })
    },
    stopTimer: () => {
      clearInterval(getState().timer)
      set({ timer: null, elapsedTime: 0 })
    },
    setPlaybackState: (playbackState) => set({ playbackState }),
    setIsLoading: (isLoading) => set({ isLoading }),
    setSuccess: (success) => set({
      success, error: '', isLoading: false
    }),
    incrementCount: () => set((state) => ({ count: state.count + 1 })),
    setError: (error) => set({
      error, success: '', isLoading: false,
    }),
    setMaxRetries: (reachedMaxRetries) => set({ reachedMaxRetries }),
    setTrack: (track) => {
      set({ refresh: false })
      set({ success: '', error: '' })
      set({ track })
    },
    setTracks: (tracks) => set({ tracks }),
    setTrackId: (trackId) => set({ trackId }),
    setIsPlaying: (isPlaying) => set({ isPlaying }),
    setRefresh: (refresh) => set({ refresh }),
    setDuration: (duration) => set({ duration }),
    setProgress: (progress) => set({ progress }),
    resetCount: () => set({ count: 0 }),
    resetFeedback: () => set({ success: false, error: false, refresh: false }),
    resetTimer: () => set({ elapsedTime: 0 }),
    resetPlayer: () => { set(initialPlayerState) },
    resetAll: () => {
      const {
        checkPlaybackState,
        resetCount,
        resetFeedback,
        resetPlayer,
        resetTimer,
        stopTrack
      } = getState()

      stopTrack()
      resetFeedback()
      resetTimer()
      resetCount()
      resetPlayer()
      checkPlaybackState(true)
    },
  }),
)

export default usePlayStore
