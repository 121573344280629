import { create } from 'zustand'

export const usePushedTrackStore = create(
  (set) => ({
    currentTrackId: null,
    currentTrack: null,
    setCurrentTrack: (track) => {
      set({
        currentTrack: track,
        currentTrackId: track?.id
      })
    }
  }),
)
