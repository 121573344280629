import React, { useState } from 'react'
import Avatar from '@fnd/components/Avatar'
import { CuratorDetailPopup } from '@fnd/modules/Curator/CuratorDetailPopup'

export const SubmitterInfo = ({ submitter }) => {
  const [currentItem, setCurrentItem] = useState(null)

  return (
    <div className="flex flex-col items-start">
      <div className="inline-flex items-center gap-2 cursor-pointer" onClick={() => setCurrentItem(submitter)}>
        <Avatar
          src={submitter.avatar}
          label={submitter.id}
          size="extra-small"
        />
        <span className="font-medium text-truncate">
          {submitter.display_name}
        </span>
      </div>

      {currentItem ? (
        <CuratorDetailPopup
          curator={currentItem}
          handleModalClose={() => setCurrentItem(null)}
        />
      ) : null}
    </div>
  )
}

export default SubmitterInfo
