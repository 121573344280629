import React from 'react'
import classNames from 'classnames'
import Icon from '@fnd/components/Icon'
import { Link } from 'react-router-dom'
import { Slot } from "@radix-ui/react-slot"
import Spinner from '@fnd/components/Spinner'
import Tooltip from 'rc-tooltip'
import { DEFAULT_ICON_TYPE } from '@fnd/constants'

export const Button = React.forwardRef(({
  type,
  variant,
  asChild,
  to,
  small,
  large,
  disabled,
  className,
  children,
  icon,
  iconType = DEFAULT_ICON_TYPE,
  loading,
  wide,
  label,
  tooltip,
  ...props
}, ref) => {
  const Comp = asChild ? Slot : "button"

  const classes = classNames({
    button: true,
    [`button-${variant}`]: variant,
    'button-small': small,
    'button-large': large,
    'button-wide': wide,
    'button-icon': !label && !children,
    'button-loading': loading,
    [className]: className,
  })

  if (to) {
    return (
      <Link to={to} className={classes} {...props}>
        {icon && <Icon className="icon" type={iconType} name={icon} />}
        {label || children ? <span className="button-label">
          {label && label}
          {children}
        </span> : null}
      </Link>
    )
  }

  if (tooltip) {
    const tooltipClasses = classNames({
      [`tooltip-${variant}`]: variant,
    })

    return (
      <Tooltip
        mouseEnterDelay={0.5}
        overlayClassName={tooltipClasses}
        placement="bottom"
        trigger={['hover']}
        overlay={<span>{tooltip}</span>}
      >
        <span>
          <button type={type} className={classes} disabled={disabled} {...props}>
            {loading && <Spinner inline />}

            {icon && !loading && <Icon className="icon" name={icon} />}
            {label || children ? <span className="button-label">
              {label && label}
              {children}
            </span> : null}

          </button>
        </span>
      </Tooltip>
    )
  }

  return (
    <Comp
      type={type}
      disabled={disabled}
      className={classes}
      ref={ref}
      {...props}
    >
      <>
        {loading && <Spinner inline />}

        {icon && !loading && <Icon className="icon" name={icon} />}
        {label || children ? <span className="button-label">
          {label && label}
          {children}
        </span> : null}
      </>
    </Comp>
  )
})

export default Button

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
}
