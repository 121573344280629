import { make } from './endpoints'

export const getAffiliateByCode = (code) => make.get(`/affiliates/${code}`).toPromise()

export const redeemAffiliateCode = (code, userId) =>
  make.post(`/affiliates/${code}`, { body: { code, userId } }).toPromise()


export default {
  getAffiliateByCode,
  redeemAffiliateCode,
}
