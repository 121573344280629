import React, { useEffect, useState } from 'react'
import { useMatchStore } from '@fnd/store'
import { FormattedMessage, useIntl } from 'react-intl'
import Select from 'react-select'
import parse from 'html-react-parser'
import CopyToClipboard from '@fnd/components/CopyToClipboard'
import classNames from 'classnames'
import { messages, translations } from '@fnd/translations'


export const parseMatchText = (html) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')

  const paragraphs = doc.querySelectorAll('p')
  paragraphs.forEach(p => {
    p.parentNode.insertBefore(document.createTextNode('\n\n'), p)
    p.parentNode.replaceChild(document.createTextNode(p.textContent), p)
  })

  return doc.body.textContent.trim()
}

function MatchMessage({
  className,
  curatorName,
  playlistName,
  score,
  typology,
  userName
}) {
  const intl = useIntl()
  const [message, setMessage] = useState('')
  const [copyMessage, setCopyMessage] = useState('')
  const { matchLocale, setMatchLocale, track } = useMatchStore()


  const changeHandler = (option, { action }) => {
    if (action !== 'select-option') {
      return
    }

    setMatchLocale(option.value)
  }

  useEffect(() => {
    const matchKey = `match.push.message.${typology}`
    const translator = translations[matchLocale]

    const trackLink = `<a href="${track.external_urls.spotify}" target="_blank" rel="noopener noreferrer">${track.external_urls.spotify}</a>`

    const matchMessage = translator
      .formatMessage({ id: matchKey })
      .replaceAll('{trackLink}', trackLink)
      .replaceAll('{curatorName}', curatorName)
      .replaceAll('{playlistName}', playlistName)
      .replaceAll('{score}', score)
      .replaceAll('{userName}', userName)

    setMessage(matchMessage)
    setCopyMessage(parseMatchText(matchMessage))
  }, [matchLocale, score, typology])

  const languageOptions = Object.keys(messages).map((language) => ({
    value: language,
    label: intl.formatMessage({ id: `languages.${language}` }),
  }))

  const classes = classNames({
    'match-message': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      <div className="match-message-header mb-3">
        <Select
          value={languageOptions.find(({ value }) => value === matchLocale)}
          options={languageOptions}
          onChange={changeHandler}
          className="select select-theme-primary"
          classNamePrefix="select"
          isSearchable={false}
        />

        <CopyToClipboard
          variant="theme-primary-light"
          label={<FormattedMessage id="messages.copy" />}
          text={copyMessage}
        />
      </div>

      {message && <div className="match-message-body">
        {parse(message)}
      </div>}
    </div>
  )
}

export default MatchMessage
