import React, { useCallback, useEffect, useState } from 'react'
import AnalysisCalculator from '@fnd/components/AnalysisCalculator'
import Auth from '@fnd/components/Auth'
import Button from '@fnd/components/Button'
import Payment from '@fnd/components/Payment'
import { ROUTES } from '@fnd/screens/constants'
import { FormattedMessage } from 'react-intl'
import { PaymentItem } from '@fnd/components/Plans/PaymentItem'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { useQuery, useQueryClient } from '@tanstack/react-query'

export function useOpenClose(isShowModal) {
  const [isOpen, setIsOpen] = useState(isShowModal)
  const handlers = {
    open: useCallback(() => setIsOpen(true), []),
    close: useCallback(() => setIsOpen(false), []),
    toggle: useCallback(() => setIsOpen((current) => !current), []),
  }

  useEffect(() => {
    if (isShowModal && !isOpen) handlers.open()
  }, [isShowModal])

  return [isOpen, handlers]
}

const PlanActionAnalysis = ({
  title,
  slug,
  icon,
  color,
  disabled,
  price,
}) => {
  const [isAnalysisCalculatorModalOpen, openCloseHandlers] = useOpenClose(false)
  const queryClient = useQueryClient()
  const { data: { availableCount: availableAnalysis } } = useQuery({
    queryKey: ['availableAnalysis'],
    queryFn: () => spotimatchEndpoints.getAvailableAnalysis().toPromise(),
    initialData: { availableCount: 0, isArtistAnalysisFree: false },
  })

  useEffect(() => {
    if (isAnalysisCalculatorModalOpen) {
      openCloseHandlers.close()
    }
  }, [])


  const getButtonTitle = () => {
    if (disabled) {
      return <FormattedMessage id="plans.card.button.login_to_start" />
    }

    return (
      <FormattedMessage id="plans.card.button.analyze" />
    )
  }

  return (
    <>
      <AnalysisCalculator
        isOpen={isAnalysisCalculatorModalOpen}
        availableAnalysis={availableAnalysis}
        onSuccessCallback={() => {
          queryClient.invalidateQueries({ queryKey: ['availableAnalysis'] })
          openCloseHandlers.close()
        }}
      />

      <Auth
        login
        loginLabel={<FormattedMessage id="plans.card.button.login_to_start" />}
        redirect={ROUTES.ANALYSIS}
      >
        {availableAnalysis === 0 ? (
          <Payment
            className="flex justify-center w-100"
            plan={slug}
            price={price}
            title={title}
          >
            <PaymentItem title={title} icon={icon} />
          </Payment>
        ) : (
          <Button
            variant={color}
            onClick={() => openCloseHandlers.open()}
            disabled={disabled}
          >
            {getButtonTitle()}
          </Button>
        )}
      </Auth>
    </>
  )
}

export default PlanActionAnalysis
