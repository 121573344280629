import { useEffect, useState } from 'react'
import { spotifyEndpoints } from '@fnd/core/spotify'

const initialActivityState = {
  artists: [],
  error: void 0,
  isLoading: true,
}

export const useReceiveArtists = (spotifyArtistIds) => {
  const [activityState, setActivityState] = useState(initialActivityState)

  useEffect(() => {
    const fetchArtists = async () => {
      try {
        const { artists } = await spotifyEndpoints.getArtistsBulk(spotifyArtistIds)
        setActivityState({
          artists: artists.map(artist => ({ ...artist, analytics: [] })),
          error: void 0,
          isLoading: false,
        })
      } catch (error) {
        console.error(error)
        setActivityState(current => ({
          ...current,
          error,
          isLoading: false,
        }))
      }
    }

    fetchArtists()
  }, [spotifyArtistIds])

  return [activityState, setActivityState]
}

export default useReceiveArtists
