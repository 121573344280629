import { hexToRGB, hexToHSL } from './helpers'

export const themes = [
  {
    id: 'dark',
    name: 'Dark',
    enable: true,
    palette: {
      primary: '#7866ff',
      primaryLight: '#26223f',
      secondary: '#225CF4',
      secondaryLight: '#1d233d',
      tertiary: '#23269f',
      blue: '#2196f3',
      gold: '#cfae54',
      silver: '#8f8f8f',
      bronze: '#cd7f32',
      green: '#1DB854',
      darkGreen: '#127636',
      greenLight: '#17231e',
      red: '#f44336',
      redLight: '#2b191c',
      orange: '#fb923c',
      yellow: '#eab308',
      primaryBg: '#131317',
      secondaryBg: '#181818',
      primaryText: '#fafafa',
      secondaryText: '#ededed',
      accent: '#a094fb',
      border: '#2d2d2d',
      input: '#232323',
      neutral: '#505050',
      overlay: '#131317',
      invert: '#ffffff',
      invertLight: '#1f1f23',
      dark: '#131317',
      black: '#000000',
      white: '#ffffff',
    },
    shadow: {
      color: '#000000',
      opacity: 0.5,
    },
  },
  {
    id: 'light',
    name: 'Light',
    enable: true,
    palette: {
      primary: '#5e4af9',
      primaryLight: '#efedfe',
      secondary: '#225CF4',
      secondaryLight: '#ebf0fe',
      tertiary: '#23269f',
      blue: '#2196f3',
      gold: '#cfae54',
      silver: '#8f8f8f',
      bronze: '#cd7f32',
      green: '#1DB854',
      darkGreen: '#127636',
      greenLight: '#ecf7ed',
      red: '#f44336',
      redLight: '#ffedec',
      orange: '#fb923c',
      yellow: '#fbc81b',
      primaryBg: '#ffffff',
      secondaryBg: '#f7f9fc',
      primaryText: '#232323',
      secondaryText: '#383A3C',
      accent: '#a094fb',
      border: '#e1e1e1',
      input: '#ededed',
      neutral: '#505050',
      overlay: '#131317',
      invert: '#131317',
      invertLight: '#f4f4f4',
      dark: '#131317',
      black: '#000000',
      white: '#ffffff',
    },
    shadow: {
      color: '#000000',
      opacity: 0.1,
    },
  },
]

export const dashed = (str) => {
  return str.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`)
}

export const setThemeVariables = (theme) => {
  document.body.classList.remove(...document.body.classList)
  document.body.classList.add(`theme-${theme.id}`)
  const root = document.querySelector(':root')

  // theme palette
  Object.keys(theme.palette).forEach((color) => {
    root.style.setProperty(`--${dashed(color)}-color`, theme.palette[color])
    root.style.setProperty(
      `--${dashed(color)}-color-rgb`,
      hexToRGB(theme.palette[color])
    )
  })

  // properties
  root.style.setProperty(
    '--shadow-color',
    `hsl(${hexToHSL(theme.shadow.color)}, ${theme.shadow.opacity})`
  )
  root.style.setProperty(
    '--shadow-color-rgb',
    `${hexToRGB(theme.shadow.color)}`
  )
}
