import { make } from './endpoints'
import { PER_PAGE } from '@fnd/constants'

export const fetchToken = (identity) =>
  make.post('/chat/token', { body: { identity } }).toPromise()

export const getConversations = (params, identity) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)

  return make.get('/chat/conversation', { query: { identity, limit, offset } }).toPromise()
}

export const createConversation = (participants) =>
  make.post('/chat/conversation', { body: { participants } }).toPromise()

export const joinConversation = (conversationId, participants) =>
  make.post('/chat/conversation/join', { body: { conversationId, participants } }).toPromise()

export const leaveConversation = (conversationId, identity) =>
  make.delete(`/chat/conversation/leave`, { body: { conversationId, identity } }).toPromise()

export const getConversationCount = () =>
  make.get('/chat/count').toPromise()

export const archiveConversation = (conversationId) =>
  make.put(`/chat/conversation/${conversationId}/archive`).toPromise()

export const unarchiveConversation = (conversationId) =>
  make.put(`/chat/conversation/${conversationId}/unarchive`).toPromise()

export const syncConversations = (conversations) =>
  make.put('/chat/sync', { body: { conversations } }).toPromise()

export const deleteConversation = (conversationId) =>
  make.delete(`/chat/conversation/${conversationId}`).toPromise()


export default {
  archiveConversation,
  createConversation,
  deleteConversation,
  fetchToken,
  getConversationCount,
  getConversations,
  joinConversation,
  leaveConversation,
  syncConversations,
  unarchiveConversation
}
