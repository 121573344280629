import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useAffiliateStore = create(
  persist(
    (set) => ({
      affiliate: '',
      setAffiliate: (affiliate) => set({ affiliate }),
    }),
    {
      name: 'affiliate-storage'
    }
  )
)

export default useAffiliateStore
