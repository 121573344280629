import { make } from './endpoints'


export const getAvailableAnalysis = () =>
  make.get('/analysis/available/count')

export const getArtistAnalysis = (artistId) => {
  return make
    .post(`/analysis/generate/${artistId}`, {
      body: {},
      responseType: 'blob',
    })
    .toPromise()
}


export default {
  getAvailableAnalysis,
  getArtistAnalysis,
}
