import { make } from './endpoints'

export const trackDashboardAnalytics = () =>
  make.post('/audit-logs/track/dashboard-analytics').toPromise()

export const updateDashboardAnalytics = () =>
  make.patch('/audit-logs/track/dashboard-analytics').toPromise()

export const trackOnlineTime = () =>
  make.post('/audit-logs/track/time-online').toPromise()

export default {
  trackDashboardAnalytics,
  trackOnlineTime,
  updateDashboardAnalytics
}
