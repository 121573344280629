import React, { useState } from 'react'
import Modal from 'react-modal'
import Badge from '@fnd/components/Badge'
import Button from '@fnd/components/Button'
import Field from '@fnd/components/Field'
import Separator from '@fnd/components/Separator'
import Spinner from '@fnd/components/Spinner'
import Toggle from 'react-toggle'
import {
  AUTO_ACCEPT_MIN_MATCH_RATE,
  AUTO_ACCEPT_MAX_MATCH_RATE
} from '@fnd/constants'
import { ModalHeader } from '@fnd/components/Modal'
import { FormattedMessage, useIntl } from 'react-intl'
import { toastFeedback } from '@fnd/core/libs/toast'
import { usePlaylistStore } from '@fnd/store'


export default function AutoSubmitPopup({
  isOpen,
  defaultValue = AUTO_ACCEPT_MIN_MATCH_RATE,
  playlistId,
  onClose,
  updatePlaylistData
}) {
  const [minMatchRate, setMinMatchRate] = useState(defaultValue ?? 50)
  const [enable, setEnable] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const intl = useIntl()

  const { enableAutoSubmit, disableAutoSubmit } = usePlaylistStore()

  const handleMinMatchRateChange = (value) => {
    setMinMatchRate(value?.[0])
  }

  const handleEnableAutoSubmit = async () => {
    setIsLoading(true)

    if (enable) {
      let rate = minMatchRate

      if (minMatchRate < AUTO_ACCEPT_MIN_MATCH_RATE) {
        setMinMatchRate(AUTO_ACCEPT_MIN_MATCH_RATE)
        rate = AUTO_ACCEPT_MIN_MATCH_RATE
      } else if (minMatchRate > AUTO_ACCEPT_MAX_MATCH_RATE) {
        setMinMatchRate(AUTO_ACCEPT_MAX_MATCH_RATE)
        rate = AUTO_ACCEPT_MAX_MATCH_RATE
      }

      try {
        await enableAutoSubmit(playlistId, rate)
        updatePlaylistData(playlistId, { autoAcceptRate: rate })
        if (onClose) {
          onClose(rate)
        }
        setIsLoading(false)
      } catch (error) {
        toastFeedback(
          'error',
          intl.formatMessage({ id: 'feedback.error.default' })
        )
      }
    } else {
      try {
        await disableAutoSubmit(playlistId)
        updatePlaylistData(playlistId, { autoAcceptRate: 100 })
        if (onClose) {
          onClose(100)
        }
        setIsLoading(false)
      } catch (error) {
        toastFeedback(
          'error',
          intl.formatMessage({ id: 'feedback.error.default' })
        )
      }
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName="modal show"
      className="modal-dialog modal-center modal-center modal-xs modal-confirm"
    >
      <div className="modal-content">
        <ModalHeader
          title={(
            <div className="flex items-center gap-3">
              <FormattedMessage id="auto_accept.title" />
              <Badge
                label="AI"
                icon="sparkles"
                variant="blue-light"
              />
            </div>
          )}
          onClose={onClose}
        />

        <div className="modal-body p-5">
          <div className="flex flex-col items-start">
            <Badge
              icon="connectdevelop"
              iconType="brands"
              label="βeta Feature"
              variant="blue-light"
              className="mb-3"
            />
            <h3 className="text-xl md:text-3xl mb-2">
              <FormattedMessage id="auto_accept.intro.title" />
            </h3>
            <FormattedMessage id="auto_accept.intro.description" />
          </div>

          <Separator className="my-6" />

          <form className="form">
            <div className="form-group">
              <label>
                <FormattedMessage id="auto_accept.enable" />
              </label>
              <Toggle
                checked={enable}
                onChange={() => setEnable(!enable)}
                name="enable-auto-submit"
              />
            </div>

            <div className="form-group">
              <label htmlFor="auto-submit-match-rate">
                <FormattedMessage id="auto_accept.input" />
              </label>
              <Field
                type="slider"
                showLabels={true}
                labelSuffix="%"
                variant="blue"
                min={AUTO_ACCEPT_MIN_MATCH_RATE}
                max={AUTO_ACCEPT_MAX_MATCH_RATE}
                disabled={!enable}
                id="auto-submit-match-rate"
                defaultValue={[minMatchRate]}
                value={[minMatchRate]}
                onChange={handleMinMatchRateChange}
              />
            </div>
          </form>
        </div>

        <div className="modal-footer">
          <Button
            className="w-full"
            variant="blue-light"
            onClick={handleEnableAutoSubmit}
          >
            {isLoading ? (
              <Spinner inline />
            ) : (
              <FormattedMessage id="auto_accept.cta" />
            )}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
