import React, { useMemo } from 'react'
import { useUserContext } from '@fnd/modules/User'
import {
  PlanPriceAnalysis,
  PlanPriceDefault,
  PlanPriceVip,
} from './prices'
import { useQuery } from '@tanstack/react-query'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'

const PricingPlanPrice = ({
  price,
  salePrice,
  layout,
  displayMonthly,
  countdown,
  duration,
}) => {

  const { data: { availableCount: availableAnalysis, isArtistAnalysisFree: _isArtistAnalysisFree } } = useQuery({
    queryKey: ['availableAnalysis'],
    queryFn: () => spotimatchEndpoints.getAvailableAnalysis().toPromise(),
    initialData: { availableCount: 0, isArtistAnalysisFree: false },
  })

  const { user } = useUserContext()
  const isArtistAnalysisFree = useMemo(() => {
    return user.isAuthorized && _isArtistAnalysisFree
  }, [user, _isArtistAnalysisFree])

  return (
    <div className="plan-price">
      {
        layout === 'default' && (
          <PlanPriceDefault
            price={price}
            salePrice={salePrice}
            countdown={countdown}
            displayMonthly={displayMonthly}
            duration={duration}
          />
        )
      }

      {layout === 'vip' && <PlanPriceVip price={price} />}

      {layout === 'analysis' && <PlanPriceAnalysis
        price={price}
        isArtistAnalysisFree={isArtistAnalysisFree}
        availableAnalysis={availableAnalysis}
      />}

    </div>
  )
}

export default PricingPlanPrice
