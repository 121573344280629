import React from 'react'
import { CopyToClipboard as Copy } from 'react-copy-to-clipboard'
import Button from '@fnd/components/Button'
import { useIntl } from 'react-intl'
import { toastFeedback } from '@fnd/core/libs/toast'

export default function CopyToClipboard({
  text,
  label,
  variant = 'primary-light',
  options = {
    format: 'text/plain',
  },
  className
}) {
  const intl = useIntl()

  const copied = () => {
    toastFeedback('success', intl.formatMessage({ id: 'messages.copied' }))
  }

  return (
    <Copy
      onCopy={copied}
      className={className}
      text={text}
      options={options}
    >
      <Button
        small
        label={label ?? ''}
        variant={variant}
        className="button-copy"
        icon="copy"
      />
    </Copy>
  )
}

CopyToClipboard.defaultProps = {
  className: '',
}
