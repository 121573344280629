import React, { useState, useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import Modal from 'react-modal'
import { useUserContext, userProfileSelector } from '@fnd/modules/User'
import PushToPlaylist from '@fnd/components/PushToPlaylist'
import TrackDetails from './TracksDetails'
import PlaylistOverview from './PlaylistOverview'
import { Tabs, TabContent, useTabs } from '../Tabs'
import { ModalHeader } from '@fnd/components/Modal'
import { useMatchStore, usePlaylistTracksStore } from '@fnd/store'
Modal.setAppElement('#root')

const PlaylistModal = ({
  playlist,
  curator,
  isFeatured,
  score,
  values,
  isOpen,
  onClose
}) => {
  const [matchScore, setMatchScore] = useState(score)
  const [matchValues, setMatchValues] = useState(values)
  const [tracks, setTracks] = useState()

  const { getMatch, track, trackId } = useMatchStore()
  const { getPlaylistTracks } = usePlaylistTracksStore()
  const { profile } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
  }))

  const fetchMatchData = async () => {
    if (!values || !score) {
      const matchData = await getMatch(playlist.id, trackId)
      setMatchScore(matchData?.score)
      setMatchValues(matchData?.values)
    }
  }

  useEffect(() => {
    fetchMatchData()
  }, [values, score])

  useEffect(() => {
    const fetchPlaylistTracks = async () => {
      if (playlist?.id) {
        const trackData = await getPlaylistTracks(playlist?.id)
        setTracks(trackData)
      }
    }

    fetchPlaylistTracks()
  }, [playlist])

  const tabsData = {
    tabs: [
      {
        id: 'push-to-playlist',
        icon: 'arrow-right',
        label: <FormattedMessage id="match.playlist_popup.submit.title" />,
        children: (
          <>
            <PushToPlaylist
              playlistId={playlist?.id}
              playlist={playlist}
              isFeatured={isFeatured}
              score={matchScore}
              curatorId={curator?.id}
              curatorName={curator?.display_name}
              track={track}
              trackId={trackId}
              tracks={tracks}
              userName={profile?.display_name}
              userContacts={profile?.contacts}
            />
          </>
        ),
      },
      {
        id: 'tracks',
        icon: 'list-music',
        label: <FormattedMessage id="profile.my_playlists.table.track_list" />,
        children: <TrackDetails tracks={tracks} />,
      },
    ],
    initialTabId: 'push-to-playlist',
  }

  const tabs = useTabs(tabsData)
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      overlayClassName="modal show"
      className="modal-dialog modal-center modal-fullscreen"
    >
      <div className="modal-content">
        <ModalHeader
          title={playlist.name}
          onClose={onClose}
        />
        <div className="modal-body">
          <PlaylistOverview
            playlist={playlist}
            score={matchScore}
            curator={curator}
            values={matchValues}
          />
          <div className="py-4">
            <Tabs {...tabs.tabProps} />
            <TabContent {...tabs.contentProps}>
              {tabs.selectedTab.children}
            </TabContent>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default React.memo(PlaylistModal, (prevProps, nextProps) => {
  return prevProps.playlist === nextProps.playlist
})
