import React, { useEffect, useRef } from 'react'
import Empty from '@fnd/components/Empty'
import Icon from '@fnd/components/Icon'
import Spinner from '@fnd/components/Spinner'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'
import { useInView } from 'react-intersection-observer'
import { useChatStore } from '@fnd/store/useChatStore'
import { getDateFormat } from '@fnd/core/libs/helpers'
import { useScrollDirection } from '@fnd/core/hooks/useScrollDirection'


const MessageAttachments = ({ message }) => {
  if (!message?.attributes || !Object.keys(message.attributes).length > 0) {
    return null
  }

  return (
    <div className="message-attachments">
      {Object.keys(message.attributes).map((key) => {
        const attachment = message.attributes[key]

        if (attachment && typeof attachment === 'object') {
          return (
            <MessageAttachment
              key={key}
              url={attachment.url}
              title={attachment.name}
              type={attachment.type}
              icon={attachment.icon}
              image={attachment.image}
            />
          )
        }
      })}
    </div>
  )
}

const MessageAttachment = ({ image, type, icon, title, url }) => {
  return (
    <Link to={url} target="_blank" className="message-attachment">
      <div className="message-attachment-header">
        <span className="flex items-center gap-1">
          {icon && <Icon name={icon} />}
          {type}
        </span>
      </div>
      <div className="message-attachment-body">
        <div className="message-attachment-image">
          <img src={image} alt={title} />
        </div>
        <span className="font-medium text-sm">
          {title}
        </span>
      </div>
    </Link>
  )
}

export const ConversationMessages = ({
  isLoading,
  messages,
  onLoadMore,
  total,
  user,
}) => {
  const chatRef = useRef(null)
  const chatEndRef = useRef(null)
  const { scrollDirection, setScrollDirection } = useScrollDirection(chatRef, 50)
  const intl = useIntl()
  const { locale } = intl

  const {
    activeSid,
    loadedConversations,
  } = useChatStore()

  const { ref: loadMoreRef, inView } = useInView({
    threshold: 1,
    delay: 1000
  })

  useEffect(() => {
    if (loadedConversations.includes(activeSid)) {
      const blockEnd = document.querySelectorAll('.block-end')
      if (blockEnd?.length > 0) {
        chatRef.current.scrollTo({
          top: blockEnd[0].offsetTop - blockEnd[0].offsetHeight,
          behavior: 'instant',
        })
      }
    }

    if (!loadedConversations.includes(activeSid)) {
      chatRef.current.scrollTo({
        top: chatEndRef.current.offsetTop,
        behavior: 'instant',
      })
    }
  }, [activeSid, messages])

  useEffect(() => {
    if (inView && !isLoading && scrollDirection === 'up') {
      onLoadMore()
      setScrollDirection('idle')
    }
  }, [inView])

  return (
    <div ref={chatRef} className="chat-body">
      {messages && total > messages.length && !isLoading && (
        <div ref={loadMoreRef} className="w-full h-1 pb-8"></div>
      )}

      {isLoading && (
        <div className="flex items-center justify-center w-full pt-4 pb-8">
          <Spinner inline />
        </div>
      )}

      {messages && messages?.length > 0 && messages.map((msg, index) => {
        const isSelf = msg.author === user.spotify_id
        const isLastOfGroup =
          messages.indexOf(msg) === messages.length - 1 ||
          messages[messages.indexOf(msg) + 1].author !== msg.author

        return (
          <div
            key={index}
            className={classNames({
              'message': true,
              'self': isSelf,
              'last': isLastOfGroup,
              'block-start': msg?.blockStart,
              'block-end': msg?.blockEnd,
            })}
          >
            {msg.body}
            {msg.dateCreated && (
              <small>
                {dayjs(msg.dateCreated).format(getDateFormat(locale, true))}
              </small>
            )}

            <MessageAttachments message={msg} />
          </div>
        )
      })}

      {!isLoading && messages && messages?.length === 0 && (
        <Empty
          small
          ghost
          className="h-full m-0"
          message={intl.formatMessage({ id: 'empty_state.no_messages' })}
          icon="comments"
        />
      )}

      {!isLoading && (
        <div ref={chatEndRef} className="w-full h-1"></div>
      )}
    </div>
  )
}

export default ConversationMessages
