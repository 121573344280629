import React, { useEffect, useState } from 'react'
import Badge from '@fnd/components/Badge'
import { PlanCard } from '@fnd/components/Plans'
import {
  isUserAuthorizedSelector,
  useUserContext,
  userProfileSelector,
} from '@fnd/modules/User'
import SectionTitle from '@fnd/components/SectionTitle'
import classNames from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSearchParams } from 'react-router-dom'
import {
  COUNTERS,
  PLANS,
  PLAN_CONFIG,
  PLAN_PRICES,
  PLAN_FEATURES_FREE as planFeaturesFree,
  PLAN_FEATURES_ARTIST as planFeaturesArtist,
  PLAN_FEATURES_AGENCY as planFeaturesAgency,
  PLAN_FEATURES_VIP as planFeaturesVIP
} from '@fnd/constants'

const Plans = ({
  className,
  showTitle = false,
  titleTag = 'h1',
}) => {
  const [defaultOpen, setDefaultOpen] = useState(null)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const planParam = searchParams.get('plan')
    if (planParam) {
      setDefaultOpen(planParam)
    }
  }, [searchParams])

  const { isUserAuthorized } = useUserContext(
    ({ user, updateUserProfile }) => ({
      isUserAuthorized: isUserAuthorizedSelector(user),
      updateUser: (next) => updateUserProfile(next),
    })
  )

  const { plan } = useUserContext(({ user }) => userProfileSelector(user))

  const disablePlanDowngrade = (planName) => {
    const plans = Object.values(PLANS)

    if (typeof plan === 'undefined') return false
    return plans.indexOf(planName) <= plans.indexOf(plan.name)
  }

  const { isAuthorized } = useUserContext(({ user }) => ({
    isAuthorized: isUserAuthorizedSelector(user),
  }))

  const intl = useIntl()

  const PLAN_FEATURES_FREE = planFeaturesFree(intl)
  const PLAN_FEATURES_ARTIST = planFeaturesArtist(intl)
  const PLAN_FEATURES_AGENCY = planFeaturesAgency(intl)
  const PLAN_FEATURES_VIP = planFeaturesVIP(intl)

  const classes = classNames({
    [className]: className,
  })

  return (
    <div className={classes}>
      {showTitle && (
        <SectionTitle
          tag={titleTag}
          title={intl.formatMessage({ id: 'plans.header.title' })}
          subtitle={intl.formatMessage({ id: 'plans.page.subtitle' })}
          badge={
            <Badge
              variant="primary-light"
              icon="cloud-music"
              label={intl.formatMessage({ id: 'plans.page.title' })}
            />
          }
          className="mb-8"
          center
        />
      )}

      <div className="plans-grid">
        <PlanCard
          title={PLAN_CONFIG.free.title}
          slug={PLANS.CURATOR}
          defaultOpen={PLANS.CURATOR === defaultOpen}
          icon={PLAN_CONFIG.free.icon}
          color={PLAN_CONFIG.free.color}
          duration="y"
          displayMonthly={true}
          features={PLAN_FEATURES_FREE}
          caption={intl.formatMessage({ id: 'plans.card.free.caption' })}
          price={PLAN_PRICES.free.price}
          salePrice={PLAN_PRICES.free.salePrice}
          isActive={
            (typeof plan !== 'undefined' ? plan.name.toLowerCase() : '') ===
            'free'
          }
          layout="default"
          isAuthorized={isAuthorized}
          disabledDowngrade={disablePlanDowngrade('free')}
        />

        <PlanCard
          title={PLAN_CONFIG.artist.title}
          slug={PLANS.ARTIST}
          defaultOpen={PLANS.ARTIST === defaultOpen}
          icon={PLAN_CONFIG.artist.icon}
          color={PLAN_CONFIG.artist.color}
          duration="y"
          displayMonthly={true}
          mostPopular={true}
          features={PLAN_FEATURES_ARTIST}
          caption={intl.formatMessage({ id: 'plans.card.artist.caption' })}
          price={PLAN_PRICES.artist.price}
          salePrice={PLAN_PRICES.artist.salePrice}
          save={PLAN_PRICES.artist.save}
          paymentPrice={PLAN_PRICES.artist.salePrice}
          isActive={
            (typeof plan !== 'undefined' ? plan.name.toLowerCase() : '') ===
            'artist'
          }
          layout="default"
          isAuthorized={isAuthorized}
          disabled={!isUserAuthorized}
          disabledDowngrade={disablePlanDowngrade('artist')}
          socialProof={
            <FormattedMessage
              id="plans.card.artist.social_proof"
              values={{ plans: COUNTERS.ARTIST_PLAN_COUNT }}
            />
          }
        />

        <PlanCard
          title={PLAN_CONFIG.agency.title}
          slug={PLANS.AGENCY}
          defaultOpen={PLANS.AGENCY === defaultOpen}
          icon={PLAN_CONFIG.agency.icon}
          color={PLAN_CONFIG.agency.color}
          duration="y"
          displayMonthly={true}
          mostPopular={false}
          features={PLAN_FEATURES_AGENCY}
          caption={intl.formatMessage({ id: 'plans.card.agency.caption' })}
          price={PLAN_PRICES.agency.price}
          salePrice={PLAN_PRICES.agency.salePrice}
          save={PLAN_PRICES.agency.save}
          paymentPrice={PLAN_PRICES.agency.salePrice}
          isActive={
            (typeof plan !== 'undefined' ? plan.name.toLowerCase() : '') ===
            'agency'
          }
          layout="default"
          isAuthorized={isAuthorized}
          disabled={!isUserAuthorized}
          disabledDowngrade={disablePlanDowngrade('agency')}
          socialProof={
            <FormattedMessage
              id="plans.card.agency.social_proof"
              values={{ plans: COUNTERS.AGENCY_PLAN_COUNT }}
            />
          }
        />

        <PlanCard
          title={PLAN_CONFIG.vip.title}
          icon={PLAN_CONFIG.vip.icon}
          color={PLAN_CONFIG.vip.color}
          features={PLAN_FEATURES_VIP}
          caption={intl.formatMessage({ id: 'plans.card.vip.caption' })}
          price={PLAN_PRICES.vip.price}
          layout="vip"
          isActive={false}
          isAuthorized={isAuthorized}
          disabled={!isUserAuthorized}
        />
      </div>
    </div>
  )
}

export default Plans
