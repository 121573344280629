import { make } from './endpoints'


export const getInvoice = (invoiceToken) =>
  make.get(`/invoices/${invoiceToken}`).toPromise()


export default {
  getInvoice,
}
