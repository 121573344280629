import React, { Fragment, memo, useEffect, useCallback } from 'react'
import Badge from '@fnd/components/Badge'
import Empty from '@fnd/components/Empty'
import Icon from '@fnd/components/Icon'
import Spinner from '@fnd/components/Spinner'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { PLAYLISTS_PER_PAGE, QUERIES } from '@fnd/constants'
import { toastFeedback } from '@fnd/core/libs/toast'
import PlaylistTableItem from '@fnd/modules/Curator/Playlist/PlaylistTableItem'
import { usePlaylistStore } from '@fnd/store'
import { useInView } from 'react-intersection-observer'
import Tooltip from 'rc-tooltip'
import { FormattedMessage, useIntl } from 'react-intl'

function PlaylistsTable({ userId, isUserVerified }) {
  const {
    resetFeedback,
    success,
    error,
    toggleVerification,
    toggleWatch,
  } = usePlaylistStore()

  const { ref, inView } = useInView({
    threshold: 0,
  })

  const queryClient = useQueryClient()
  const queryKey = `${QUERIES.PLAYLIST.LIST}`
  const intl = useIntl()

  const {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery({
    queryKey: [queryKey],
    queryFn: async (params) => {
      const res = await spotimatchEndpoints.getUserPlaylists({
        ...params,
        limit: PLAYLISTS_PER_PAGE
      })
      return res
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage?.next_page) return
      return lastPage.next_page
    },
  })

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage, hasNextPage])

  useEffect(() => {
    if (success) {
      toastFeedback('success', intl.formatMessage({ id: success }))
    } else if (error) {
      toastFeedback('error', intl.formatMessage({ id: error }))
    }

    resetFeedback()
  }, [success, error])

  const updatePlaylistData = useCallback((playlistId, updatedFields) => {
    queryClient.setQueryData([queryKey], (oldData) => {
      if (!oldData) return oldData

      return {
        ...oldData,
        pages: oldData.pages.map(page => ({
          ...page,
          data: page.data.map(playlist =>
            playlist.PlaylistId === playlistId
              ? { ...playlist, ...updatedFields }
              : playlist
          )
        }))
      }
    })
  }, [queryClient, queryKey])

  const handleToggleVerification = useCallback((playlistId, newFeaturedState) => {
    toggleVerification(playlistId, newFeaturedState)
    updatePlaylistData(playlistId, { featured: newFeaturedState })
  }, [toggleVerification, updatePlaylistData])

  const handleToggleWatch = useCallback((playlistId, newWatchState) => {
    toggleWatch(playlistId, newWatchState).then(() => {
      updatePlaylistData(playlistId, { watch: newWatchState })
    })
  }, [toggleWatch, updatePlaylistData])

  if (isLoading) return <Spinner />

  const userPlaylists = data?.pages?.map((page) => page.data).flat() || []

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th>
              Playlist
            </th>
            <th>
              <Tooltip
                placement="top"
                trigger={['hover']}
                overlay={
                  <span>
                    <FormattedMessage id="profile.my_playlists.table.auto_tooltip" />
                  </span>
                }
              >
                <div className="flex items-center">
                  <FormattedMessage id="profile.my_playlists.table.auto" />
                  <Badge
                    label="AI"
                    icon="sparkles"
                    variant="blue-light"
                    className="ml-2"
                  />
                </div>
              </Tooltip>
            </th>
            <th>
              <Tooltip
                placement="top"
                trigger={['hover']}
                overlay={
                  <span>
                    <FormattedMessage id="profile.my_playlists.table.verified_tooltip" />
                  </span>
                }
              >
                <div className="flex items-center">
                  <FormattedMessage id="profile.my_playlists.table.verified" />
                  <Icon name="info-circle" className="ml-2" />
                </div>
              </Tooltip>
            </th>
            <th>
              <div className="flex items-center">
                <FormattedMessage id="profile.my_playlists.table.watch" />
                <Icon name="info-circle" className="ml-2" />
              </div>
            </th>
            <th>
              <Tooltip
                placement="top"
                trigger={['hover']}
                overlay={
                  <span>
                    <FormattedMessage id="profile.my_playlists.table.submissions_tooltip" />
                  </span>
                }
              >
                <div className="flex itemx-center">
                  <FormattedMessage id="profile.my_playlists.table.submissions" />
                  <Icon name="info-circle" className="ml-2" />
                </div>
              </Tooltip>
            </th>
            <th className="text-right">
              <FormattedMessage id="messages.actions" />
            </th>
          </tr>
        </thead>

        {!isLoading && userPlaylists?.length > 0 ? (
          <tbody>
            {userPlaylists.map((playlist, playlistIndex) => (
              <Fragment key={playlist.PlaylistId}>
                <PlaylistTableItem
                  index={playlistIndex + 1}
                  playlist={playlist}
                  isUserVerified={isUserVerified}
                  userId={userId}
                  updatePlaylistData={updatePlaylistData}
                  toggleVerification={() => handleToggleVerification(playlist.PlaylistId, !playlist.featured)}
                  toggleWatch={() => handleToggleWatch(playlist.PlaylistId, !playlist.watch)}
                />
              </Fragment>
            ))}
          </tbody>
        ) : (
          <tbody>
            <td colSpan={7}>
              <Empty message={intl.formatMessage({ id: 'messages.no_playlists' })} />
            </td>
          </tbody>
        )}
      </table>

      {(isLoading || isFetchingNextPage) && <Spinner />}

      <div ref={ref} />
    </div>
  )
}

export default memo(PlaylistsTable)
