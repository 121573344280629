import React from 'react'
import Empty from '@fnd/components/Empty'
import { ConversationItem } from '@fnd/components/Chat'
import { useIntl } from 'react-intl'
import { AnimatePresence, motion } from 'framer-motion'

export const ConversationArchive = ({
  archiveList,
  activeSid,
  showArchive,
  handleConversationSelect,
  isLoading,
}) => {
  const intl = useIntl()

  return (
    <AnimatePresence>
      {showArchive && archiveList?.map(chat => (
        <motion.div layout className="w-full" key={chat.sid}>
          <ConversationItem
            key={chat.sid}
            active={activeSid === chat.sid}
            conversation={chat}
            onClick={handleConversationSelect}
          />
        </motion.div>
      ))}

      {showArchive && !isLoading && archiveList?.length === 0 && (
        <Empty
          small
          ghost
          className="h-full m-0"
          message={intl.formatMessage({ id: 'empty_state.no_chats' })}
          icon="comments"
        />
      )}
    </AnimatePresence>
  )
}

export default ConversationArchive
